/** Hooks & React **/
import React, {useEffect} from 'react';
import {Redirect, Route, withRouter} from 'react-router-dom';
import {compose} from "redux";
import {useDispatch, useSelector} from 'react-redux';
/** Styles & Graphics **/
import './App.scss';
/** Components **/
import Vars from './components/Vars/Vars';
import DealsPage from './components/DealsPage/DealsPage';
import Calculator from './components/Calculator/Calculator';
import Header from './components/Header/Header';
import NavBar from './components/NavBar/NavBar';
import HeaderRight from "./components/HeaderRight/HeaderRight";
import SideBarVars from "./components/SideBar/SideBarVars/SideBarVars";
import ProfileContainer from "./components/Profile/ProfileContainer";
import Preloader from "./common/Preloader/Preloader";
import NotificationsList from "./components/Profile/Notifications/NotificationsList";
import SettingsContainer from "./components/Settings/SettingsContainer";
import ReleasesContainer from "./components/Releases/ReleasesContainer";
import Stats from "./components/Stats/Stats";
import Price from "./components/Price/Price";
import ClientsNew from "./components/Tables/Clients/Clients";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
import {initializeAppThunk} from "./redux/reducers/app.reducer";
/** Selectors **/
import {initializedSelector} from './redux/selectors/app.selector';
import {isAuthSelector} from "./redux/selectors/auth.selectors";
import DriversForwarders from "./components/Tables/DriversForwarders/DriversForwarders";
import DfOrders from "./components/Tables/DfOrders/DfOrders";
/** Other **/

const App: React.FC = () =>  {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const initialized: boolean = useSelector(initializedSelector)
    //const isChatOpen: boolean = useSelector(isChatOpenSelector)
    const isAuth: boolean = useSelector(isAuthSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // const onOpenChat = () => {
    //     dispatch(appAC.toggleChatOpen(true))
    // }

    // const onCloseChat = () => {
    //     dispatch(appAC.toggleChatOpen(false))
    // }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(initializeAppThunk())
        // eslint-disable-next-line
    }, [])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */
    // если инициализация еще не прошла отображаем Прелоадер
    if (!initialized) {
        return <div className='preloader'><Preloader/></div>
    }
    // если пользователь не авторизован то редиректим его на страницу Логин
    if (!isAuth) return <Redirect to='/login'/>
    /** ###################################### JSX ######################################################################## **/
    if (process.env.NODE_ENV === 'development') console.log('========= DEVELOPMENT MODE ==========')
    return (
        <>
            <div className='app-wrapper'>
                <div className='allHeader'>
                    <Header/>
                    <NavBar/>
                    <HeaderRight/>
                </div>
                <div className='app-wrapper-content'>
                    <Route path='/calculator' render={() => <Calculator/>}/>
                    <Route path='/clients' render={() => <ClientsNew />}/>
                    <Route path='/df' render={() => <DriversForwarders />}/>
                    <Route path='/dforders/:page?' render={() => <DfOrders />}/>
                    <Route path='/dealspage/:id?' render={() => <DealsPage/>}/>
                    <Route path='/pricelistcreator' render={() => <Price/>}/>
                    <Route path='/profile' render={() => <ProfileContainer/>}/>
                    <Route path='/settings' render={() => <SettingsContainer/>}/>
                    <Route path='/stats' render={() => <Stats/>}/>
                    <Route path='/releases' render={() => <ReleasesContainer/>}/>
                    <Route path='/notifications/:page?' render={() => <NotificationsList/>}/>
                    <Route path='/vars' render={() => {
                        return <div className='app-wrapper-content-with-sidebar'>
                            <div className='content'>
                                <Vars/>
                            </div>
                            <div>
                                <SideBarVars/>
                            </div>
                        </div>
                    }}/>
                </div>

                {/*<Drawer anchor={"right"} open={isChatOpen} onClose={onCloseChat}>*/}
                {/*    <Chat/>*/}
                {/*</Drawer>*/}
            </div>
            {/*<div className='buttonChat'>*/}
            {/*    <Fab color="secondary" onClick={onOpenChat}>*/}
            {/*        <Badge badgeContent={4} color="error">*/}
            {/*            <ChatIcon/>*/}
            {/*        </Badge>*/}
            {/*    </Fab>*/}
            {/*</div>*/}
        </>
    );
}

export default compose(withRouter)(App)