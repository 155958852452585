/** Hooks & React **/
import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
/** Styles & Graphics **/
import './NavBar.scss'
/** Components **/
import AddIcon from '@mui/icons-material/Add';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PeopleIcon from '@mui/icons-material/People';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import {Tooltip} from "@mui/material";
import AddNewDeal from "../DealsPage/AddNewDeal/AddNewDeal";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IMenu {
    label: string
    icon: JSX.Element
    url: string
}

const NavBar = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    const menu: Array<IMenu> = [
        {
            label: 'Сделки',
            icon: <DocumentScannerIcon />,
            url: '/dealspage'
        },
        {
            label: 'Клиенты',
            icon: <PeopleIcon />,
            url: '/clients'
        },
        {
            label: 'Водители и Экспедиторы',
            icon: <AutoStoriesIcon />,
            url: '/df'
        }
    ]
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [openAddDealPopup, setOpenAddDealPopup] = useState<boolean>(false)
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onOpenAddDealPopup = (): void => {
        setOpenAddDealPopup(true)
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const menuElements = menu.map(item => <div className='navbar__item' key={item.label}>
        <NavLink
            to={item.url}
            className='navbar__link'
            activeClassName='navbar__link-active'
        >
            <div className="navbar__link-icon">{item.icon}</div>
            <div className="navbar__link-text">{item.label}</div>
        </NavLink>
    </div>)
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <nav className='navbar'>
            <Tooltip title={'Добавить сделку'}>
                <div className='navbar__add-deal-btn' onClick={onOpenAddDealPopup}>
                    <AddIcon />
                </div>
            </Tooltip>
            <div className='navbar__items'>
                {menuElements}
            </div>
            {openAddDealPopup && <AddNewDeal openAddDealPopup={openAddDealPopup} setOpenAddDealPopup={setOpenAddDealPopup}/>}
        </nav>
    )
}
export default NavBar;