import {companiesAPI} from "../../API/api";
import produce from "immer";
import {ICompaniesState, ICompany} from "../types/companies.types";
import {TDispatch} from "../redux-store";

// Actions

enum ActionsType {
    SET_COMPANIES_DATA_TABLE = 'dealCRM/vars/companies/SET_DRIVERS_DATA_TABLE'
}

// InitialState

let initialState: ICompaniesState = {
    companiesTableData: [],
}

// ActionCreators

export const companiesAC = {
    setCompaniesDataTable: (data: Array<ICompany>) => ({ type: ActionsType.SET_COMPANIES_DATA_TABLE, data } as const)
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof companiesAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

const companiesReducer = (state = initialState, action: ActionType): ICompaniesState =>
    produce(state, draft => {
        switch (action.type) {
            case ActionsType.SET_COMPANIES_DATA_TABLE:
                draft.companiesTableData = action.data
                break
        }
    })

// Thunks
export const loadingCompaniesTableData = () => async (dispatch: TDispatch) => {
    try {
        const tableData: Array<ICompany> = await companiesAPI.getAllCompanies()
        dispatch(companiesAC.setCompaniesDataTable(tableData))
    } catch (e: any) { alert(e.response.data.message)}
}

export const addCompany = (name: string, bill: string, tax: number) => async (dispatch: TDispatch) => {
    try {
        await companiesAPI.addNewCompany(name, bill, tax)
    } catch (e: any) { alert(e.response.data.message)}
    await dispatch(loadingCompaniesTableData())
}

export const updateCompany = (id: string, name: string, bill: string, tax: number) => async (dispatch: TDispatch) => {
    try {
        await companiesAPI.updateCompany(id, name, bill, tax)
    } catch (e: any) { alert(e.response.data.message)}
    await dispatch(loadingCompaniesTableData())
}

export const deleteCompany = (id: string) => async (dispatch: TDispatch) => {
    try {
        await companiesAPI.deleteCompany(id)
    } catch (e: any) { alert(e.response.data.message)}
    await dispatch(loadingCompaniesTableData())
}


export default companiesReducer;