/** Hooks & React **/
import React from 'react';
/** Styles & Graphics **/
import s from "./StatusBlock.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import {IDealsLoading, IDealStatuses, TDealsStatus} from "../../../../redux/types/deals.types";
import {useDispatch, useSelector} from "react-redux";
import {meDataSelector} from "../../../../redux/selectors/auth.selectors";
import {IMe, TPosition} from '../../../../redux/types/auth.types';
import { toggleStatusThunk } from '../../../../redux/reducers/deals.reducer';
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    dealStatus: IDealStatuses
    dealId: string
    managerId: string
    loading: IDealsLoading
    locked: boolean
    deleted: boolean
}

const StatusBlock: React.FC<IProps> = ({dealStatus, dealId, loading, managerId,
                                           locked, deleted}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    let approved = dealStatus.approved
    let providerPaid = dealStatus.providerPaid
    let delivered = dealStatus.delivered
    let clientPaid = dealStatus.clientPaid
    let docSigned = dealStatus.docSigned
    let docCollected = dealStatus.docCollected
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const meData: IMe | null = useSelector(meDataSelector)
    // Доступ
    const position: TPosition | undefined = meData?.position
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const toggleStatus = (status: TDealsStatus) => {
        dispatch(toggleStatusThunk(dealId, status, managerId))
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return <div className={s.statusBlock}>
        <div className={`${s.approved} ${(position === 'chief') && !(locked || deleted) ? s.buttonLeft : s.buttonLeftNoAccess} ${approved && s.active}`} onClick={() => {
            (position === 'chief') && !(locked || deleted) ? toggleStatus('approved') : console.log('Ошибка доступа - NO chief')
        }}>
            <div className={s.btnText}>{approved ? 'Одобрено' : 'Не одобрено'}</div>
            {approved && <div className={s.pointDone}/>}
            {loading.approved && <div className={s.loading}><CircularProgress color="secondary" size={13}/></div>}
        </div>
        <div className={`${s.providerPaid} ${(position === 'secretary') && !(locked || deleted) ? s.buttonLeft : s.buttonLeftNoAccess} ${providerPaid && s.active}`} onClick={() => {
            (position === 'secretary') && !(locked || deleted) ? toggleStatus('providerPaid') : console.log('Ошибка доступа - NO secretary')
        }}>
            <div className={s.btnText}>{providerPaid ? 'Закупка оплачена' : 'Закупка не оплачена'}</div>
            {providerPaid && <div className={s.pointDone}/>}
            {loading.providerPaid && <div className={s.loading}><CircularProgress color="secondary" size={13}/></div>}
        </div>
        <div className={`${s.delivered} ${!(locked || deleted) ? s.buttonLeft : s.buttonLeftNoAccess} ${delivered && s.active}`} onClick={() => {
        !(locked || deleted) ? toggleStatus('delivered') : console.log('Ошибка доступа - locked/deleted')
        }}>
            <div className={s.btnText}>{delivered ? 'Вывезли' : 'Не вывезли'}</div>
            {delivered && <div className={s.pointDone}/>}
            {loading.delivered && <div className={s.loading}><CircularProgress color="secondary" size={13}/></div>}
        </div>
        <div className={`${s.clientPaid} ${(position === 'secretary') && !(locked || deleted) ? s.buttonLeft : s.buttonLeftNoAccess} ${clientPaid && s.active}`} onClick={() => {
            (position === 'secretary') && !(locked || deleted) ? toggleStatus('clientPaid') : console.log('Ошибка доступа - NO secretary')
        }}>
            <div className={s.btnText}>{clientPaid ? `Клиент оплатил` : 'Клиент не оплатил'}</div>
            {clientPaid && <div className={s.pointDone}/>}
            {loading.clientPaid && <div className={s.loading}><CircularProgress color="secondary" size={13}/></div>}
        </div>
        <div className={`${s.docSigned} ${(position === 'manager') && !(locked || deleted) ? s.buttonLeft : s.buttonLeftNoAccess} ${docSigned && s.active}`} onClick={() => {
            (position === 'manager') && !(locked || deleted) ? toggleStatus('docSigned') : console.log('Ошибка доступа - NO manager')
        }}>
            <div className={s.btnText}> {docSigned ? 'Документы подписаны' : 'Документы не подписаны'}</div>
            {docSigned && <div className={s.pointDone}/>}
            {loading.docSigned && <div className={s.loading}><CircularProgress color="secondary" size={13}/></div>}
        </div>
        <div className={`${s.docCollected} ${(position === 'director') && !(locked || deleted) ? s.buttonLeft : s.buttonLeftNoAccess} ${docCollected && s.active}`} onClick={() => {
            (position === 'director') && !(locked || deleted) ? toggleStatus('docCollected') : console.log('Ошибка доступа - NO director')
        }}>
            <div className={s.btnText}>{docCollected ? 'Документы собраны' : 'Документы не собраны'}</div>
            {docCollected && <div className={s.pointDone}/>}
            {loading.docCollected && <div className={s.loading}><CircularProgress color="secondary" size={13}/></div>}
        </div>
    </div>;
}

export default StatusBlock