/** Hooks & React **/
import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
/** Styles & Graphics **/
import '../Tables.scss'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
/** Components **/
import SelectField from "../../_organisms/SelectField/SelectField";
/** Types **/
import {TSelectList} from "../../../redux/types/common.types";
/** Utilities **/
/** Thunks and ActionCreators **/
import {
    tablesAC,
    loadingDfOrderData
} from "../../../redux/reducers/tables.reducer";
import {getDfListThunk, getManagersListThunk} from "../../../redux/reducers/forms.reducer";
/** Selectors **/
import {
    filterDfIdSelector,
    filterDateFromSelector,
    filterDateToSelector, filterManagerIdSelector
} from "../../../redux/selectors/tables.selector";
import {allDfSelector, managersListSelector} from "../../../redux/selectors/forms.selector";
import {Button, Tooltip} from "@mui/material";
import SimpleTextField from "../../_organisms/SimpleTextField/SimpleTextField";
/** Other **/

interface IProps {

}

const DfOrdersFilters: React.FC = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const filterDfId: string = useSelector(filterDfIdSelector)
    const filterDateFrom: string = useSelector(filterDateFromSelector)
    const filterDateTo: string = useSelector(filterDateToSelector)
    const filterManagerId: string = useSelector(filterManagerIdSelector)
    const allDf: TSelectList = useSelector(allDfSelector)
    const allManagers: TSelectList = useSelector(managersListSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onChangeFilterDfId = (id: string, dfId: string) => {
        dispatch(tablesAC.setFilterDfId(dfId))
        dispatch(loadingDfOrderData())
    };

    const onChangeFilterManagerId = (id: string, managerId: string) => {
        dispatch(tablesAC.setFilterManagerId(managerId))
        dispatch(loadingDfOrderData())
    };

    const onChangeFilterDateFrom = (e: any) => {
        const dateFrom = e.target.value
        const dateTo = filterDateTo || e.target.value
        dispatch(tablesAC.setFilterDate(dateFrom, dateTo))
        dispatch(loadingDfOrderData())
    };

    const onChangeFilterDateTo = (e: any) => {
        const dateFrom = filterDateFrom || e.target.value
        const dateTo = e.target.value
        dispatch(tablesAC.setFilterDate(dateFrom, dateTo))
        dispatch(loadingDfOrderData())
    };

    const onClearFilters = () => {
        dispatch(tablesAC.clearAllFilters())
        dispatch(loadingDfOrderData())
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(getDfListThunk())
        dispatch(getManagersListThunk())
        // eslint-disable-next-line
    }, [])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return <div className="table__filters">
        <SelectField id={'dfId'}
                     icon={<FilterAltIcon />}
                     label={'Водитель/Экспедитор/Расход'}
                     onChange={onChangeFilterDfId}
                     value={filterDfId}
                     validation
                     list={allDf}
        />
        <SelectField id={'dfId'}
                     icon={<FilterAltIcon />}
                     label={'Менеджер'}
                     onChange={onChangeFilterManagerId}
                     value={filterManagerId}
                     validation
                     list={allManagers}
        />
        <SimpleTextField
            id="date"
            label="от"
            type="date"
            value={filterDateFrom}
            onChange={onChangeFilterDateFrom}
            maxDate={filterDateTo}
        />
        <SimpleTextField
            id="date"
            label="до"
            type="date"
            value={filterDateTo}
            onChange={onChangeFilterDateTo}
            minDate={filterDateFrom}
        />
        <Tooltip title={'Очистить все фильтры'}>
            <Button type={'reset'} variant={'contained'} color={'secondary'} onClick={onClearFilters}><FilterAltOffIcon /></Button>
        </Tooltip>
    </div>
}

export default DfOrdersFilters;