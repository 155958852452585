import React from "react"
import s from './price.module.css'

const Price = (props) => {

    return (
        <div className={s.pricePage}>
            <h1>Прайс-лист</h1>
            <div className={s.priceWrapper}>
                <div className={s.categoryBlock}>
                    <div className={s.categoryTitle}>Трубы</div>
                    <ul>
                        <li>Трубы электросварные круглые</li>
                        <li>Трубы электросварные квадратные</li>
                        <li>Трубы электросварные прямоугольные</li>
                        <li>Трубы ВГП</li>
                        <li>Трубы бесшовные г/д</li>
                        <li>Трубы бесшовные х/д</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Price