/** Hooks & React **/
import React, {useState} from "react";
/** Styles & Graphics **/
import './Deal.scss'
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
/** Components **/
import Comments from "./Comments/Comments";
import DeliverItem from "./DeliverBlock/DeliverItems";
import TextField from "@material-ui/core/TextField";
/** Types **/
import {
    IDealDf,
    IDealDriver,
    IDealForwarder,
    IDealGift,
    IDealsLoading,
    IDealTax
} from "../../../../redux/types/deals.types";
/** Utilities **/
import {useFormik} from "formik";
import {TPosition} from "../../../../redux/types/auth.types";
import {useDispatch} from "react-redux";
import { editAddress } from "../../../../redux/reducers/deals.reducer";
import classNames from "classnames";
import FormDfOrders from "../../../Tables/DfOrders/FormDfOrders";
import {IClient} from "../../../../redux/types/tables.types";
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    df: Array<IDealDf>
    drivers: Array<IDealDriver>
    forwarders: Array<IDealForwarder>
    gifts: Array<IDealGift>
    taxes: Array<IDealTax>
    id: string
    managerId: string
    loading: IDealsLoading
    position: TPosition
    dealDone: boolean
    sumDeliver: number
    sumOther: number
    delta: number
    deltaWD: number
    commentHead: string
    commentManager: string
    address: string
    locked: boolean
    deleted: boolean
    client: IClient
    dealDate: string
}

const DealRight: React.FC<IProps> = ({
                        df,
                        drivers,
                        forwarders,
                        gifts,
                        id,
                        managerId,
                        loading,
                        position,
                        dealDone,
                        sumDeliver,
                        sumOther,
                        delta,
                        deltaWD,
                        commentHead,
                        commentManager,
                        address,
                        taxes,
                                         deleted,
                                         locked, client, dealDate
                    }) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [openAddForwarder, setOpenAddForwarder] = React.useState(false);
    const [openAddDriver, setOpenAddDriver] = React.useState(false);
    const [openAddGift, setOpenAddGift] = React.useState(false);
    const [openAddress, setOpenAddress] = React.useState(false);
    const [addressEditMode, setAddressEditMode] = React.useState(false);
    const [openOther, setOpenOther] = useState(false)
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            addressText: address,
        },
        onSubmit: () => {}
    });
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // окно FORWARDERS
    // открыть
    const onAddForwarderOpen = () => {
        setOpenAddForwarder(true)
    }

    const onChangeOpenOther = () => {
        setOpenOther(!openOther)
    }

    // окно DRIVERS
    // открыть
    const onAddDriverOpen = () => {
        setOpenAddDriver(true)
    }

    // окно GIFTS
    // открыть
    const onAddGiftOpen = () => {
        setOpenAddGift(true)
    }

    // окно address
    // открыть
    const onAddressOpen = () => {
        setOpenAddress(!openAddress)
        setAddressEditMode(false)
    }
    // address режим редактирования
    // открыть
    const onAddressEditMode = () => {
        setAddressEditMode(true)
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    const onAddressEdit = () => {
        dispatch(editAddress(id, formik.values.addressText, managerId))
        setAddressEditMode(false)
    }
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    // отрисовка водителей
    let driversElements = drivers.map(d => <DeliverItem key={d.driverName + d.sum + id}
                                                        id={d.driverName}
                                                        name={d.driverName}
                                                        tel={d.tel}
                                                        auto={d.auto}
                                                        sum={d.sum}
                                                        dealId={id}
                                                        position={position}
                                                        dealDone={dealDone}
                                                        managerId={managerId}
                                                        type={'driver'}
                                                        locked={locked}
                                                        deleted={deleted}
                                                        oldFormat
        />)
    // -----------------------------------------------------------------------------------------------------------------
    // отрисовка экспедиторов
    let forwardersElements = forwarders.map(d => <DeliverItem key={d.forwarderName + d.sum + id}
                                                              id={d.forwarderName}
                                                              name={d.forwarderName}
                                                              tel={d.tel}
                                                              sum={d.sum}
                                                              dealId={id}
                                                              position={position}
                                                              dealDone={dealDone}
                                                              managerId={managerId}
                                                              type={'forwarder'}
                                                              locked={locked}
                                                              deleted={deleted}
                                                              oldFormat
        />)
    // -----------------------------------------------------------------------------------------------------------------
    // отрисовка расходов
    let giftsElements = gifts.map(d => <DeliverItem key={d.giftName + d.sum + id}
                                                    id={d.giftName}
                                                    giftName={d.giftName}
                                                    comment={d.comment}
                                                    sum={d.sum}
                                                    dealId={id}
                                                    position={position}
                                                    dealDone={dealDone}
                                                    managerId={managerId}
                                                    type={'cost'}
                                                    locked={locked}
                                                    deleted={deleted}
                                                    oldFormat
    />)
    // -----------------------------------------------------------------------------------------------------------------
    df.forEach(df => {
        if (df.driver) driversElements.push(<DeliverItem key={df._id}
                                                         id={df._id}
                                                         name={df.df.name}
                                                         tel={df.df.phone}
                                                         auto={df.df.car}
                                                         sum={df.sum}
                                                         dealId={id}
                                                         position={position}
                                                         dealDone={dealDone}
                                                         managerId={managerId}
                                                         type={'driver'}
                                                         locked={locked}
                                                         deleted={deleted}
                                                         loading={df.loading}
                                                         unloading={df.unloading}
                                                         paid={df.paid}
        />)
        if (df.forwarder) forwardersElements.push(<DeliverItem key={df._id}
                                                               id={df._id}
                                                                name={df.df.name}
                                                                 tel={df.df.phone}
                                                                 sum={df.sum}
                                                                 dealId={id}
                                                                 position={position}
                                                                 dealDone={dealDone}
                                                                 managerId={managerId}
                                                                 type={'forwarder'}
                                                                 locked={locked}
                                                                 deleted={deleted}
                                                               loading={df.loading}
                                                               unloading={df.unloading}
                                                               paid={df.paid}
        />)
        if (df.cost) giftsElements.push(<DeliverItem key={df._id}
                                                               id={df._id}
                                                                name={df.df.name}
                                                                 tel={df.df.phone}
                                                                 sum={df.sum}
                                                                comment={df.comment}
                                                                 dealId={id}
                                                                 position={position}
                                                                 dealDone={dealDone}
                                                                 managerId={managerId}
                                                                 type={'cost'}
                                                                 locked={locked}
                                                                 deleted={deleted}
                                                               loading={df.loading}
                                                               unloading={df.unloading}
                                                               paid={df.paid}
        />)

    })
    // -----------------------------------------------------------------------------------------------------------------
    // отрисовка налогов
    let taxesElements = taxes.map(d => <DeliverItem key={d.tax + d.sumTax + id}
                                                    id={d.sumTax}
                                                    bill={d.bill}
                                                    tax={d.tax}
                                                    sumTax={d.sumTax}
                                                    dealId={id}
                                                    position={position}
                                                    dealDone={dealDone}
                                                    managerId={managerId}
                                                    openOther={openOther}
                                                    type={'tax'}
                                                    locked={locked}
                                                    deleted={deleted}
    />)
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/


    return (
        <>
            {/*----------------------начало-------------------ОКНО ADD DRIVER----------------------------------------*/}
            {openAddDriver && <FormDfOrders open={openAddDriver}
                                           where={'deals'}
                                           type={'add'}
                                           setOpen={setOpenAddDriver}
                                           dealId={id}
                                           df={'driver'}
                                           pointsVariants={(client && client.points) || []}
                                            dealDate={dealDate}
                                            managerId={managerId}
            />}
            {/*----------------------конец--------------------ОКНО ADD DRIVER----------------------------------------*/}
            {/*----------------------начало-------------------ОКНО ADD FORWARDER-------------------------------------*/}
            {openAddForwarder && <FormDfOrders open={openAddForwarder}
                                               where={'deals'}
                                               type={'add'}
                                               setOpen={setOpenAddForwarder}
                                               dealId={id}
                                               df={'forwarder'}
                                               pointsVariants={(client && client.points) || []}
                                               dealDate={dealDate}
                                               managerId={managerId}
            />}
            {/*----------------------конец--------------------ОКНО ADD FORWARDER-------------------------------------*/}
            {/*----------------------начало-------------------ОКНО ADD GIFT-------------------------------------*/}
            {openAddGift && <FormDfOrders open={openAddGift}
                                          where={'deals'}
                                          type={'add'}
                                          setOpen={setOpenAddGift}
                                          dealId={id}
                                          df={'cost'}
                                          dealDate={dealDate}
                                          managerId={managerId}
            />}
            {/*----------------------конец--------------------ОКНО ADD GIFT-------------------------------------*/}
            <div className='deal-right'>
                <div className='deal-right__header'>
                    {openOther
                        ? <>
                            <div className='deal-right__header--title'>Другое</div>
                            <div className='deal-right__header--sum'>{sumOther.toLocaleString()} ₽</div>
                        </>
                        : <>
                            <div className='deal-right__header--title'>Доставка</div>
                            <div className='deal-right__header--sum'>{sumDeliver.toLocaleString()} ₽</div>
                        </>}
                    <div className='deal-right__header--loading'>{loading.delivery &&<CircularProgress color="secondary" size={15}/>}</div>
                    {openOther
                        ? <div className='deal-right__header--button' onClick={onChangeOpenOther}>
                            <div>Доставка</div>
                        </div>
                        : <div className='deal-right__header--button' onClick={onChangeOpenOther}>
                            {(gifts.length > 0 || df.some(df => df.cost)) && <div className='delivery-or-other__icon'><ErrorOutlineIcon fontSize={'small'}/></div>}
                            <div>Другое</div>
                        </div>}
                </div>
                <div className='delivery-or-other'>
                    {!openOther
                        ? <div className='delivery-or-other__columns'>
                            <div className='delivery-or-other__categories'>
                                <div className='delivery-or-other__categories-name'>Водители:</div>
                                <div className='delivery-or-other__categories-items'>
                                    {driversElements}
                                    {(position === 'manager' || position === 'chief') && !(locked || deleted) && <div
                                        className='delivery-or-other__categories-item'
                                        onClick={onAddDriverOpen}>
                                        + добавить водителя
                                    </div>}
                                </div>
                            </div>
                            <div className='delivery-or-other__categories'>
                                <div className='delivery-or-other__categories-name'>Экспедиторы:</div>
                                <div className='delivery-or-other__categories-items'>
                                    {forwardersElements}
                                    {(position === 'manager' || position === 'chief') && !(locked || deleted) && <div
                                        className='delivery-or-other__categories-item'
                                        onClick={onAddForwarderOpen}>
                                        + добавить экспедитора
                                    </div>}
                                </div>
                            </div>
                        </div>
                        : <div className='delivery-or-other__columns'>
                            <div className='delivery-or-other__categories'>
                                <div className='delivery-or-other__categories-name'>Налоги:</div>
                                <div className='delivery-or-other__categories-items'>
                                    {taxesElements}
                                </div>
                            </div>
                            <div className='delivery-or-other__categories'>
                                <div className='delivery-or-other__categories-name'>Расходы:</div>
                                <div className='delivery-or-other__categories-items'>
                                    {giftsElements}
                                    {(position === 'manager' || position === 'chief' || position === 'director') && !(locked || deleted) && <div
                                        className='delivery-or-other__categories-item'
                                        onClick={onAddGiftOpen}>
                                        + добавить расход
                                    </div>}
                                </div>
                            </div>
                        </div>}
                </div>
                <div className='dr-address__container'>
                    <div className={classNames('dr-address', {
                        'dr-address__open': openAddress
                    })}>
                        <div className='dr-address__text'>
                            <div className='dr-address__textarea'>
                                <div className='dr-address__textarea-label'>Адрес:</div>
                                {addressEditMode
                                    ? <div className='dr-address__textarea-input'>
                                        <TextField
                                            id="addressText"
                                            fullWidth
                                            size={'small'}
                                            value={formik.values.addressText}
                                            defaultValue={address}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    : <div className='dr-address__value'>{address}</div>}
                                {addressEditMode
                                    ? <div className='dr-address__icon-save' onClick={onAddressEdit}><SaveIcon fontSize={"small"}/></div>
                                    : (position === 'manager') && !(locked || deleted) && <div className='dr-address__icon-save' onClick={onAddressEditMode}><EditIcon fontSize={"small"}/></div>}
                            </div>
                        </div>
                        <div className={classNames('dr-address__label', {
                            'dr-address__true': address.length > 0
                        })}><LocationOnIcon onClick={onAddressOpen} fontSize="small"/></div>
                    </div>
                </div>
                <div className='deal-right__delta'>
                    <div className='deal-right__delta-item'>
                        <div className='deal-right__delta-item--title'>Дельта без доков</div>
                        <div className='deal-right__delta-item--sum'>{deltaWD.toLocaleString()} ₽</div>
                    </div>
                    <div className='deal-right__delta-item'>
                        {delta !== 0 && <>
                            <div className='deal-right__delta-item--title'>Дельта с доками</div>
                            <div className='deal-right__delta-item--sum'>{delta.toLocaleString()} ₽</div>
                        </>}
                    </div>
                </div>
                <Comments loading={loading}
                          position={position}
                          commentManager={commentManager}
                          commentHead={commentHead}
                          id={id}
                          managerId={managerId}
                />
            </div>
        </>
    )
}

export default DealRight;