
/** Hooks & React **/
import React, {Dispatch, SetStateAction, useEffect} from 'react';
import AbcIcon from '@mui/icons-material/Abc';
import BusinessIcon from "@material-ui/icons/Business";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {addClient, tablesAC, updateClient} from "../../../redux/reducers/tables.reducer";
import ErrorValidation from "../../_organisms/ErrorValidation/ErrorValidation";
import {
  savingSelector, tableDataSelector,
  serverErrorSelector,
  successSelector
} from "../../../redux/selectors/tables.selector";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import SimpleTextField from "../../_organisms/SimpleTextField/SimpleTextField";
import {isINNLegalEntity} from "../../../utils/isINNLegalEntity";
import SelectField from "../../_organisms/SelectField/SelectField";
import {commonAC} from "../../../redux/reducers/common.reducer";
import {isSubmittedSelector} from "../../../redux/selectors/common.selectors";
import {IClient} from "../../../redux/types/tables.types";
import { useState } from 'react';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddTextToArray from "../../_organisms/AddTextToArray/AddTextToArray";
/** Styles & Graphics **/
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  open: boolean
  type: 'add' | 'edit'
  setOpen: Dispatch<SetStateAction<boolean>>
  id?: string
}

const FormClient: React.FC<IProps> = ({ open, type, setOpen, id }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  const onSubmit = () => {
    dispatch(commonAC.toggleIsSubmitted(true))
    if (Object.keys(formikErrors).length === 0) {
      if (type ==='edit' && id) {
        dispatch(updateClient(id, formikValues.name, formikValues.inn, formikValues.type, formikValues.phone, formikValues.email, formikValues.points))
      } else {
        dispatch(addClient(formikValues.name, formikValues.inn, formikValues.type, formikValues.phone, formikValues.email, formikValues.points))
      }

    }
  }
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  const serverError: string = useSelector(serverErrorSelector)
  const savingClient: boolean = useSelector(savingSelector)
  const successAdded: boolean = useSelector(successSelector)
  const isSubmitted: boolean = useSelector(isSubmittedSelector)
  const clientsData: Array<IClient> = useSelector(tableDataSelector)
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  const [clientData, setClientData] = useState<IClient | null>()
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */

  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  let validationSchema = yup.object().shape({
    name: yup.string().required('Введите название'),
    inn: yup.number().when(['type'], {
      is: (type: string) => type === 'ЧЛ',
      then: yup.number(),
      otherwise: yup.number()
          .test('innValid', 'Неверный ИНН', value => isINNLegalEntity(value))
          .required('Введите ИНН')
    }),
    type: yup.string().required('Выберите тип компании'),
    // eslint-disable-next-line
    phone: yup.string().matches(/(\+7\ \([1-9]\d\d\)\ \d\d\d\-\d\d\-\d\d)/, 'Введите номер полностью'),
    email: yup.string().email('Некорректный email')
  });
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      name: '',
      inn: '',
      type: '',
      phone: '',
      email: '',
      points: [] as Array<string>,
    },
    onSubmit: onSubmit,
    validateOnMount: true,
    validationSchema: validationSchema
  });

  const formikValues = formik.values
  const formikErrors = formik.errors
  const formikChange = formik.handleChange
  const formikSet = formik.setFieldValue
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onClosePopup = () => {
    setOpen(false)
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  const dispatch = useDispatch()
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  useEffect(() => {
    if (type === 'edit' && id) {
      const client = clientsData.find(client => client._id === id)
      setClientData(client)
    }

    return () => {
      formikSet('name', '')
      formikSet('inn', '')
      formikSet('type', '')
      formikSet('phone', '')
      formikSet('email', '')
      dispatch(tablesAC.toggleSuccess(false))
      dispatch(commonAC.toggleIsSubmitted(false))
      dispatch(tablesAC.setServerError(''))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    successAdded && onClosePopup()
    // eslint-disable-next-line
  }, [successAdded])

  useEffect(() => {
    formikValues.type === 'ЧЛ' && formikSet('inn', '')
    // eslint-disable-next-line
  }, [formikValues.type])

  useEffect(() => {
    if (clientData) {
      formik.setValues({
        name: clientData.name,
        inn: clientData.inn,
        type: clientData.type,
        phone: clientData.phone,
        email: clientData.email,
        points: clientData.points || []
      }, true)
    }
    // eslint-disable-next-line
  }, [clientData])
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */
  /** ###################################### JSX ######################################################################## **/
  return open ? <Dialog onClose={onClosePopup} maxWidth={'xl'} open={open}>
    <DialogTitle>
      {type === 'edit' && clientData ? 'Редактирование клиента ' + clientData.name : 'Добавить нового клиента'}
    </DialogTitle>
    <DialogContent dividers>
      <div className="add-item">
        <SimpleTextField id={'name'}
                         icon={<AbcIcon/>}
                         label={'Название'}
                         onChange={formikChange}
                         value={formikValues.name}
                         validation
                         isSubmitted={isSubmitted}
                         errorMessage={formikErrors.name}
        />
        <SelectField id={'type'}
                     icon={<BusinessIcon/>}
                     label={'Тип'}
                     onChange={formikSet}
                     value={formikValues.type}
                     validation
                     isSubmitted={isSubmitted}
                     errorMessage={formikErrors.type}
                     list={[
                       {id: 'ЧЛ', name: 'ЧЛ'},
                       {id: 'ООО', name: 'ООО'},
                       {id: 'ИП',  name: 'ИП'},
                       {id: 'ПАО', name: 'ПАО'},
                       {id: 'ЗАО', name: 'ЗАО'},
                       {id: 'АО', name: 'АО'}
                     ]}
        />
        {formikValues.type !== 'ЧЛ' && <SimpleTextField id={'inn'}
                          icon={<Grid3x3Icon/>}
                          label={'ИНН'}
                          onChange={formikChange}
                          value={formikValues.inn}
                          validation
                          isSubmitted={isSubmitted}
                          errorMessage={formikErrors.inn}
        />}
        <SimpleTextField id={'phone'}
                         icon={<PhoneIphoneIcon/>}
                         label={'Телефон'}
                         onChange={formikChange}
                         value={formikValues.phone}
                         validation
                         isSubmitted={isSubmitted}
                         errorMessage={formikErrors.phone}
                         type={'phone'}
        />
        <SimpleTextField id={'email'}
                         icon={<EmailIcon/>}
                         label={'E-mail'}
                         onChange={formikChange}
                         value={formikValues.email}
                         validation
                         isSubmitted={isSubmitted}
                         errorMessage={formikErrors.email}
        />
        <AddTextToArray
            id={'points'}
            label={'Адреса объектов'}
            iconInput={<LocationOnIcon fontSize={'small'}/>}
            inputPlaceholder={'Введите название/адрес объекта'}
            buttonLabel={'Добавить адрес'}
            onChange={formikSet}
            value={formikValues.points}
        />
        <ErrorValidation message={serverError} />
      </div>
    </DialogContent>
    <DialogActions>
      <div className="add-item__actions">
        {savingClient && <CircularProgress size={20}/>}
        <Button onClick={onSubmit} variant="contained" color="primary" component="span" disabled={savingClient}>
          {type === 'edit' ? 'Сохранить' : 'Добавить'}
        </Button>
        <Button onClick={onClosePopup} color="primary" disabled={savingClient}>
          Закрыть
        </Button>
      </div>
    </DialogActions>
  </Dialog> : <></>
};
export default FormClient;
