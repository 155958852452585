import {instance} from "./api";
import {AxiosResponse} from "axios";

export const employeesAPI = {
    getAllEmployees(): Promise<any> {
        return instance.get(`employees`).then((response: AxiosResponse<any>) => response.data)
    },
    getAllManagers(): Promise<any> {
        return instance.get(`employees/managers`).then((response: AxiosResponse<any>) => response.data)
    },
    getEmployeeData(id: string): Promise<any> {
        return instance.get(`employees/${id}`).then((response: AxiosResponse<any>) => response.data)
    },
    addNewEmployee(position: string, name: string, head: string, location: string, tel: string, intel: string, birthday: string): Promise<any> {
        return instance.post(`employees/add`, {
            position: position,
            name: name,
            head: head,
            location: location,
            tel: tel,
            intel: intel,
            birthday: birthday
        }).then((response: AxiosResponse<any>) => response.data)
    },
    updateEmployee(id: string, position: string, name: string, head: string, location: string, tel: string, intel: string, birthday: string): Promise<any> {
        return instance.patch(`employees/${id}`, {
            position: position,
            name: name,
            head: head,
            location: location,
            tel: tel,
            intel: intel,
            birthday: birthday
        }).then((response: AxiosResponse<any>) => response.data)
    },
    deleteEmployee(id: string): Promise<any> {
        return instance.delete(`employees/${id}`).then((response: AxiosResponse<any>) => response.data)
    },
    changePosition(id: string): Promise<any> {
        return instance.patch(`employees/managermode?id=${id}`).then((response: AxiosResponse<any>) => response.data)
    }
}