/** Hooks & React **/
import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
/** Styles & Graphics **/
import './AddNewDeal.scss'
/** Components **/
import SimpleTextField from "../../_organisms/SimpleTextField/SimpleTextField";
/** Types **/
/** Utilities **/
import {useFormik} from "formik";
import * as yup from "yup";
/** Thunks and ActionCreators **/
import {addDealThunk} from "../../../redux/reducers/deals.reducer";
import {commonAC} from "../../../redux/reducers/common.reducer";
/** Selectors **/
import {isSubmittedSelector} from "../../../redux/selectors/common.selectors";
import {useHistory} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import SmartTextField from "../../_organisms/SmartTextField/SmartTextField";
import {getClientsListThunk} from "../../../redux/reducers/forms.reducer";
import {clientsListSelector} from "../../../redux/selectors/forms.selector";
/** Other **/

interface IProps {
    openAddDealPopup: boolean
    setOpenAddDealPopup: Dispatch<SetStateAction<boolean>>
}

const AddNewDeal: React.FC<IProps> = ({openAddDealPopup, setOpenAddDealPopup}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const clients = useSelector(clientsListSelector)
    const isSubmitted: boolean = useSelector(isSubmittedSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    let validationSchema = yup.object().shape({
        date: yup.date().required('Выберите дату'),
        client: yup.string().required('Выберите клиента')
    });
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            date: '',
            client: ''
        },
        onSubmit: (): void => {},
        validateOnMount: true,
        validationSchema: validationSchema
    });

    const formikValues = formik.values
    const formikChange = formik.handleChange
    const formikSet = formik.setFieldValue
    const formikErrors = formik.errors
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onCloseAddDealPopup = (): void => {
        setOpenAddDealPopup(false)
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    const history = useHistory()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(getClientsListThunk())

        return () => {
            dispatch(commonAC.toggleIsSubmitted(false))
            formikSet('date', '')
            formikSet('client', '')
        }
        // eslint-disable-next-line
    }, [])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    const onAddNewDeal = () => {
        dispatch(commonAC.toggleIsSubmitted(true))
        if (Object.keys(formikErrors).length === 0) {
            dispatch(addDealThunk(formikValues.date, formikValues.client))
            setOpenAddDealPopup(false)
            history.push('/dealspage')
        }
    }
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return <>
        <Dialog onClose={onCloseAddDealPopup} maxWidth={'xl'} open={openAddDealPopup}>
            <DialogTitle>
                Добавить новую сделку
            </DialogTitle>
            <DialogContent dividers>
                <div className="add-new-deal">
                    <SimpleTextField
                        id="date"
                        label="Дата сделки"
                        type="date"
                        value={formikValues.date}
                        onChange={formikChange}
                        isSubmitted={isSubmitted}
                        validation
                        errorMessage={formikErrors.date}
                    />
                    <SmartTextField id={'client'}
                                    label={'Клиент'}
                                    onChange={formikSet}
                                    value={formikValues.client}
                                    list={clients}
                                    isSubmitted={isSubmitted}
                                    validation
                                    errorMessage={formikErrors.client}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAddNewDeal} variant="contained" color="primary" component="span">
                    Добавить
                </Button>
                <Button onClick={onCloseAddDealPopup} color="primary">
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    </>
};
export default AddNewDeal;
