/** Hooks & React **/
import React, {useState} from 'react';
/** Styles & Graphics **/
import './deliverItem.scss'
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@mui/icons-material/Edit';
import PaidIcon from '@mui/icons-material/Paid';
/** Components **/
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import {TPosition} from "../../../../../redux/types/auth.types";
import classNames from "classnames";
import FormDfOrders from "../../../../Tables/DfOrders/FormDfOrders";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    deleteFunc: () => void
    id: string
    tel?: string
    comment?: string
    auto?: string
    position: TPosition
    dealDone: boolean
    openOther?: boolean
    locked: boolean
    deleted: boolean
    oldFormat?: boolean
    loading: Array<string>
    unloading: Array<string>
    paid: boolean
}

const DeliverItemActions: React.FC<IProps> = ({deleteFunc, oldFormat, id, tel, auto, position,
                                           dealDone, openOther, comment,
                                           locked, deleted, loading, unloading, paid}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [openPaidPopover, setOpenPaidPopover] = useState<boolean>(false);
    const [openInfoPopover, setOpenInfoPopover] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openEditDfOrder, setOpenEditDfOrder] = useState<boolean>(false)
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // edit Item
    const onEdit = () => {
        setOpenEditDfOrder(true)
    }

    const onOpenPaid = (e: any) => {
        setAnchorEl(e.currentTarget)
        setOpenPaidPopover(true)
    }

    const onOpenInfo = (e: any) => {
        setAnchorEl(e.currentTarget)
        setOpenInfoPopover(true)
    }

    const handleClose = () => {
        setOpenPaidPopover(false)
        setOpenInfoPopover(false)
        setAnchorEl(null)
    };
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */

    const loadingElements = loading.map((l, i) => <li key={i + l}>{l}</li>)
    const unloadingElements = unloading.map((unl, i) => <li key={i + unl}>{unl}</li>)
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (position === 'manager' || position === 'chief') && !dealDone && !(locked || deleted) && !openOther ?
        <div className='deliver-item__actions'>
            {paid
                ? <Tooltip title="Оплачено" placement="bottom-end">
                    <div className={classNames('deliver-item__icon')} onClick={onOpenPaid}>
                        <PaidIcon fontSize={'small'}/>
                    </div>
                </Tooltip>
                : <>
                {!oldFormat && <Tooltip title="Редактировать" placement="bottom-end">
                    <div className={classNames('deliver-item__icon')} onClick={onEdit}>
                        <EditIcon fontSize={'small'}/>
                    </div>
                </Tooltip>}
                <Tooltip title="Удалить" placement="bottom-end">
                    <div className={classNames('deliver-item__icon')} onClick={deleteFunc}>
                        <DeleteIcon fontSize={'small'}/>
                    </div>
                </Tooltip>
            </>}
            <Tooltip title="Информация" placement="bottom-start">
                <div className={classNames('deliver-item__icon')} onClick={onOpenInfo}>
                    <InfoIcon fontSize={'small'}/>
                </div>
            </Tooltip>
            <Popover
                id={'info'}
                open={openInfoPopover}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className='deliver-item__popup'>
                    {comment && <div className='deliver-item__popup-items'>{comment}</div>}
                    {tel && <div className='deliver-item__popup-items'>тел. {tel}</div>}
                    {auto && <div className='deliver-item__popup-items'>авто: {auto}</div>}
                    {loadingElements.length > 0 && <div className="deliver-item__popup-loading">
                        <div className="deliver-item__popup-loading-title">Загрузки</div>
                        <ol className="deliver-item__popup-loading-list">
                            {loadingElements}
                        </ol>
                    </div>}
                    {unloadingElements.length > 0 && <div className="deliver-item__popup-loading">
                        <div className="deliver-item__popup-loading-title">Разгрузки</div>
                        <ol className="deliver-item__popup-loading-list">
                            {unloadingElements}
                        </ol>
                    </div>}
                </div>
            </Popover>
            <Popover
                id={'paid'}
                open={openPaidPopover}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className='deliver-item__popup'>
                    Данный ордер уже оплачен - его изменить нельзя! Если нужно добавить сумму то создай еще один ордер на сумму, которую требуется добавить, если же нужно уменьшить, то тогда уже обращайся к директору для решения вопроса.
                </div>
            </Popover>
            {openEditDfOrder && <FormDfOrders open={openEditDfOrder} where={'deals'} type={'edit'} setOpen={setOpenEditDfOrder} id={id}/>}
        </div> : <></>
}

export default DeliverItemActions;