/** Hooks & React **/
import React, {useState} from 'react';
/** Styles & Graphics **/
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
/** Components **/
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/
import Tooltip from "@material-ui/core/Tooltip";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import config from '../../../../config/config.json'
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {TPosition} from "../../../../redux/types/auth.types";
import {useDispatch} from "react-redux";
import {deleteFile} from "../../../../redux/reducers/deals.reducer";
import classNames from "classnames";

interface IProps {
    company: string
    fileUrl: string
    sum: string | number
    typeFile: string
    dealId: string
    position: TPosition
    dealDone: boolean
    managerId: string
    locked: boolean
    deleted: boolean
}

const DocsItem: React.FC<IProps> = ({company, sum, dealId, dealDone, fileUrl,
                                                typeFile, position, managerId, deleted, locked}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [open, setOpen] = React.useState(false);
    const [deleteShow, setDeleteShow] = React.useState(false);
    const [view, setView] = React.useState(false);
    const [askDelete, setAskDelete] = React.useState(false);
    const [zoom, setZoom] = React.useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // open dialog with Image
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // show Delete and View Areas
    const onDeleteShow = () => {
        setDeleteShow(true);
        setView(true);
    };

    const onDeleteHide = () => {
        setDeleteShow(false);
        setAskDelete(false);
        setView(false);
    };

    // show Confirm Delete Area
    const onAskDeleteOpen = () => {
        setAskDelete(true);
    };

    const onAskDeleteClose = () => {
        setAskDelete(false);
    };

    // zoom
    const onZoom = () => {
        setZoom(!zoom);
    };

    // delete Item
    const onDeleteFile = () => {
        dispatch(deleteFile(dealId, fileUrl, typeFile, managerId))
    }

    // PDF Viewer
    // @ts-ignore
    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    const onPdfPageChangePlus = () => {
        if (pageNumber !== numPages) {
            setPageNumber(pageNumber + 1)
        }
    }

    const onPdfPageChangeMinus = () => {
        if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1)
        }
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <>
            <div className='dc-files__item' onMouseEnter={onDeleteShow}
                 onMouseLeave={onDeleteHide}>
                {(position === 'manager' || position === 'chief') && !dealDone && !(locked || deleted) &&
                <div className={classNames('dc-files__delete-btn', {
                    'dc-files__hide': !deleteShow
                })}>
                    {askDelete && <div className='dc-files__ask-delete'>
                        <Tooltip title="Точно удалить" placement="bottom-end">
                            <div className='dc-files__confirm' onClick={onDeleteFile}>
                                <CheckIcon fontSize={"small"}/>
                            </div>
                        </Tooltip>
                        <Tooltip title="Отмена" placement="bottom-start">
                            <div className='dc-files__cancel' onClick={onAskDeleteClose}>
                                <CloseIcon fontSize={"small"}/>
                            </div>
                        </Tooltip>
                    </div>}
                    {!askDelete &&
                    <Tooltip title="Удалить" placement="bottom-end">
                        <div className='dc-files__delete' onClick={onAskDeleteOpen}><DeleteIcon fontSize={"small"}/></div>
                    </Tooltip>}
                </div>}
                <div className={classNames('dc-files__view_btn', {
                    'dc-files__hide': !view
                })}>
                    {view && <div>
                        <div className='dc-files__confirm' onClick={handleClickOpen}>
                            <ImageSearchIcon fontSize={"large"}/>
                        </div>
                    </div>}
                </div>
                <div className={classNames('dc-files__item-name', {
                    'dc-files__small-font': company.length > 6
                })}>
                    {!view && company}
                </div>
                <div className='dc-files__item-sum'>{Number(sum).toLocaleString()} ₽</div>
            </div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={"xl"}
            >
                {/*@ts-ignore*/}
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Счет {company} на сумму {sum} ₽
                </DialogTitle>
                <DialogContent dividers>
                    {fileUrl.split('.')[1] === 'pdf' && <Document
                        file={`${config.baseUrl}/uploads/${fileUrl}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            pageNumber={pageNumber}
                            width={900}
                        />
                    </Document>}
                    <div className={`dc-files__image ${zoom ? 'dc-files__zoom' : 'dc-files__noZoom'}`} onClick={onZoom}>
                        <img alt='' src={`${config.baseUrl}/uploads/${fileUrl}`}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    {fileUrl.split('.')[1] === 'pdf' && <><ButtonGroup disableElevation variant="contained" color="primary">
                        <Button onClick={onPdfPageChangeMinus}>{'<'}</Button>
                        <Button onClick={onPdfPageChangePlus}>{'>'}</Button>
                    </ButtonGroup>
                    <div>Page {pageNumber} of {numPages}</div></>}
                    <Button onClick={handleClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )

}

export default DocsItem;