/** Hooks & React **/
import React, {Dispatch, SetStateAction, useEffect} from 'react';
import AbcIcon from '@mui/icons-material/Abc';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {addDf, tablesAC, updateDf} from "../../../redux/reducers/tables.reducer";
import ErrorValidation from "../../_organisms/ErrorValidation/ErrorValidation";
import {
  savingSelector, tableDataSelector,
  serverErrorSelector,
  successSelector
} from "../../../redux/selectors/tables.selector";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import SimpleTextField from "../../_organisms/SimpleTextField/SimpleTextField";
import {commonAC} from "../../../redux/reducers/common.reducer";
import {isSubmittedSelector} from "../../../redux/selectors/common.selectors";
import {IDriverForwarder} from "../../../redux/types/tables.types";
import { useState } from 'react';
import CheckboxField from "../../_organisms/CheckboxField/CheckboxField";
/** Styles & Graphics **/
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  open: boolean
  type: 'add' | 'edit'
  setOpen: Dispatch<SetStateAction<boolean>>
  id?: string
}

const FormDriversForwarders: React.FC<IProps> = ({ open, type, setOpen, id }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  const onSubmit = () => {
    dispatch(commonAC.toggleIsSubmitted(true))
    if (Object.keys(formikErrors).length === 0) {
      if (type ==='edit' && id) {
        dispatch(updateDf(id, formikValues.name, formikValues.phone, formikValues.car, formikValues.driver, formikValues.forwarder, formikValues.cost))
      } else {
        dispatch(addDf(formikValues.name, formikValues.phone, formikValues.car, formikValues.driver, formikValues.forwarder, formikValues.cost))
      }

    }
  }
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  const serverError: string = useSelector(serverErrorSelector)
  const saving: boolean = useSelector(savingSelector)
  const success: boolean = useSelector(successSelector)
  const isSubmitted: boolean = useSelector(isSubmittedSelector)
  const dfTableData: Array<IDriverForwarder> = useSelector(tableDataSelector)
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  const [dfData, setDfData] = useState<IDriverForwarder | null>()
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */

  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  let validationSchema = yup.object().shape({
    name: yup.string().required('Введите имя'),
    // eslint-disable-next-line
    phone: yup.string().matches(/(\+7\ \([1-9]\d\d\)\ \d\d\d\-\d\d\-\d\d)/, 'Введите номер полностью'),
    car: yup.string()
  });
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      car: '',
      driver: false,
      forwarder: false,
      cost: false,
    },
    onSubmit: onSubmit,
    validateOnMount: true,
    validationSchema: validationSchema
  });

  const formikValues = formik.values
  const formikErrors = formik.errors
  const formikChange = formik.handleChange
  const formikSet = formik.setFieldValue
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onClosePopup = () => {
    setOpen(false)
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  const dispatch = useDispatch()
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  useEffect(() => {
    if (type === 'edit' && id) {
      const df = dfTableData.find(df => df._id === id)
      setDfData(df)
    }

    return () => {
      formik.setValues({
        name: '',
        phone: '',
        car: '',
        driver: false,
        forwarder: false,
        cost: false
      }, true)
      dispatch(tablesAC.toggleSuccess(false))
      dispatch(commonAC.toggleIsSubmitted(false))
      dispatch(tablesAC.setServerError(''))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    success && onClosePopup()
    // eslint-disable-next-line
  }, [success])

  useEffect(() => {
    if (dfData) {
      formik.setValues({
        name: dfData.name,
        phone: dfData.phone,
        car: dfData.car,
        driver: dfData.driver,
        forwarder: dfData.forwarder,
        cost: dfData.cost
      }, true)
    }
    // eslint-disable-next-line
  }, [dfData])

  useEffect(() => {
    if (formikValues.cost) {
      formikSet('phone', '')
      formikSet('car', '')
      formikSet('driver', false)
      formikSet('forwarder', false)
    }
    // eslint-disable-next-line
  }, [formikValues.cost])
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */
  /** ###################################### JSX ######################################################################## **/
  return open ? <Dialog onClose={onClosePopup} maxWidth={'xl'} open={open}>
    <DialogTitle>
      {type === 'edit' && dfData ? 'Редактирование водителя / экспедитора ' + dfData.name : 'Добавить нового водителя / экспедитора'}
    </DialogTitle>
    <DialogContent dividers>
      <div className="add-item">
        <SimpleTextField id={'name'}
                         icon={<AbcIcon/>}
                         label={'Имя или название'}
                         onChange={formikChange}
                         value={formikValues.name}
                         validation
                         isSubmitted={isSubmitted}
                         errorMessage={formikErrors.name}
        />
        {!formikValues.cost && <>
          <SimpleTextField id={'phone'}
                           icon={<PhoneIphoneIcon/>}
                           label={'Телефон'}
                           onChange={formikChange}
                           value={formikValues.phone}
                           validation
                           isSubmitted={isSubmitted}
                           errorMessage={formikErrors.phone}
                           type={'phone'}
          />
          <SimpleTextField id={'car'}
                           icon={<DirectionsCarIcon/>}
                           label={'Авто'}
                           onChange={formikChange}
                           value={formikValues.car}
                           validation
                           isSubmitted={isSubmitted}
                           errorMessage={formikErrors.car}
          />
        </>}
        <CheckboxField id={'driver'} label={'Водитель'} onChange={formikSet} value={formikValues.driver} disabled={formikValues.cost}/>
        <CheckboxField id={'forwarder'} label={'Экспедитор'} onChange={formikSet} value={formikValues.forwarder} disabled={formikValues.cost}/>
        <CheckboxField id={'cost'} label={'Расход'} onChange={formikSet} value={formikValues.cost} />
        <ErrorValidation message={serverError} />
      </div>
    </DialogContent>
    <DialogActions>
      <div className="add-item__actions">
        {saving && <CircularProgress size={20}/>}
        <Button onClick={onSubmit} variant="contained" color="primary" component="span" disabled={saving}>
          {type === 'edit' ? 'Сохранить' : 'Добавить'}
        </Button>
        <Button onClick={onClosePopup} color="primary" disabled={saving}>
          Закрыть
        </Button>
      </div>
    </DialogActions>
  </Dialog> : <></>
};
export default FormDriversForwarders;
