/** Hooks & React **/
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
/** Styles & Graphics **/
import './Deal.scss'
/** Components **/
import DealLeft from "./DealLeft";
import DealCenter from "./DealCenter";
import DealRight from "./DealRight";
/** Types **/
import {IDeal, IDealsLoading} from "../../../../redux/types/deals.types";
import {TPosition} from '../../../../redux/types/auth.types';
/** Utilities **/
/** Thunks and ActionCreators **/
import {updateDelta, updateDeltaWD} from "../../../../redux/reducers/deals.reducer";
/** Selectors **/
import {loadingSelector} from "../../../../redux/selectors/deals.selectors";
/** Other **/

interface IProps {
    dealData: IDeal
    sumClientInvoices: number
    sumProviderInvoices: number
    sumAllDocs: number
    sumDeliver: number
    sumOther: number
    sumDeltaOutDocs: number
    sumDeltaWithDocs: number
    position: TPosition
    location: string
}

const Deal: React.FC<IProps> = ({dealData, sumClientInvoices, sumProviderInvoices, sumAllDocs,
                                    sumDeliver, sumDeltaOutDocs,
                                    sumDeltaWithDocs, sumOther, position, location}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    const {clientInvoices, dealStatus, delta, deltaWD, address, allDocs,
            commentHead, commentManager, drivers, forwarders, gifts,
            providerInvoices, taxes, responsibility, _id, locked, deleted, df } = dealData
/** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const loading: IDealsLoading = useSelector(loadingSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        if (deltaWD !== sumDeltaOutDocs) {
            dispatch(updateDeltaWD(_id, sumDeltaOutDocs))
        }
        // eslint-disable-next-line
    }, [sumDeltaOutDocs]);
    useEffect(() => {
        if (delta !== sumDeltaWithDocs) {
            dispatch(updateDelta(_id, sumDeltaWithDocs))
        }
        // eslint-disable-next-line
    }, [allDocs.length]);
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */

    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const dealDone = dealStatus.dealDone
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='deal'>
            <DealLeft dealData={dealData} position={position} location={location} />
            <DealCenter id={_id}
                        managerId={responsibility._id}
                        sumClientInvoices={sumClientInvoices}
                        sumProviderInvoices={sumProviderInvoices}
                        sumAllDocs={sumAllDocs}
                        clientInvoices={clientInvoices}
                        providerInvoices={providerInvoices}
                        allDocs={allDocs}
                        position={position || 'manager'}
                        dealDone={dealDone}
                        loading={loading}
                        locked={locked}
                        deleted={deleted}
            />
            <DealRight drivers={drivers}
                       forwarders={forwarders}
                       df={df}
                       gifts={gifts}
                       taxes={taxes}
                       id={_id}
                       managerId={responsibility._id}
                       loading={loading}
                       position={position || 'manager'}
                       dealDone={dealDone}
                       sumDeliver={sumDeliver}
                       sumOther={sumOther}
                       delta={delta}
                       deltaWD={deltaWD}
                       commentHead={commentHead}
                       commentManager={commentManager}
                       address={address}
                       locked={locked}
                       deleted={deleted}
                       client={dealData.client}
                       dealDate={dealData.date}
            />

        </div>
    )

}

export default Deal;