/** Hooks & React **/
import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
/** Styles & Graphics **/
import classes from "./NotificationsMini.module.css";
import VisibilityIcon from '@material-ui/icons/Visibility';
/** Components **/
import NotificationMini from "./NotificationMini";
import Tooltip from "@material-ui/core/Tooltip";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
import {
    loadingNotificationsForPopupData, setReadAllNotificationsMini
} from "../../../redux/reducers/notifications.reducer";
import {
    countNotificationsNoReadSelector,
    notificationsPopupSelector
} from "../../../redux/selectors/notifications.selectors";
import {INotification} from "../../../redux/types/notifications.types";
import Preloader from "../../../common/Preloader/Preloader";
/** Selectors **/
/** Other **/
interface IProps {
    setOpenPopup: Dispatch<SetStateAction<boolean>>
}

const NotificationsMiniList: React.FC<IProps> = ({setOpenPopup}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const countNotificationsNoRead: number = useSelector(countNotificationsNoReadSelector)
    const notificationsPopup: Array<INotification> = useSelector(notificationsPopupSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onSetAllRead = () => {
        dispatch(setReadAllNotificationsMini())
    }

    const onClickOutsideHandler = (e: any) => {
        const node = nodeRef.current
        if (node && !node.contains(e.target)) setOpenPopup(false)
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    const nodeRef = useRef<HTMLDivElement>(null)
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(loadingNotificationsForPopupData())
        // eslint-disable-next-line
    }, [countNotificationsNoRead])

    useEffect(() => {
        document.addEventListener('click', onClickOutsideHandler)

        return () => {
            document.removeEventListener('click', onClickOutsideHandler)
        }
        // eslint-disable-next-line
    }, [])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const notificationsElements = notificationsPopup.map(n => <NotificationMini key={n._id}
                                                                                creator={n.creator}
                                                                                deal={n.deal}
                                                                                message={n.message}
                                                                                dt={n.dt}
                                                                                read={n.read}
    />)
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return <div className={classes.notificationsPage} ref={nodeRef}>
        <div className={classes.header}>
            <div className={classes.title}>Уведомления</div>
            <div className={classes.readAll} onClick={onSetAllRead}>
                <Tooltip title="Прочитать все уведомления" placement="top">
                    <VisibilityIcon/>
                </Tooltip>
            </div>
            <div className={classes.viewMore}>
                <NavLink to={`/notifications`}>
                    Посмотреть все уведомления ...
                </NavLink>
            </div>
        </div>
        <div className={classes.table}>
            {notificationsElements.length > 0 ? notificationsElements : <Preloader />}
        </div>
    </div>
}

export default NotificationsMiniList