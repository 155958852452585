/** Hooks & React **/
import React, {useEffect} from 'react';
import Switch from "@material-ui/core/Switch";
import {useDispatch, useSelector} from "react-redux";
import { showDeletedSelector } from '../../../../../redux/selectors/deals.selectors';
import { dealsAC } from '../../../../../redux/reducers/deals.reducer';
/** Styles & Graphics **/
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {

}

const ShowDeleted: React.FC<IProps> = () => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  const showDeleted: boolean = useSelector(showDeletedSelector)
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const toggleShowDeleted = () => {
    dispatch(dealsAC.toggleShowDeleted())
    localStorage.setItem('showDeleted', String(!showDeleted))
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  const dispatch = useDispatch()
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  useEffect(() => {
    const localStorageValue: string | null = localStorage.getItem('showDeleted')
    if (localStorageValue !== null) {
      dispatch(dealsAC.toggleShowDeleted(localStorageValue === 'true'))
    } else {
      localStorage.setItem('showDeleted', String(showDeleted))
    }
    // eslint-disable-next-line
  }, [])
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className="top-bar__show-deleted">
    <div className="top-bar__show-deleted--label">Показывать удаленные сделки</div>
    <Switch
        size={'small'}
        checked={showDeleted}
        onChange={toggleShowDeleted}
        name="showDeleted"
        color="primary"
    />
  </div>
};
export default ShowDeleted;
