/** Hooks & React **/
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
/** Styles & Graphics **/
import './HeaderRight.scss'
/** Components **/
import ProfileBlock from "./ProfileBlock/ProfileBlock";
/** Types **/
import {IDeal} from "../../redux/types/deals.types";
/** Utilities **/
/** Thunks and ActionCreators **/
import {loadingCountNoDeliveredDeals, loadingCountNoDoneDeals} from "../../redux/reducers/profile.reducer";
import { loadingNotificationsNoReadCount } from "../../redux/reducers/notifications.reducer";
/** Selectors **/
import {dealsDataSelector} from "../../redux/selectors/deals.selectors";
import {countNotificationsNoReadSelector} from "../../redux/selectors/notifications.selectors";
/** Other **/
// @ts-ignore
import notification from '../../assets/sounds/newnotification.mp3';
import {meDataSelector} from "../../redux/selectors/auth.selectors";
import {IMe} from "../../redux/types/auth.types";

const HeaderRight: React.FC = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    const audio = new Audio(notification)
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const dealsData: Array<IDeal> = useSelector(dealsDataSelector)
    const meData: IMe | null = useSelector(meDataSelector)
    const countNotificationsNoRead: number = useSelector(countNotificationsNoReadSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(loadingCountNoDoneDeals())
        dispatch(loadingCountNoDeliveredDeals())
        dispatch(loadingNotificationsNoReadCount())
        // eslint-disable-next-line
    }, [dealsData])

    useEffect(() => {
        countNotificationsNoRead !== 0 && audio.play()
        // eslint-disable-next-line
    }, [countNotificationsNoRead])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return <div className='header-right'>
        <div className='header-right__container'>
            {meData && <ProfileBlock meData={meData}/>}
        </div>
    </div>
}
export default HeaderRight