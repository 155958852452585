import {AnyAction, applyMiddleware, combineReducers, createStore} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import dealsReducer from './reducers/deals.reducer';
import authReducer from "./reducers/auth.reducer";
import formsReducer from "./reducers/forms.reducer";
import tablesReducer from "./reducers/tables.reducer";
import driversReducer from "./drivers-reducer";
import forwardersReducer from "./forwarders-reducer";
import companiesReducer from "./reducers/companies.reducer";
import employeesReducer from "./employees-reducer";
import thunkMiddleware, {ThunkDispatch} from 'redux-thunk'
import appReducer from "./reducers/app.reducer";
import profileReducer from "./reducers/profile.reducer";
import notificationsReducer from "./reducers/notifications.reducer";
import settingsReducer from "./settings-reducer";
import releasesReducer from "./reducers/releases.reducer";
import statsReducer from "./stats-reducer";
import { IAppState } from './types/app.types';
import {IAuthState} from "./types/auth.types";
import {IDealsState} from "./types/deals.types";
import {ICompaniesState} from "./types/companies.types";
import {IReleasesState} from "./types/releases.types";
import {INotificationsState} from "./types/notifications.types";
import {IProfileState} from "./types/profile.types";
import {ICommonState} from "./types/common.types";
import commonReducer from './reducers/common.reducer';
import {ITablesState} from "./types/tables.types";
import {IFormsState} from "./types/forms.types";

export interface IStore {
    app: IAppState
    authBlock: IAuthState
    dealsPage: IDealsState
    forms: IFormsState
    employeesPage: any
    tables: ITablesState
    driversPage: any
    forwardersPage: any
    companiesPage: ICompaniesState
    profilePage: IProfileState
    notifications: INotificationsState
    settings: any
    releases: IReleasesState
    stats: any
    common: ICommonState
}

export type TAppState = ReturnType<typeof reducers>;
export type TDispatch = ThunkDispatch<TAppState, void, AnyAction>;
export type TGetState = () => TAppState;

let reducers = combineReducers({
    common: commonReducer,
    app: appReducer,
    authBlock: authReducer,
    dealsPage: dealsReducer,
    forms: formsReducer,
    employeesPage: employeesReducer,
    tables: tablesReducer,
    driversPage: driversReducer,
    forwardersPage: forwardersReducer,
    companiesPage: companiesReducer,
    profilePage: profileReducer,
    notifications: notificationsReducer,
    settings: settingsReducer,
    releases: releasesReducer,
    stats: statsReducer,
});


const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));

export default store;