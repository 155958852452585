/** Hooks & React **/
import React, {ChangeEvent, ReactNode} from 'react';
import ErrorValidation from "../ErrorValidation/ErrorValidation";
/** Styles & Graphics **/
import './SelectField.scss'
/** Components **/
import {MenuItem, Select} from "@material-ui/core";
import OutlinedInput from '@mui/material/OutlinedInput';
/** Types **/
import {TSelectList} from "../../../redux/types/common.types";
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  id: string;
  label: string
  onChange: any
  value: string
  validation?: boolean
  isSubmitted?: boolean
  errorMessage?: string
  icon?: JSX.Element
  list: TSelectList
}

const SelectField: React.FC<IProps> = ({ id, list, icon, label, validation, isSubmitted, value, errorMessage, onChange }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onChangeValue = (e: ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: ReactNode) => {
    onChange(id, e.target.value)
    console.log(e.target.value)
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  const menuItemsElements = list.map((item, idx) => <MenuItem key={idx + item.id} value={item.id}>{item.name}</MenuItem>)
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className='select-field'>
    <div className="select-field__select">
      {icon && icon}
        <Select
            labelId="demo-multiple-name-label"
            id={id}
            value={value}
            onChange={onChangeValue}
            displayEmpty
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
            fullWidth
            variant={'outlined'}
        >
          <MenuItem disabled value="">
            <em>{label}</em>
          </MenuItem>
          {menuItemsElements}
        </Select>
    </div>
    {validation && isSubmitted && <ErrorValidation message={errorMessage}/>}
  </div>
};

export default SelectField;
