import {ITablesState, TTableData, TTableDataItem, TTableName} from "../types/tables.types";
import produce from "immer";
import {TDispatch, TGetState} from "../redux-store";
import {clientsAPI} from "../../API/clients.api";
import {addNewSnackThunk, ifAnyErrorThunk} from "./app.reducer";
import {dfAPI} from "../../API/df.api";
import {dfOrdersAPI} from "../../API/dfOrder.api";
import {dealsAC, loadingDealsPage} from "./deals.reducer";
import {addNewNotification} from "./notifications.reducer";

// Actions

enum ActionsType {
    SET_TABLE_DATA = 'dealCRM/tables/SET_TABLE_DATA',
    TOGGLE_TABLE_DATA_LOADED = '[toggle] Устанавливаем флаг - таблица загружена',
    SET_CURRENT_PAGE = '[set] Устанавливаем номер текущей страницы',
    SET_COUNT_PAGES = '[set] Устанавливаем количество страниц',
    SET_FILTER_DF_ID = '[set] Устанавливаем фильтр - id экспедитора/водителя/расхода',
    SET_FILTER_DATE = '[set] Устанавливаем фильтр - дата',
    SET_FILTER_MANAGER_ID = '[set] Устанавливаем фильтр - id менеджера',
    CLEAR_ALL_FILTERS = '[clear] Сбрасываем все фильтры',
    SET_ITEM_DATA = 'dealCRM/tables/SET_ITEM_DATA',
    SET_FILTERED = '[set] Устанавливаем отфильтрованный список в стейт',
    SET_SERVER_ERROR = '[set] Устанавливаем ошибку с сервера',
    TOGGLE_LOADING = '[toggle] Флаг загрузки',
    TOGGLE_SAVING = '[toggle] Флаг создания / редактирования',
    TOGGLE_SAVING_ROW = '[toggle] Флаг редактирования для строки',
    TOGGLE_SUCCESS = '[toggle] Флаг успешного создания',
    SET_MANAGERS = 'SET_MANAGERS',
    CLEAR_TABLES_REDUCER = '[clear] Очищаем редьюсер Таблицы',
    SET_SEARCH_VALUE = '[set] Ставим значение строки поиска в стейт'
}

// InitialState

let initialState: ITablesState = {
    tableData: [],
    tableDataLoaded: null,
    currentPage: 1,
    pages: 1,
    filters: {
        dfId: '',
        dateFrom: '',
        dateTo: '',
        managerId: ''
    },
    itemData: null,
    filtered: [],
    serverError: '',
    loading: false,
    saving: false,
    savingRow: '',
    success: false,
    managers: [],
    search: ''
}

// ActionCreators

export const tablesAC = {
    setTableData: (data: TTableData) => ({ type: ActionsType.SET_TABLE_DATA, data } as const),
    toggleTableDataLoaded: (tableName: TTableName) => ({ type: ActionsType.TOGGLE_TABLE_DATA_LOADED, tableName } as const),
    setCurrentPage: (payload: number) => ({ type: ActionsType.SET_CURRENT_PAGE, payload } as const),
    setCountPages: (payload: number) => ({ type: ActionsType.SET_COUNT_PAGES, payload } as const),
    setFilterDfId: (payload: string) => ({ type: ActionsType.SET_FILTER_DF_ID, payload } as const),
    setFilterDate: (dateFrom: string, dateTo: string) => ({ type: ActionsType.SET_FILTER_DATE, dateFrom, dateTo } as const),
    setFilterManagerId: (payload: string) => ({ type: ActionsType.SET_FILTER_MANAGER_ID, payload } as const),
    clearAllFilters: () => ({ type: ActionsType.CLEAR_ALL_FILTERS } as const),
    setItemData: (data: TTableDataItem | null) => ({ type: ActionsType.SET_ITEM_DATA, data } as const),
    setFiltered: (data: TTableData) => ({ type: ActionsType.SET_FILTERED, data } as const),
    setManagers: (data: Array<any>) => ({type: ActionsType.SET_MANAGERS, data} as const),
    setServerError: (error: string) => ({type: ActionsType.SET_SERVER_ERROR, error} as const),
    toggleLoading: (toggle: boolean) => ({type: ActionsType.TOGGLE_LOADING, toggle} as const),
    toggleSaving: (toggle: boolean) => ({type: ActionsType.TOGGLE_SAVING, toggle} as const),
    toggleSavingRow: (toggle: string) => ({type: ActionsType.TOGGLE_SAVING_ROW, toggle} as const),
    toggleSuccess: (toggle: boolean) => ({type: ActionsType.TOGGLE_SUCCESS, toggle} as const),
    setSearchValue: (search: string) => ({type: ActionsType.SET_SEARCH_VALUE, search} as const),
    clearReducer: () => ({type: ActionsType.CLEAR_TABLES_REDUCER} as const)
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof tablesAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

const tablesReducer = (state = initialState, action: ActionType): ITablesState =>
    produce(state, draft => {
        switch (action.type) {
            case ActionsType.SET_TABLE_DATA:
                draft.tableData = action.data
                break
            case ActionsType.TOGGLE_TABLE_DATA_LOADED:
                draft.tableDataLoaded = action.tableName
                break
            case ActionsType.SET_CURRENT_PAGE:
                draft.currentPage = action.payload
                break
            case ActionsType.SET_COUNT_PAGES:
                draft.pages = action.payload
                break
            case ActionsType.SET_FILTER_DF_ID:
                draft.filters.dfId = action.payload
                break
            case ActionsType.SET_FILTER_DATE:
                draft.filters.dateFrom = action.dateFrom
                draft.filters.dateTo = action.dateTo
                break
            case ActionsType.SET_FILTER_MANAGER_ID:
                draft.filters.managerId = action.payload
                break
            case ActionsType.CLEAR_ALL_FILTERS:
                draft.filters.dfId = ''
                draft.filters.dateFrom = ''
                draft.filters.dateTo = ''
                draft.filters.managerId = ''
                break
            case ActionsType.SET_ITEM_DATA:
                draft.itemData = action.data
                break
            case ActionsType.SET_FILTERED:
                draft.filtered = action.data
                break
            case ActionsType.SET_MANAGERS:
                draft.managers = action.data
                break
            case ActionsType.SET_SERVER_ERROR:
                draft.serverError = action.error
                break
            case ActionsType.TOGGLE_LOADING:
                draft.loading = action.toggle
                break
            case ActionsType.TOGGLE_SAVING:
                draft.saving = action.toggle
                break
            case ActionsType.TOGGLE_SAVING_ROW:
                draft.savingRow = action.toggle
                break
            case ActionsType.TOGGLE_SUCCESS:
                draft.success = action.toggle
                break
            case ActionsType.SET_SEARCH_VALUE:
                draft.search = action.search
                break
            case ActionsType.CLEAR_TABLES_REDUCER:
                draft.search = ''
                draft.tableData = []
                draft.tableDataLoaded = null
                draft.filtered = []
                draft.itemData = null
                draft.managers = []
                draft.serverError = ''
                draft.saving = false
                draft.savingRow = ''
                draft.success = false
                break
        }
    })

// Thunks
export const loadingClientsData = () => async (dispatch: TDispatch) => {
    try {
        dispatch(tablesAC.toggleLoading(true))
        const tableData = await clientsAPI.getAllClients()
        dispatch(tablesAC.setTableData(tableData))
        await dispatch(filterClientsThunk())
        dispatch(tablesAC.toggleTableDataLoaded('clients'))
        dispatch(tablesAC.toggleLoading(false))
    } catch (e: any) { alert(e)}
}

export const loadingDfData = () => async (dispatch: TDispatch) => {
    try {
        dispatch(tablesAC.toggleLoading(true))
        const tableData = await dfAPI.getAllDf()
        dispatch(tablesAC.setTableData(tableData))
        await dispatch(filterDfThunk())
        dispatch(tablesAC.toggleTableDataLoaded('df'))
        dispatch(tablesAC.toggleLoading(false))
    } catch (e: any) { alert(e)}
}

export const loadingDfOrderData = () => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        dispatch(tablesAC.toggleLoading(true))
        const page: number = getState().tables.currentPage
        const filterDfId: string = getState().tables.filters.dfId
        const filterDateFrom: string = getState().tables.filters.dateFrom
        const filterDateTo: string = getState().tables.filters.dateTo
        const filterManagerId: string = getState().tables.filters.managerId
        const tableData = await dfOrdersAPI.getAllDfOrders(page, filterDfId, filterDateFrom, filterDateTo, filterManagerId)
        dispatch(tablesAC.setTableData(tableData.result))
        dispatch(tablesAC.setCountPages(tableData.pages))
        await dispatch(filterDfOrdersThunk())
        dispatch(tablesAC.toggleTableDataLoaded('dforder'))
        dispatch(tablesAC.toggleLoading(false))
    } catch (e: any) { alert(e)}
}

export const loadingDfOrderItemData = (id: string) => async (dispatch: TDispatch) => {
    try {
        const itemData = await dfOrdersAPI.getDfOrderById(id)
        dispatch(tablesAC.setItemData(itemData))
    } catch (e: any) { alert(e)}
}

export const filterClientsThunk = () => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        let clients = getState().tables.tableData
        const searchValue = getState().tables.search
        const filterText = (originalText: string, searchValue: string, array: boolean = false): boolean => {
            if (array) {
                let searchArray = searchValue.split(' ')
                searchArray = searchArray.filter(word => word)
                return searchArray.every(word => originalText.toLowerCase().includes(word.toLowerCase()))
            }
            return originalText.toLowerCase().includes(searchValue.toLowerCase())
        };

        const allFiltersFunc = (item: TTableDataItem) => {
            let bigString = ''
            if (item.name) bigString += item.name
            if (item.inn ) bigString += item.inn
            if (item.email) bigString += item.email
            if (item.phone) bigString += item.phone
            if (item.manager && item.manager.name) bigString += item.manager.name
            return filterText(bigString, searchValue, true)
        }

        if (searchValue) {
            clients = clients.filter((item: TTableDataItem) => allFiltersFunc(item))
        }
        dispatch(tablesAC.setFiltered(clients))
    } catch (e: any) { alert(e)}
}

export const filterDfThunk = () => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        let df = getState().tables.tableData
        const searchValue = getState().tables.search
        const filterText = (originalText: string, searchValue: string, array: boolean = false): boolean => {
            if (array) {
                let searchArray = searchValue.split(' ')
                searchArray = searchArray.filter(word => word)
                return searchArray.every(word => originalText.toLowerCase().includes(word.toLowerCase()))
            }
            return originalText.toLowerCase().includes(searchValue.toLowerCase())
        };

        const allFiltersFunc = (item: TTableDataItem) => {
            let bigString = ''
            if (item.name) bigString += item.name
            if (item.phone) bigString += item.phone
            if (item.car) bigString += item.car
            return filterText(bigString, searchValue, true)
        }

        if (searchValue) {
            df = df.filter((item: TTableDataItem) => allFiltersFunc(item))
        }
        dispatch(tablesAC.setFiltered(df))
    } catch (e: any) { alert(e)}
}

export const filterDfOrdersThunk = () => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        let df = getState().tables.tableData
        const searchValue = getState().tables.search
        const filterText = (originalText: string, searchValue: string, array: boolean = false): boolean => {
            if (array) {
                let searchArray = searchValue.split(' ')
                searchArray = searchArray.filter(word => word)
                return searchArray.every(word => originalText.toLowerCase().includes(word.toLowerCase()))
            }
            return originalText.toLowerCase().includes(searchValue.toLowerCase())
        };

        const allFiltersFunc = (item: TTableDataItem) => {
            let bigString = ''
            if (item.df.name) bigString += item.df.name
            if (item.df.phone) bigString += item.df.phone
            if (item.df.car) bigString += item.df.car
            if (item.sum) bigString += item.sum
            return filterText(bigString, searchValue, true)
        }

        if (searchValue) {
            df = df.filter((item: TTableDataItem) => allFiltersFunc(item))
        }
        dispatch(tablesAC.setFiltered(df))
    } catch (e: any) { alert(e)}
}



export const addClient = (name: string, inn: string, type: string, phone: string, email: string, points: Array<string>) => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        dispatch(tablesAC.toggleSaving(true))
        const manager: string = getState().authBlock.me._id
        await clientsAPI.addNewClient(name, inn, type, phone, email, manager, points)
        await dispatch(loadingClientsData())
        dispatch(tablesAC.toggleSuccess(true))
        dispatch(tablesAC.toggleSaving(false))
    } catch (e: any) {
        dispatch(tablesAC.toggleSaving(false))
        if (e.response.data.message) {
            dispatch(addNewSnackThunk('error', e.response.data.message, true))
            dispatch(tablesAC.setServerError(e.response.data.message))
        } else {
            ifAnyErrorThunk(e)
        }
    }
}

export const addDf = (name: string, phone: string, car: string, driver: boolean, forwarder: boolean, cost: boolean) => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        dispatch(tablesAC.toggleSaving(true))
        await dfAPI.addNewDf(name, phone, car, driver, forwarder, cost)
        await dispatch(loadingDfData())
        dispatch(tablesAC.toggleSuccess(true))
        dispatch(tablesAC.toggleSaving(false))
    } catch (e: any) {
        dispatch(tablesAC.toggleSaving(false))
        if (e.response.data.message) {
            dispatch(addNewSnackThunk('error', e.response.data.message, true))
            dispatch(tablesAC.setServerError(e.response.data.message))
        } else {
            ifAnyErrorThunk(e)
        }
    }
}


// добавление водителя в сделку
export const addDFOrderThunk = (id: string, driverId: string, sum: number, df: 'driver' | 'forwarder' | 'cost', loading: Array<string>, unloading: Array<string>, comment: string, dealDate: string, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    const managerId: string | undefined = getState().authBlock.me?._id
    try {
        dispatch(tablesAC.toggleSaving(true))
        dispatch(dealsAC.toggleLoading('delivery', true))
        await dfOrdersAPI.addNewDfOrder(id, driverId, sum, df, loading, unloading, comment, dealDate, managerId)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        const text: string = `добавил ${df === 'driver' ? 'водителя' : 'экспедитора'}`
        await dispatch(addNewNotification(managerId, id, text))
        headId && await dispatch(addNewNotification(headId, id, text))
        dispatch(tablesAC.toggleSuccess(true))
        dispatch(tablesAC.toggleSaving(false))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

export const updateClient = (id: string, name: string, inn: string, type: string, phone: string, email: string, points: Array<string>) => async (dispatch: TDispatch) => {
    try {
        dispatch(tablesAC.toggleSaving(true))
        await clientsAPI.updateClient(id, name, inn, type, phone, email, points)
        dispatch(tablesAC.toggleSaving(false))
        dispatch(tablesAC.toggleSuccess(true))
        await dispatch(loadingClientsData())
    } catch (e: any) {
        dispatch(tablesAC.toggleSaving(false))
        if (e.response.data.message) {
            dispatch(addNewSnackThunk('error', e.response.data.message, true))
            dispatch(tablesAC.setServerError(e.response.data.message))
        } else {
            ifAnyErrorThunk(e)
        }
    }
}

export const updateDf = (id: string, name: string, phone: string, car: string, driver: boolean, forwarder: boolean, cost: boolean) => async (dispatch: TDispatch) => {
    try {
        dispatch(tablesAC.toggleSaving(true))
        await dfAPI.updateDf(id, name, phone, car, driver, forwarder, cost)
        dispatch(tablesAC.toggleSaving(false))
        dispatch(tablesAC.toggleSuccess(true))
        await dispatch(loadingDfData())
    } catch (e: any) {
        dispatch(tablesAC.toggleSaving(false))
        if (e.response.data.message) {
            dispatch(addNewSnackThunk('error', e.response.data.message, true))
            dispatch(tablesAC.setServerError(e.response.data.message))
        } else {
            ifAnyErrorThunk(e)
        }
    }
}

export const paidDfOrderThunk = (id: string) => async (dispatch: TDispatch) => {
    try {
        dispatch(tablesAC.toggleSavingRow(id))
        await dfOrdersAPI.paidDfOrder(id)
        await dispatch(loadingDfOrderData())
        dispatch(tablesAC.toggleSavingRow(''))
    } catch (e: any) {
        dispatch(tablesAC.toggleSavingRow(''))
        if (e.response.data.message) {
            dispatch(addNewSnackThunk('error', e.response.data.message, true))
            dispatch(tablesAC.setServerError(e.response.data.message))
        } else {
            ifAnyErrorThunk(e)
        }
    }
}

export const updateDfOrderThunk = (dealId: string, driverId: string, sum: number, df: 'driver' | 'forwarder' | 'cost', loading: Array<string>, unloading: Array<string>, where: 'deals' | 'table', comment: string) => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        const headId: string | undefined = getState().authBlock.me?.head._id
        const managerId: string | undefined = getState().authBlock.me?._id
        dispatch(tablesAC.toggleSaving(true))
        where === 'deals' && dispatch(dealsAC.toggleLoading('delivery', true))
        await dfOrdersAPI.updateDfOrder(dealId, driverId, sum, df, loading, unloading, comment)
        if (where === 'deals') {
            if (getState().dealsPage.oneDealMode) {
                await dispatch(loadingDealsPage(dealId))
            } else {
                await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
            }
        }
        const text: string = `отредактировал ордер на ${df === 'driver' ? 'водителя' : df === 'forwarder' ? 'экспедитора' : 'расход'}`
        await dispatch(addNewNotification(managerId, dealId, text))
        headId && await dispatch(addNewNotification(headId, dealId, text))
        dispatch(tablesAC.toggleSuccess(true))
        dispatch(tablesAC.toggleSaving(false))
        where === 'table' && await dispatch(loadingDfOrderData())
    } catch (e: any) {
        dispatch(tablesAC.toggleSaving(false))
        if (e.response.data.message) {
            dispatch(addNewSnackThunk('error', e.response.data.message, true))
            dispatch(tablesAC.setServerError(e.response.data.message))
        } else {
            ifAnyErrorThunk(e)
        }
    }
}

export const deleteClient = (id: string) => async (dispatch: TDispatch) => {
    try {
        await clientsAPI.deleteClient(id)
    } catch (e: any) { alert(e.response.data.message) }
    await dispatch(loadingClientsData())
}

export default tablesReducer;