/** Hooks & React **/
import React, {Dispatch, SetStateAction} from 'react';
import {IDriverForwarder} from "../../../redux/types/tables.types";
import { ISearchHighlighter, markSearchText } from '../../../utils/markSearchText';
import { useMemo } from 'react';
import {useSelector} from "react-redux";
import {searchSelector} from "../../../redux/selectors/tables.selector";
/** Styles & Graphics **/
import AbcIcon from '@mui/icons-material/Abc';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AttributionIcon from '@mui/icons-material/Attribution';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EditIcon from '@mui/icons-material/Edit';
import {Button} from "@mui/material";
import {userIdSelector} from "../../../redux/selectors/auth.selectors";
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    data: IDriverForwarder
    setEditMode: Dispatch<SetStateAction<boolean>>
    setId: Dispatch<SetStateAction<string>>
}

const DriversForwardersRow: React.FC<IProps> = ({data: {_id, name, phone, driver, forwarder, cost, car, deals}, setId, setEditMode }) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const search: string = useSelector(searchSelector)
    const userId: string | null = useSelector(userIdSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onEditDf = () => {
        setId(_id)
        setEditMode(true)
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const NAME_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(name, search), [name, search])
    const CAR_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(car, search), [car, search])
    const PHONE_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(phone, search), [phone, search])

    const dfRole = (): string => {
        const array = [driver, forwarder, cost]
        return array.map((bool, i) => {
            if (i === 0 && bool) return 'Водитель'
            if (i === 1 && bool) return 'Экспедитор'
            if (i === 2 && bool) return 'Расход'
            return ''
        }).filter(b => b).join(' / ')
    }
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */


    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='table__row'>
            <div className="table__row-field table__row-edit">
                {userId && <Button variant={'text'} color={'primary'} onClick={onEditDf}><EditIcon/></Button>}
            </div>
            <div className="table__row-field table__row-name">
                <div className='table__row-field--icon'><AbcIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{NAME_SEARCH.jsx}</div>
            </div>
            <div className="table__row-field table__row-car">
                <div className='table__row-field--icon'><DirectionsCarIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{CAR_SEARCH.jsx}</div>
            </div>
            <div className="table__row-field table__row-phone">
                <div className='table__row-field--icon'><PhoneIphoneIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{PHONE_SEARCH.jsx}</div>
            </div>
            <div className="table__row-field table__row-position">
                <div className='table__row-field--icon'><AttributionIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>
                    {dfRole()}
                </div>
            </div>
        </div>
    )
}

export default DriversForwardersRow;