/** Hooks & React **/
import React, {useState} from 'react';
/** Styles & Graphics **/
import './deliverItem.scss'
/** Components **/
import {TPosition} from "../../../../../redux/types/auth.types";
import {useDispatch} from "react-redux";
import {
    deleteDFFromDeal,
    deleteDriverFromDealOldFormat,
    deleteForwarderFromDealOldFormat,
    deleteGiftFromDeal
} from "../../../../../redux/reducers/deals.reducer";
import classNames from "classnames";
import DeliverItemActions from "./DeliverItemsActions";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    id: string
    name?: string
    giftName?: string
    tel?: string
    comment?: string
    auto?: string
    sum?: number
    dealId: string
    position: TPosition
    dealDone: boolean
    managerId: string
    bill?: string
    tax?: number
    sumTax?: string
    openOther?: boolean
    type: 'forwarder' | 'driver' | 'cost' | 'tax'
    locked: boolean
    deleted: boolean
    oldFormat?: boolean
    loading?: Array<string>
    unloading?: Array<string>
    paid?: boolean
}

const DeliverItem: React.FC<IProps> = ({id, name, tel, auto,
                                           sum, dealId, position,
                                           dealDone, managerId, bill,
                                           tax, sumTax, openOther,
                                           giftName, comment, type,
                                           locked, deleted, oldFormat = false,
                                           loading = [], unloading = [], paid = false}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [showActions, setShowActions] = useState(false)
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // show Actions
    const onShowActions = () => {
        setShowActions(true)
    }
    const onHideActions = () => {
        setShowActions(false)
    }

    // delete Item
    const onDelete = () => {
        if (oldFormat) {
            type === 'forwarder' && name && sum && dispatch(deleteForwarderFromDealOldFormat(dealId, name, sum, managerId))
            type === 'driver' && name && sum && dispatch(deleteDriverFromDealOldFormat(dealId, name, sum, managerId))
            type === 'cost' && giftName && sum && dispatch(deleteGiftFromDeal(dealId, giftName, sum, managerId))
        } else {
            (type === 'forwarder' || type === 'driver' || type === 'cost') && name && sum && dispatch(deleteDFFromDeal(dealId, id, name, sum, type))
        }
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='deliver-item' onMouseEnter={onShowActions} onMouseLeave={onHideActions}>
            <div className={classNames('deliver-item__name', {
                'deliver-item__old-format': oldFormat,
                'deliver-item__small-text': name && name.length > 16
            })}>
                {type === 'tax' && `${bill === 'nn' ? ' +' : ' -'} ${tax} %`}
                {(type === 'driver' || type === 'forwarder' || type === 'cost') && name}
                {showActions && type !== 'tax' && <DeliverItemActions
                    deleteFunc={onDelete}
                    id={id}
                    tel={tel}
                    auto={auto}
                    comment={comment}
                    position={position}
                    dealDone={dealDone}
                    locked={locked}
                    deleted={deleted}
                    oldFormat={oldFormat}
                    loading={loading}
                    unloading={unloading}
                    paid={paid}
                />}
            </div>
            {sum ? <div className='deliver-item__sum'>{sum.toLocaleString()} ₽</div> : <></>}
            {type === 'tax' && sumTax && <div className='deliver-item__sum'>{sumTax.toLocaleString()} ₽</div>}
        </div>
    )

}

export default DeliverItem;