/** Hooks & React **/
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom';
/** Styles & Graphics **/
import classes from "./Notifications.module.css";
/** Components **/
import Notification from "./Notification";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
/** Types **/
import {INotification} from "../../../redux/types/notifications.types";
/** Utilities **/
/** Thunks and ActionCreators **/
import {
    loadingAllNotificationsData,
    setReadAllNotifications
} from "../../../redux/reducers/notifications.reducer";
/** Selectors **/
import {
    isFetchingSelector,
    notificationsCurrentPageSelector, notificationsPagesCountSelector,
    notificationsSelector
} from "../../../redux/selectors/notifications.selectors";
/** Other **/

const NotificationsList: React.FC = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    const params: Record<string, string> = useParams()
    const page: number | undefined = +params.page
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const notifications: Array<INotification> = useSelector(notificationsSelector)
    const notificationsCurrentPage: number = useSelector(notificationsCurrentPageSelector)
    const notificationsPagesCount: number = useSelector(notificationsPagesCountSelector)
    const isFetching: boolean = useSelector(isFetchingSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onSetAllRead = () => {
        if (page) {
            dispatch(setReadAllNotifications(page))
        } else {
            dispatch(setReadAllNotifications(1))
        }
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        if (page) {
            dispatch(loadingAllNotificationsData(page))
        } else {
            dispatch(loadingAllNotificationsData(1))
        }
        // eslint-disable-next-line
    }, [page])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    const notificationsElements = notifications.map(n => <Notification key={n._id}
                                                                       creator={n.creator}
                                                                       deal={n.deal}
                                                                       message={n.message}
                                                                       dt={n.dt}
                                                                       read={n.read}
    />)

    return <div className={classes.notificationsPage}>

        <div className={classes.titleHeader}>
            <div className={classes.title}><h1>Уведомления</h1></div>
            <div className={classes.readAll}>
                <Button onClick={onSetAllRead} variant="outlined" color="primary">Прочитать все уведомления</Button>
            </div>
        </div>
        <div className={classes.paginationContainer}>
            <div className={classes.pagination}>
                <Pagination
                    page={notificationsCurrentPage ? notificationsCurrentPage : 1}
                    count={notificationsPagesCount}
                    renderItem={(item) => (
                        <PaginationItem
                            component={Link}
                            to={`/notifications/${item.page === 1 ? '' : `${item.page}`}`}
                            {...item}
                        />
                    )}
                />
            </div>
            {isFetching ? <div className={classes.progress}>
                <CircularProgress
                    size={25}
                    thickness={7}
                />
            </div> : null}
        </div>
        <div className={`${classes.table}`}>
            {notificationsElements}
        </div>
    </div>
}


export default NotificationsList