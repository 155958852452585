/** Hooks & React **/
import React, {useEffect} from 'react';
import {Link, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
/** Styles & Graphics **/
import './DealsPage.scss'
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import CircularProgress from "@material-ui/core/CircularProgress";
/** Components **/
import DealsItems from "./DealsItems/DealsItems";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
import {
    loadingDealsPage
} from "../../redux/reducers/deals.reducer";
/** Selectors **/
import {
    dealsCurrentPageSelector,
    dealsPageCountSelector,
    isFetchingSelector, showDeletedSelector
} from "../../redux/selectors/deals.selectors";
import ShowDeleted from "./DealsItems/Deal/_organisms/ShowDeleted";
/** Other **/

const DealsPage: React.FC = () =>  {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const dealsCurrentPage: number = useSelector(dealsCurrentPageSelector)
    const dealsPageCount: number = useSelector(dealsPageCountSelector)
    const isFetching: boolean = useSelector(isFetchingSelector)
    const showDeleted: boolean = useSelector(showDeletedSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    const location = useLocation()
    const params: Record<string, string> = useParams()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        let page = location.search // страница /dealspage?page=
        let id = params.id // id сделки /dealspage/:id
        if (!page) {
            // если страница не указана
            if (id) {
                // если id присутствует отправляем его в thunk loadingDealsPage
                dispatch(loadingDealsPage(id))
            } else {
                // иначе загружаем первую страницу сделок
                dispatch(loadingDealsPage(null, null, 1))
            }
        } else {
            // если страница указана, берем номер страницы и отправляем его в thunk loadingDealsPage
            dispatch(loadingDealsPage(null, null, +page.slice(6)))
        }
        // eslint-disable-next-line
    }, [location.search, params.id, showDeleted])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='deals-page'>
            {/*если в адресной строке не вбит id то показываем Пагинатор*/}
            {!params.id && <div className='top-bar'>
                <div className='deals-page__pagination'>
                    <Pagination
                        shape="rounded"
                        size="small"
                        page={dealsCurrentPage}
                        count={dealsPageCount}
                        renderItem={(item) => (
                            <PaginationItem
                                component={Link}
                                to={`/dealspage${item.page === 1 ? '' : `?page=${item.page}`}`}
                                {...item}
                            />
                        )}
                    />
                </div>
                <ShowDeleted />
                {/*если идет загрузка показываем анимацию*/}
                {isFetching && <div className='deals-page__progress'>
                    <CircularProgress
                        size={25}
                        thickness={7}
                    />
                </div>}
            </div>}
            <DealsItems />
        </div>
    )
}

export default DealsPage

