/** Hooks & React **/
import React, {Dispatch, SetStateAction} from 'react';
import config from "../../../config/config.json";
import {IDfOrder} from "../../../redux/types/tables.types";
import moment from 'moment'
import { ISearchHighlighter, markSearchText } from '../../../utils/markSearchText';
import { useMemo } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {savingRowSelector, searchSelector} from "../../../redux/selectors/tables.selector";
/** Styles & Graphics **/
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import EventIcon from '@mui/icons-material/Event';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import EditIcon from '@mui/icons-material/Edit';
import {Button, CircularProgress} from "@mui/material";
import {meDataSelector} from "../../../redux/selectors/auth.selectors";
import CheckboxField from "../../_organisms/CheckboxField/CheckboxField";
import {paidDfOrderThunk} from "../../../redux/reducers/tables.reducer";
import classNames from "classnames";
import {IMe} from "../../../redux/types/auth.types";
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    data: IDfOrder
    setEditMode: Dispatch<SetStateAction<boolean>>
    setId: Dispatch<SetStateAction<string>>
}

const DfOrdersRow: React.FC<IProps> = ({data: {_id, df, driver, forwarder, cost, comment, paid, sum, deal, loading, unloading }, setId, setEditMode }) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const search: string = useSelector(searchSelector)
    const savingRow: string = useSelector(savingRowSelector)
    const meData: IMe | null = useSelector(meDataSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onEditClient = () => {
        setId(_id)
        setEditMode(true)
    }

    const onChangePaid = () => {
        dispatch(paidDfOrderThunk(_id))
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const MANAGER_NAME: string = (deal && deal.responsibility && deal.responsibility.name) ? deal.responsibility.name : ''
    const NAME: string = df ? df.name : ''
    const NAME_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(NAME, search), [NAME, search])
    const MANAGER_NAME_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(MANAGER_NAME, search), [MANAGER_NAME, search])
    const DEAL: string = (deal && deal.responsibility) ? (deal.responsibility.location === 'korolev' ? 'ДК-' : 'ДМ-') + deal.dealNumber + ' ' + (deal.client ? deal.client.name : deal.clientOld) : ''
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='table__row'>
            {meData && meData.position === 'director' && <div className="table__row-field table__row-edit">
                <Button variant={'text'} color={'primary'} onClick={onEditClient}><EditIcon/></Button>
            </div>}
            <div className="table__row-field table__row-date">
                <div className='table__row-field--icon'><EventIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{moment(deal.date).format('DD.MM.YYYY')}</div>
            </div>
            <div className="table__row-field table__row-df">
                <div className='table__row-field--icon'><AccountCircleIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>
                    <span className='table__row-field--df'>
                        {driver ? <LocalShippingIcon fontSize={'small'}/> : forwarder ? <AirlineSeatReclineExtraIcon fontSize={'small'}/> : <PointOfSaleIcon fontSize={'small'}/>}
                    </span>{NAME_SEARCH.jsx}
                </div>
            </div>
            <div className="table__row-field table__row-deal">
                <div className='table__row-field--icon'><Grid3x3Icon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{DEAL}</div>
            </div>
            <div className="table__row-field table__row-points-from">
                <div className='table__row-field--icon'><LocationSearchingIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{loading.join(', ')}</div>
            </div>
            <div className="table__row-field table__row-points-to">
                <div className='table__row-field--icon'><MyLocationIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{unloading.join(', ')}</div>
            </div>
            <div className={classNames("table__row-field table__row-sum", {
                "table__row-field table__row-sum--paid": paid
            })}>{sum.toLocaleString()} ₽</div>
            {meData && meData.position === 'director' && <div className="table__row-field table__row-paid">
                <CheckboxField id={'paid'} onChange={onChangePaid} value={paid}/>
                {savingRow === _id && <CircularProgress color="secondary" size={20}/>}
            </div>}
            <div className="table__row-field table__row-manager">
                <div className="table__row-manager--name">{MANAGER_NAME_SEARCH.jsx}</div>
                <div className="table__row-manager--avatar">
                    <img alt='' className="deal-mini__manager-avatar"
                         src={deal.responsibility.avatar !== ''
                             ? `${config.baseUrl}/${deal.responsibility.avatar}`
                             : `${config.baseUrl}/images/avatars/default.png`}/>
                </div>
            </div>
        </div>
    )
}

export default DfOrdersRow;