/** Hooks & React **/
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
/** Styles & Graphics **/
import '../Tables.scss'
/** Components **/
import DfOrdersRow from "./DfOrdersRow";
/** Types **/
import {IDfOrder, TTableName} from "../../../redux/types/tables.types";
/** Utilities **/
/** Thunks and ActionCreators **/
import {
    tablesAC,
    filterDfOrdersThunk,
    loadingDfOrderData
} from "../../../redux/reducers/tables.reducer";
/** Selectors **/
import {
    tableDataLoadedSelector,
    filteredSelector,
    searchSelector, loadingSelector, currentPageSelector, countPagesSelector
} from "../../../redux/selectors/tables.selector";
import FormDfOrders from './FormDfOrders';
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import {Link, useParams} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import DfOrdersFilters from "./DfOrdersFilters";
/** Other **/

interface IProps {

}

const DfOrders: React.FC = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    const params: Record<string, string> = useParams()
    const page: number | undefined = +params.page
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const tableDataLoaded: TTableName | null = useSelector(tableDataLoadedSelector)
    const loading: boolean = useSelector(loadingSelector)
    const currentPage: number = useSelector(currentPageSelector)
    const countPages: number = useSelector(countPagesSelector)
    const filtered: Array<IDfOrder> = useSelector(filteredSelector)
    const search: string = useSelector(searchSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [openEditDfOrder, setOpenEditDfOrder] = useState<boolean>(false)
    const [idEditDfOrder, setIdEditDfOrder] = useState<string>('')
    //const [localSearchValue, setLocalSearchValue] = useState<string>('')
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // const onSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     const searchValue = (event.target as HTMLInputElement).value;
    //     setLocalSearchValue(searchValue)
    // };
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(tablesAC.setCurrentPage(page))
        dispatch(loadingDfOrderData())

        return () => {
            dispatch(tablesAC.clearReducer())
        }
        // eslint-disable-next-line
    }, [page])

    // let timer = useRef<ReturnType<typeof setTimeout>>()

    // useEffect(() => {
    //     timer.current && clearTimeout(timer.current)
    //     if (localSearchValue) {
    //         timer.current = setTimeout(() => {
    //             dispatch(tablesAC.setSearchValue(localSearchValue))
    //         },100)
    //     } else {
    //         dispatch(tablesAC.setSearchValue(''))
    //     }
    //     // eslint-disable-next-line
    // }, [dispatch, localSearchValue])
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(filterDfOrdersThunk())
        // eslint-disable-next-line
    }, [dispatch, search])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */


    const dfOrdersElements = tableDataLoaded === 'dforder' ? filtered
        .map((dfOrder: IDfOrder) => <DfOrdersRow key={dfOrder._id} data={dfOrder} setEditMode={setOpenEditDfOrder} setId={setIdEditDfOrder}/>) : []

    //console.log('tableDataLoaded', tableDataLoaded, 'dfOrdersElements', dfOrdersElements)
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='table'>
            <div className="table__menu">
                <div className='table__pagination'>
                    <Pagination
                        page={currentPage}
                        count={countPages}
                        renderItem={(item) => (
                            <PaginationItem
                                component={Link}
                                to={`/dforders/${item.page}`}
                                {...item}
                            />
                        )}
                    />
                </div>
                <DfOrdersFilters />
                {/*<div className="table__search">*/}
                {/*    <Input id={'search'}*/}
                {/*           type={'text'}*/}
                {/*           name={'search'}*/}
                {/*           value={localSearchValue}*/}
                {/*           onChange={onSearchChange}*/}
                {/*           fullWidth*/}
                {/*           placeholder={'Введите название, ИНН, телефон, E-mail, менеджера...'} />*/}
                {/*</div>*/}
                {loading ? <div className='table__loading'>
                    <CircularProgress
                        size={25}
                        thickness={7}
                    />
                </div> : null}
            </div>
            <div className="table__table">
                <div className="table__headers">
                    <div className="table__headers-item table__headers-item--date">Дата</div>
                    <div className="table__headers-item table__headers-item--df">Водитель / Экспедитор / Расход</div>
                    <div className="table__headers-item table__headers-item--deal">Сделка</div>
                    <div className="table__headers-item table__headers-item--points-from">Загрузки</div>
                    <div className="table__headers-item table__headers-item--points-to">Разгрузки</div>
                    <div className="table__headers-item table__headers-item--sum">Сумма</div>
                    <div className="table__headers-item table__headers-item--manager">Менеджер</div>
                </div>
                {tableDataLoaded === 'dforder' && dfOrdersElements}
            </div>
            {openEditDfOrder && <FormDfOrders open={openEditDfOrder} where={'table'} type={'edit'} setOpen={setOpenEditDfOrder} id={idEditDfOrder}/>}
        </div>
    )
}

export default DfOrders;