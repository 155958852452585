/** Hooks & React **/
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
/** Styles & Graphics **/
import classes from './AuthBlock.module.css'
/** Components **/
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
/** Types **/
/** Utilities **/
import { useFormik } from 'formik';
import * as yup from 'yup';
/** Thunks and ActionCreators **/
import {login} from "../../../redux/reducers/auth.reducer";
/** Selectors **/
import {isWaitingLoginSelector} from "../../../redux/selectors/auth.selectors";
/** Other **/

const AuthBlock: React.FC = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const isWaitingLogin: boolean = useSelector(isWaitingLoginSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    let validationSchema = yup.object().shape({
        email: yup.string().required('Введите E-mail'),
        password: yup.string().required('Введите пароль')
    });
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            email: '' as string,
            password: '' as string
        },
        onSubmit: (): void => {},
        validateOnMount: true,
        validationSchema: validationSchema
    });

    const formikValues = formik.values
    const formikChange = formik.handleChange
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // let onRegister = () => {
    //     dispatch(register(formikValues.email, formikValues.password))
    // }

    let onLogin = () => {
        dispatch(login(formikValues.email, formikValues.password))
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className={classes.AuthBlock}>
            <TextField
                id="email"
                label="E-mail"
                type='text'
                name='email'
                onChange={formikChange}
                value={formikValues.email}
                size="small"
                fullWidth={true}
            />
            <TextField
                id="password"
                label="Password"
                type='password'
                name='password'
                onChange={formikChange}
                value={formikValues.password}
                size='medium'
                margin='dense'
                fullWidth={true}
            />
            <div className={classes.btnBlock}>
                <ButtonGroup disableElevation variant="contained">
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={onLogin}
                        disabled={isWaitingLogin || !formikValues.password || !formikValues.email}
                    >Войти</Button>
                    {/*<Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={onRegister}
                    >Регистрация</Button>*/}
                </ButtonGroup>
            </div>
        </div>
    )
}

export default AuthBlock;