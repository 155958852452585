/** Hooks & React **/
import React, {useState} from "react";
import {useDispatch} from "react-redux";
/** Styles & Graphics **/
import CircularProgress from "@material-ui/core/CircularProgress";
/** Components **/
import TextField from "@material-ui/core/TextField";
/** Types **/
import {IDealsLoading} from "../../../../../redux/types/deals.types";
import {TPosition} from "../../../../../redux/types/auth.types";
/** Utilities **/
import {useFormik} from "formik";
import * as yup from "yup";
import classNames from "classnames";
/** Thunks and ActionCreators **/
import {editComment} from "../../../../../redux/reducers/deals.reducer";
/** Selectors **/
/** Other **/

interface IProps {
    loading: IDealsLoading
    position: TPosition
    commentManager: string
    commentHead: string
    id: string
    managerId: string
}

const Comments: React.FC<IProps> = ({commentManager, commentHead, loading, id, managerId, position}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [editModeCM, setEditModeCM] = useState(false)
    const [editModeCH, setEditModeCH] = useState(false)
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    let validationSchema = yup.object().shape({
        commentManager: yup.string().max(165, 'Максимальная длина 165 символов!'),
        commentHead: yup.string().max(165, 'Максимальная длина 165 символов!')
    });
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            commentManager: commentManager,
            commentHead: commentHead
        },
        onSubmit: () => {},
        validationSchema: validationSchema
    });
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // менеджер
    const onEditModeCMOn = () => {
        setEditModeCM(true)
    }
    const onEditModeCMOff = () => {
        setEditModeCM(false)
        if (formik.values.commentManager !== commentManager) {
            onEditComment('CM')
        } else {
            formik.setFieldValue('commentManager', commentManager)
        }
    }
    const onEditModeCMCancel = () => {
        setEditModeCM(false)
        formik.setFieldValue('commentManager', commentManager)
    }
    // руководитель
    const onEditModeCHOn = () => {
        setEditModeCH(true)
    }
    const onEditModeCHOff = () => {
        setEditModeCH(false)
        if (formik.values.commentHead !== commentHead) {
            onEditComment('CH')
        } else {
            formik.setFieldValue('commentHead', commentHead)
        }
    }
    const onEditModeCHCancel = () => {
        setEditModeCH(false)
        formik.setFieldValue('commentHead', commentHead)
    }
    const onEditComment = (type: string) => {
        let text = ''
        switch (type) {
            case 'CM':
                text = formik.values.commentManager
                break
            case 'CH':
                text = formik.values.commentHead
        }
        dispatch(editComment(id, type, text, managerId))
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='dr-comments'>
            <div className='dr-comments__manager'>
                <div className='dr-comments__header'>
                    <div className='dr-comments__header-title'>
                        Комментарий менеджера:
                    </div>
                    <div className='dr-comments__header-loading'>{loading.commentManager && <CircularProgress color="secondary" size={13}/>}</div>
                    <div className='dr-comments__header-length'>
                        <div className='dr-comments__header-length--error'>
                            {formik.errors.commentManager && 'Максимальная длина 165 символов!  '}
                        </div>
                        {editModeCM && <div className={classNames('dr-comments__header-length--value', {
                            'dr-comments__header-length--colored': formik.values.commentManager.length > 165
                        })}>
                            {formik.values.commentManager.length > 0 && formik.values.commentManager.length}
                        </div>}
                    </div>
                    {!editModeCM
                        ? (position === 'manager') &&
                        <div className='dr-comments__header-button'
                             onClick={onEditModeCMOn}>редактировать</div>
                        : <div className='dr-comments__header-button'
                               onClick={onEditModeCMOff}>сохранить</div>}
                    {editModeCM && <div className='dr-comments__header-cancel' onClick={onEditModeCMCancel}>Х</div>}
                </div>
                <div className='dr-comments__textarea'>
                    {!editModeCM
                        ? commentManager
                        : <TextField
                            id="commentManager"
                            multiline
                            fullWidth
                            size={'small'}
                            error={Boolean(formik.errors.commentManager)}
                            rowsMax={3}
                            value={formik.values.commentManager}
                            defaultValue={commentManager}
                            onChange={formik.handleChange}
                            color={'secondary'}
                        />}
                </div>
            </div>
            {((position !== 'chief' && commentHead.length > 0) || (position === 'chief')) && <div className='dr-comments__head'>
                <div className='dr-comments__header'>
                    <div className='dr-comments__header-title'>
                        Комментарий руководителя:
                    </div>
                    <div className='dr-comments__loading'>{loading.commentHead &&
                    <CircularProgress color="secondary" size={13}/>}</div>
                    <div className='dr-comments__header-length'>
                        <div className='dr-comments__header-length--error'>
                            {formik.errors.commentHead && 'Максимальная длина 165 символов!  '}
                        </div>
                        {editModeCM && <div className={classNames('dr-comments__header-length--value', {
                            'dr-comments__header-length--colored': formik.values.commentHead.length > 165
                        })}>
                            {formik.values.commentHead.length > 0 && formik.values.commentHead.length}
                        </div>}
                    </div>
                    {!editModeCH
                        ? (position === 'chief') && <div className='dr-comments__header-button'
                                                         onClick={onEditModeCHOn}>редактировать</div>
                        : <div className='dr-comments__header-button'
                               onClick={onEditModeCHOff}>сохранить</div>}
                    {editModeCH && <div className='dr-comments__header-cancel' onClick={onEditModeCHCancel}>Х</div>}
                </div>
                <div className='dr-comments__textarea'>
                    {!editModeCH
                        ? commentHead
                        : <TextField
                            id="commentHead"
                            multiline
                            fullWidth
                            autoFocus={true}
                            size={'small'}
                            error={Boolean(formik.errors.commentHead)}
                            rowsMax={3}
                            value={formik.values.commentHead}
                            defaultValue={commentHead}
                            onChange={formik.handleChange}
                            color={'secondary'}
                        />}
                </div>
            </div>}
        </div>
    )
}

export default Comments;