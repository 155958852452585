/** Hooks & React **/
import React, { useRef } from 'react';
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
/** Styles & Graphics **/
import classes from './ProfileBlock.module.css'
import './ProfileBlock.scss'
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import TimerIcon from '@material-ui/icons/Timer';
/** Components **/
import Button from "@material-ui/core/Button";
import Clock from "../../Сlock/Clock";
import Tooltip from "@material-ui/core/Tooltip";
import NotificationsMiniList from "../../Profile/Notifications/NotificationsMiniList";
import Badge from "@material-ui/core/Badge";
import RefreshTimer from "./RefreshTimer";
/** Types **/
import { IMe } from '../../../redux/types/auth.types';
/** Utilities **/
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
/** Thunks and ActionCreators **/
import { logout } from '../../../redux/reducers/auth.reducer';
import {notificationsAC} from "../../../redux/reducers/notifications.reducer";
/** Selectors **/
import {
    countNotificationsNoReadSelector,
    toggleRefreshSelector
} from "../../../redux/selectors/notifications.selectors";
import {countNoDeliveredDealsSelector, countNoDoneDealsSelector} from "../../../redux/selectors/profile.selectors";
/** Other **/
import config from '../../../config/config.json'

interface IProps {
    meData: IMe
}

const ProfileBlock: React.FC<IProps> = ({meData: {_id, position, name, email, avatar}}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    let positionRU = ''
    switch (position) {
        case 'manager':
            positionRU = 'Менеджер'
            break
        case 'chief':
            positionRU = 'Нач.отдела'
            break
        case 'secretary':
            positionRU = 'Офис.менеджер'
            break
        case 'director':
            positionRU = 'Директор'
            break
        default:
            break
    }
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const countNotificationsNoRead: number = useSelector(countNotificationsNoReadSelector)
    const countNoDoneDeals: number = useSelector(countNoDoneDealsSelector)
    const countNoDeliveredDeals: number = useSelector(countNoDeliveredDealsSelector)
    const toggleRefresh: boolean = useSelector(toggleRefreshSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [openNotificationsMini, setOpenNotificationsMini] = React.useState(false);
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    let onLogout = () => {
        dispatch(logout())
    }

    const onOpenPopupNotifications = () => {
        setOpenNotificationsMini(!openNotificationsMini);
    }

    // включение автообновления уведомлений
    const onRefresh = () => {
        dispatch(notificationsAC.setToggleRefresh(true))
    }
    // выключение автообновления уведомлений
    const offRefresh = () => {
        dispatch(notificationsAC.setToggleRefresh(false))
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** Ссылка на текущий компонент */
    const componentNode = useRef<HTMLDivElement>(null);

    /** Функция отслеживания клика вне элемента */

    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const StyledBadge = withStyles((theme) => ({
        badge: {
            right: 3,
            top: 7,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
            fontSize: '9px'
        },
    }))(Badge);
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='profile-block'>
            <div className='profile-block__position'>{positionRU}</div>
            <div className='profile-block__name'>{name}</div>
            <div className='profile-block__email'>{email}</div>
            <img alt={`avatar ${name}`} className='profile-block__avatar' src={avatar === '' ? `${config.baseUrl}/images/avatars/default.png` : `${config.baseUrl}/${avatar}`}/>
            <div className='profile-block__alert-statuses'>
                {countNoDoneDeals === 0
                    ? <Tooltip title="Все сделки отправлены!" placement="bottom-end">
                        <div className='profile-block__no-done'>
                            <div className={classes.icon}><CheckCircleOutlineIcon fontSize={"small"}/></div>
                        </div>
                    </Tooltip>
                    : <Tooltip title="Не готовые сделки" placement="bottom-end">
                        <div className={classNames('profile-block__no-done', 'profile-block__no-done-on')}>
                            <div className='profile-block__icon'><NotInterestedIcon fontSize={"small"}/></div>
                            <div className='profile-block__count'>{countNoDoneDeals}</div>
                        </div>
                    </Tooltip>}
                {countNoDeliveredDeals === 0
                    ? <Tooltip title="Всё вывезено!" placement="bottom-start">
                        <div className='profile-block__no-delivered'>
                            <div className={classes.icon}><CheckCircleOutlineIcon fontSize={"small"}/></div>
                        </div>
                    </Tooltip>
                    : <Tooltip title="Не вывезено" placement="bottom-start">
                        <div className={classNames('profile-block__no-delivered', 'profile-block__no-delivered-on')}>
                            <div className='profile-block__icon'><LocalShippingIcon fontSize={"small"}/></div>
                            <div className='profile-block__count'>{countNoDeliveredDeals}</div>
                        </div>
                    </Tooltip>}


            </div>
            <div className='profile-block__btn-exit'>
                <Button
                    fullWidth={true}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onLogout}
                >Выйти</Button>
            </div>
            <div className='profile-block__actions'>
                <Tooltip title="Настройки" placement="bottom-end">
                    <NavLink
                        className='profile-block__actions-item'
                        to='/settings'
                    >
                        <SettingsIcon/>
                    </NavLink>
                </Tooltip>
                <Tooltip title="Профиль" placement="bottom">
                    <NavLink
                        className='profile-block__actions-item'
                        to='/profile'
                    >
                        <AccountCircleIcon/>
                    </NavLink>
                </Tooltip>
                <Tooltip title="Таблицы" placement="bottom-start">
                    <NavLink
                        className='profile-block__actions-item'
                        to='/dforders/1'
                    >
                        <MultilineChartIcon/>
                    </NavLink>
                </Tooltip>
                <Tooltip title="Уведомления" placement="bottom-start">
                    <div className={classNames('profile-block__actions-item', {
                        'profile-block__notifications-active': countNotificationsNoRead !== 0
                    })}
                         onClick={onOpenPopupNotifications} ref={componentNode}>
                            {countNotificationsNoRead === 0
                                ? <NotificationsNoneIcon/>
                                : <StyledBadge
                                    badgeContent={countNotificationsNoRead}
                                    max={9}
                                    color="primary"><NotificationsActiveIcon/></StyledBadge>}
                    </div>
                </Tooltip>
                <div className='profile-block__actions-item'>
                        {toggleRefresh
                            ? <div className='profile-block__refresh-notifications' onClick={offRefresh}>
                            <RefreshTimer />
                        </div>
                            : <TimerIcon onClick={onRefresh}/>}
                </div>
                {openNotificationsMini && <div className='profile-block__popup-notifications'>
                    <NotificationsMiniList setOpenPopup={setOpenNotificationsMini}/>
                </div>}
            </div>
            <div className='profile-block__clock'><Clock/></div>
        </div>
    )
}

export default ProfileBlock;

