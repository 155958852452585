import { instance } from "./api"
import {AxiosResponse} from "axios";

export const clientsAPI = {
    getAllClients(): Promise<any>  {
        return instance.get(`clients`).then((response: AxiosResponse<any>) => response.data)
    },
    getAllClientsNames(): Promise<any> {
        return instance.get(`clients/names`).then((response: AxiosResponse<any>) => response.data)
    },
    addNewClient(name: string, inn: string, type: string, phone: string, email: string, manager: string, points: Array<string>): Promise<any> {
        return instance.post(`clients/add`, {name, inn, type, phone, email, manager, points}).then((response: AxiosResponse<any>) => response.data)
    },
    updateClient(id: string, name: string, inn: string, type: string, phone: string, email: string, points: Array<string>): Promise<any> {
        return instance.patch(`clients/${id}`, {type, name, inn, phone, email, points}).then((response: AxiosResponse<any>) => response.data)
    },
    deleteClient(id: string): Promise<any> {
        return instance.delete(`clients/${id}`).then((response: AxiosResponse<any>) => response.data)
    }
}