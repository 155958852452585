/** Hooks & React **/
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
/** Styles & Graphics **/
import './Header.scss'
import { ReactComponent as LogoIcon } from '../../assets/images/logo_demir_without_text.svg';
import { ReactComponent as LogoText } from '../../assets/images/logo_demir_text.svg';
/** Components **/
import AppBlock from "./AppBlock";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
import {loadingLastReleaseData} from "../../redux/reducers/releases.reducer";
/** Selectors **/
import {lastReleaseDataSelector, lastReleaseNumberSelector} from "../../redux/selectors/releases.selectors";
/** Other **/


const Header: React.FC = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const lastReleaseData = useSelector(lastReleaseDataSelector)
    const lastReleaseNumber: string = useSelector(lastReleaseNumberSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(loadingLastReleaseData())
        // eslint-disable-next-line
    }, [])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/

    return <header className='header'>
        <div className='header__container'>
            <div className='header__logo'>
                <div className="header__logo-icon"><LogoIcon /></div>
                <div className="header__logo-text"><LogoText /></div>
            </div>
            <AppBlock
                version={lastReleaseNumber}
                title={lastReleaseData.title}
                text={lastReleaseData.text}
                items={lastReleaseData.items}
                date={lastReleaseData.date}
            />
        </div>
    </header>
}

export default Header;