
/** Hooks & React **/
import React, {Dispatch, SetStateAction, useEffect} from 'react';
import AbcIcon from '@mui/icons-material/Abc';
import BusinessIcon from "@material-ui/icons/Business";
import CommentIcon from '@mui/icons-material/Comment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {
  loadingDfOrderItemData,
  tablesAC,
  updateDfOrderThunk
} from "../../../redux/reducers/tables.reducer";
import ErrorValidation from "../../_organisms/ErrorValidation/ErrorValidation";
import {
  savingSelector,
  serverErrorSelector,
  successSelector, tableItemDataSelector
} from "../../../redux/selectors/tables.selector";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import SimpleTextField from "../../_organisms/SimpleTextField/SimpleTextField";
import SelectField from "../../_organisms/SelectField/SelectField";
import {commonAC} from "../../../redux/reducers/common.reducer";
import {isSubmittedSelector} from "../../../redux/selectors/common.selectors";
import {IDfOrder} from "../../../redux/types/tables.types";
import {getDfListThunk} from "../../../redux/reducers/forms.reducer";
import {costsListSelector, driversListSelector, forwardersListSelector} from "../../../redux/selectors/forms.selector";
import {TSelectList} from "../../../redux/types/common.types";
import moment from "moment";
import {addDFOrderThunk} from "../../../redux/reducers/tables.reducer";
import AddTextToArray from "../../_organisms/AddTextToArray/AddTextToArray";
import Preloader from "../../../common/Preloader/Preloader";
/** Styles & Graphics **/
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  open: boolean
  where: 'table' | 'deals'
  type: 'add' | 'edit'
  df?: 'driver' | 'forwarder' | 'cost'
  setOpen: Dispatch<SetStateAction<boolean>>
  id?: string
  dealId?: string
  managerId?: string
  pointsVariants?: Array<string>
  dealDate?: string
}

const FormDfOrders: React.FC<IProps> = ({ open, managerId, dealDate, type, setOpen, id, df, dealId, pointsVariants, where }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  const onSubmit = () => {
    dispatch(commonAC.toggleIsSubmitted(true))
    if (Object.keys(formikErrors).length === 0) {
      if (type ==='edit' && dfOrderData) {
        dispatch(updateDfOrderThunk(
            dfOrderData._id,
            formikValues.dfId,
            formikValues.sum,
            dfOrderData.driver ? 'driver' : dfOrderData.forwarder ? 'forwarder' : 'cost',
            formikValues.loading,
            formikValues.unloading,
            where,
            formikValues.comment
        ))
      } else if (type ==='add' && df && dealDate) {
        dealId && managerId && df && dispatch(addDFOrderThunk(dealId, formikValues.dfId, formikValues.sum, df, formikValues.loading, formikValues.unloading, formikValues.comment, dealDate, managerId))
      }
    }
  }
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  const dfOrderData: IDfOrder | null = useSelector(tableItemDataSelector)
  const serverError: string = useSelector(serverErrorSelector)
  const savingDfOrder: boolean = useSelector(savingSelector)
  const successAdded: boolean = useSelector(successSelector)
  const isSubmitted: boolean = useSelector(isSubmittedSelector)
  const driversList: TSelectList = useSelector(driversListSelector)
  const forwardersList: TSelectList = useSelector(forwardersListSelector)
  const costsList: TSelectList = useSelector(costsListSelector)
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */

  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  let validationSchema = yup.object().shape({
    dfId: yup.string().required(`Выберите ${df === 'driver' ? 'Водителя' : df === 'forwarder' ? 'Экспедитора' : 'Расход'}`),
    sum: yup.number().required('Введите сумму'),
    comment: yup.string()
  });
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      dfId: '',
      sum: 0,
      comment: '',
      loading: [] as Array<string>,
      unloading: [] as Array<string>
    },
    onSubmit: onSubmit,
    validateOnMount: true,
    validationSchema: validationSchema
  });

  const formikValues = formik.values
  const formikErrors = formik.errors
  const formikChange = formik.handleChange
  const formikSet = formik.setFieldValue
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onClosePopup = () => {
    setOpen(false)
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  const dispatch = useDispatch()
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  useEffect(() => {
    dispatch(getDfListThunk())
    if (type === 'edit' && id) {
      dispatch(loadingDfOrderItemData(id))
    }

    return () => {
      formik.setValues({
        dfId: '',
        sum: 0,
        comment: '',
        loading: [],
        unloading: []
      }, true)
      dispatch(tablesAC.setItemData(null))
      dispatch(tablesAC.toggleSuccess(false))
      dispatch(commonAC.toggleIsSubmitted(false))
      dispatch(tablesAC.setServerError(''))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    successAdded && onClosePopup()
    // eslint-disable-next-line
  }, [successAdded])

  useEffect(() => {
    if (dfOrderData) {
      formik.setValues({
        dfId: dfOrderData.df._id,
        sum: dfOrderData.sum,
        comment: dfOrderData.comment,
        loading: dfOrderData.loading,
        unloading: dfOrderData.unloading
      }, true)
    }
    // eslint-disable-next-line
  }, [dfOrderData])
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  const orderName = dfOrderData ? (dfOrderData.deal.responsibility.location === 'korolev' ? 'ДК-' : 'ДМ-')
      + dfOrderData.deal.dealNumber + ' ' + dfOrderData.deal.client.name + ' от ' + moment(dfOrderData.deal.date).format('DD.MM.YYYY') : ''
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */
  /** ###################################### JSX ######################################################################## **/
  return open ? <Dialog onClose={onClosePopup} maxWidth={'xl'} open={open}>
    <DialogTitle>
      {type === 'edit' && dfOrderData ? 'Редактирование ордера ' + orderName : df === 'driver' ? 'Добавить водителя в сделку' : df === 'forwarder' ? 'Добавить экспедитора в сделку' : 'Добавить расход в сделку'}
    </DialogTitle>
    <DialogContent dividers>
      {type === 'edit' && !dfOrderData
          ? <div className="add-item__preloader"><Preloader /></div>
          : <div className="add-item">
        <SelectField id={'dfId'}
                     icon={<BusinessIcon/>}
                     label={(df === 'driver' || dfOrderData?.driver)
                         ? 'Водитель'
                         : (df === 'forwarder' || dfOrderData?.forwarder)
                             ? 'Экспедитор'
                             : 'Расход'}
                     onChange={formikSet}
                     value={formikValues.dfId}
                     validation
                     isSubmitted={isSubmitted}
                     errorMessage={formikErrors.dfId}
                     list={(df === 'driver' || dfOrderData?.driver)
                         ? driversList
                         : (df === 'forwarder' || dfOrderData?.forwarder)
                             ? forwardersList
                             : costsList}
        />
        <SimpleTextField id={'sum'}
                         icon={<AbcIcon/>}
                         label={'Сумма'}
                         onChange={formikChange}
                         value={formikValues.sum}
                         validation
                         isSubmitted={isSubmitted}
                         errorMessage={formikErrors.sum}
        />
        <SimpleTextField id={'comment'}
                         icon={<CommentIcon/>}
                         label={'Комментарий'}
                         onChange={formikChange}
                         value={formikValues.comment}
                         validation
                         isSubmitted={isSubmitted}
                         errorMessage={formikErrors.comment}
        />
            {df !== 'cost' && !(dfOrderData && dfOrderData.cost) && <>
              <AddTextToArray
                  id={'loading'}
                  label={'Загрузки'}
                  iconInput={<LocationOnIcon fontSize={'small'}/>}
                  inputPlaceholder={'Введите название/адрес загрузки'}
                  buttonLabel={'Добавить загрузку'}
                  onChange={formikSet}
                  value={formikValues.loading}
                  variants={['МС Капотня', 'МС Очаково', 'МС Карачарово', 'Дипос', 'Брок']}
              />
              <AddTextToArray
                  id={'unloading'}
                  label={'Разгрузки'}
                  iconInput={<LocationOnIcon fontSize={'small'}/>}
                  inputPlaceholder={'Введите название/адрес разгрузки'}
                  buttonLabel={'Добавить разгрузку'}
                  onChange={formikSet}
                  value={formikValues.unloading}
                  variants={(type === 'edit' && dfOrderData) ? dfOrderData.deal.client.points : pointsVariants}
              />
            </>}
        <ErrorValidation message={serverError}/>
      </div>}
    </DialogContent>
    <DialogActions>
      <div className="add-item__actions">
        {savingDfOrder && <CircularProgress size={20}/>}
        <Button onClick={onSubmit} variant="contained" color="primary" component="span" disabled={savingDfOrder}>
          {type === 'edit' ? 'Сохранить' : 'Добавить'}
        </Button>
        <Button onClick={onClosePopup} color="primary" disabled={savingDfOrder}>
          Закрыть
        </Button>
      </div>
    </DialogActions>
  </Dialog> : <></>
};
export default FormDfOrders;
