import produce from 'immer';
import { TDispatch } from "../redux-store";
import {IFormsState} from "../types/forms.types";
import {IDf} from "../types/deals.types";
import {ifAnyErrorThunk} from "./app.reducer";
import {dfAPI} from "../../API/df.api";
import {employeesAPI} from "../../API/employee.api";
import {IClient} from "../types/tables.types";
import {clientsAPI} from "../../API/clients.api";

// Actions

enum ActionsType {
    SET_DRIVERS_LIST = 'dealCRM/forms/SET_DRIVERS_LIST',
    SET_FORWARDERS_LIST = 'dealCRM/forms/SET_FORWARDERS_LIST',
    SET_COSTS_LIST = 'dealCRM/forms/SET_COSTS_LIST',
    SET_MANAGERS_LIST = 'dealCRM/forms/SET_MANAGERS_LIST',
    SET_CLIENTS_LIST = 'dealCRM/forms/SET_CLIENTS_LIST',
}

// InitialState

const initialState: IFormsState = {
    drivers: [],
    forwarders: [],
    costs: [],
    managers: [],
    clients: []
}

// ActionCreators

export const formsAC = {
    setDriversList: (payload: Array<IDf>) => ({ type: ActionsType.SET_DRIVERS_LIST, payload } as const),
    setForwardersList: (payload: Array<IDf>) => ({ type: ActionsType.SET_FORWARDERS_LIST, payload } as const),
    setCostsList: (payload: Array<IDf>) => ({ type: ActionsType.SET_COSTS_LIST, payload } as const),
    setManagersList: (payload: Array<any>) => ({ type: ActionsType.SET_MANAGERS_LIST, payload } as const),
    setClientsList: (payload: Array<IClient>) => ({ type: ActionsType.SET_CLIENTS_LIST, payload } as const),
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof formsAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

let formsReducer = (state = initialState, action: ActionType): IFormsState =>
    produce(state, draft => {
        switch (action.type) {
            case ActionsType.SET_DRIVERS_LIST:
                draft.drivers = action.payload
                break
            case ActionsType.SET_FORWARDERS_LIST:
                draft.forwarders = action.payload
                break
            case ActionsType.SET_COSTS_LIST:
                draft.costs = action.payload
                break
            case ActionsType.SET_MANAGERS_LIST:
                draft.managers = action.payload
                break
            case ActionsType.SET_CLIENTS_LIST:
                draft.clients = action.payload
                break
        }
    })


/** Загрузка списка водителей и Экспедиторов */
export const getDfListThunk = () => async (dispatch: TDispatch) => {
    try {
        const dfList: Array<IDf> = await dfAPI.getAllDf()
        dispatch(formsAC.setDriversList(dfList.filter(df => df.driver)))
        dispatch(formsAC.setForwardersList(dfList.filter(df => df.forwarder)))
        dispatch(formsAC.setCostsList(dfList.filter(df => df.cost)))
    } catch (e) {
        ifAnyErrorThunk(e);
    }
}

/** Загрузка списка менеджеров */
export const getManagersListThunk = () => async (dispatch: TDispatch) => {
    try {
        const managersList: Array<any> = await employeesAPI.getAllManagers()
        dispatch(formsAC.setManagersList(managersList))
    } catch (e) {
        ifAnyErrorThunk(e);
    }
}

/** Загрузка списка клиентов */
export const getClientsListThunk = () => async (dispatch: TDispatch) => {
    try {
        const clientsList: Array<any> = await clientsAPI.getAllClients()
        dispatch(formsAC.setClientsList(clientsList))
    } catch (e) {
        ifAnyErrorThunk(e);
    }
}

export default formsReducer;