/** Hooks & React **/
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useRef } from 'react';
/** Styles & Graphics **/
import '../Tables.scss'
/** Components **/
import DriversForwardersRow from "./DriversForwardersRow";
import {Button, Input} from "@mui/material";
/** Types **/
import {IDriverForwarder, TTableName} from "../../../redux/types/tables.types";
/** Utilities **/
/** Thunks and ActionCreators **/
import {
    tablesAC,
    loadingDfData,
    filterDfThunk
} from "../../../redux/reducers/tables.reducer";
/** Selectors **/
import {
    tableDataLoadedSelector,
    filteredSelector,
    searchSelector
} from "../../../redux/selectors/tables.selector";
import FormDriversForwarders from './FormDriversForwarders';
/** Other **/

interface IProps {

}

const DriversForwarders: React.FC = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const tableDataLoaded: TTableName | null = useSelector(tableDataLoadedSelector)
    const filtered: Array<IDriverForwarder> = useSelector(filteredSelector)
    const search: string = useSelector(searchSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [openAddDF, setOpenAddDF] = useState<boolean>(false)
    const [openEditDF, setOpenEditDF] = useState<boolean>(false)
    const [idEditDF, setIdEditDF] = useState<string>('')
    const [localSearchValue, setLocalSearchValue] = useState<string>('')
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onOpenAddDF = () => {
        setOpenAddDF(true)
    }

    const onSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const searchValue = (event.target as HTMLInputElement).value;
        setLocalSearchValue(searchValue)
    };
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(loadingDfData())

        return () => {
            dispatch(tablesAC.clearReducer())
        }
        // eslint-disable-next-line
    }, [])

    let timer = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        timer.current && clearTimeout(timer.current)
        if (localSearchValue) {
            timer.current = setTimeout(() => {
                dispatch(tablesAC.setSearchValue(localSearchValue))
            },100)
        } else {
            dispatch(tablesAC.setSearchValue(''))
        }
        // eslint-disable-next-line
    }, [dispatch, localSearchValue])
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(filterDfThunk())
        // eslint-disable-next-line
    }, [dispatch, search])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */


    const dfElements = tableDataLoaded === 'df' ? filtered
        .map((df: IDriverForwarder) => <DriversForwardersRow key={df._id} data={df} setEditMode={setOpenEditDF} setId={setIdEditDF}/>) : []

    //console.log('tableDataLoaded', tableDataLoaded, 'dfElements', dfElements)
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='table'>
            <div className="table__menu">
                <div className="table__add-button">
                    <Button variant={'contained'} color={'primary'} onClick={onOpenAddDF}>Добавить</Button>
                </div>
                <div className="table__search">
                    <Input id={'search'}
                           type={'text'}
                           name={'search'}
                           value={localSearchValue}
                           onChange={onSearchChange}
                           fullWidth
                           placeholder={'Введите имя, название машины...'} />
                </div>
                <div className="table__filters"> </div>
            </div>
            <div className="table__table">
                <div className="table__headers">
                    <div className="table__headers-item table__headers-item--name">Имя / Название</div>
                    <div className="table__headers-item table__headers-item--inn">Машина</div>
                    <div className="table__headers-item table__headers-item--phone">Телефон</div>
                    <div className="table__headers-item table__headers-item--position">Водитель / Экспедитор / Расход</div>
                </div>
                {tableDataLoaded === 'df' && dfElements}
            </div>
            {openAddDF && <FormDriversForwarders open={openAddDF} type={'add'} setOpen={setOpenAddDF}/>}
            {openEditDF && <FormDriversForwarders open={openEditDF} type={'edit'} setOpen={setOpenEditDF} id={idEditDF}/>}
        </div>
    )
}

export default DriversForwarders;