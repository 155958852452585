/** Hooks & React **/
import React, {useEffect} from 'react';
import { ReactNode } from 'react';
/** Styles & Graphics **/
import './SmartTextField.scss'
/** Components **/
import ErrorValidation from "../ErrorValidation/ErrorValidation";
import {TextField} from "@mui/material";
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

export interface IListForSelect {
  id: string
  name: string
}

interface IProps {
  id: string;
  label: string
  onChange: any
  value: string | number
  validation?: boolean
  isSubmitted?: boolean
  errorMessage?: string
  icon?: JSX.Element
  size?: 'small' | 'medium'
  endAdornment?: ReactNode
  list: Array<IListForSelect>
}

const SmartTextField: React.FC<IProps> = ({ id, endAdornment,
                                            icon, size = 'small', label,
                                            validation, isSubmitted,
                                            value, errorMessage, onChange, list }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredList, setFilteredList] = useState<Array<IListForSelect>>([])
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onChangeSearchValue = (e: any) => {
    const search: string = e.target.value
    setSearchValue(search)
  }

  const onSelectItem = (selectedId: string, selectedName: string) => {
    onChange(id, selectedId)
    setSearchValue(selectedName)
    setFilteredList(value ? list.filter(item => item.id === value) : [])
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  useEffect(() => {
    searchValue
        ? setFilteredList(list.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.id === value))
        : setFilteredList(value ? list.filter(item => item.id === value) : [])
    // eslint-disable-next-line
  }, [searchValue])
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  const smartElements = filteredList.map(item => <div key={item.id} onClick={() => onSelectItem(item.id, item.name)} className="smart-text-field__list-item">
    <div className="smart-text-field__list-item--text">{item.name}</div>
    {item.id === value && <div className="smart-text-field__list-item--icon"><CheckIcon fontSize={'small'}/></div>}
  </div>)
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className='smart-text-field'>
    <div className="smart-text-field__input">
      {icon && icon}
      <TextField
          id={id}
          label={label}
          type={'text'}
          name={id}
          size={size}
          fullWidth={true}
          value={searchValue}
          onChange={onChangeSearchValue}
          InputProps={{
            endAdornment: endAdornment
          }}
      />
    </div>
    <div className="smart-text-field__list">
      {smartElements}
    </div>
    {validation && isSubmitted && <ErrorValidation message={errorMessage}/>}
  </div>
};

export default SmartTextField;
