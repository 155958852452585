import {dealsAPI} from "../../API/api";
import {IProfileState} from "../types/profile.types";
import produce from "immer";
import {TDispatch, TGetState} from "../redux-store";
import {ifAnyErrorThunk} from "./app.reducer";

// Actions

enum ActionsType {
    SET_COUNT_NO_DONE_DEALS = 'dealCRM/profile/SET_COUNT_NO_DONE_DEALS',
    SET_COUNT_NO_DELIVERED_DEALS = 'dealCRM/profile/SET_COUNT_NO_DELIVERED_DEALS'
}

// InitialState

let initialState: IProfileState = {
    countNoDoneDeals: 0,
    countNoDeliveredDeals: 0,
}

// ActionCreators

export const profileAC = {
    setCountNoDoneDeals: (count: number) => ({ type: ActionsType.SET_COUNT_NO_DONE_DEALS, count } as const),
    setCountNoDeliveredDeals: (count: number) => ({type: ActionsType.SET_COUNT_NO_DELIVERED_DEALS, count} as const)
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof profileAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

const profileReducer = (state = initialState, action: ActionType): IProfileState =>
    produce(state, draft => {
        switch (action.type) {
            case ActionsType.SET_COUNT_NO_DONE_DEALS:
                draft.countNoDoneDeals = action.count
                break
            case ActionsType.SET_COUNT_NO_DELIVERED_DEALS:
                draft.countNoDeliveredDeals = action.count
                break
    }
})

// Thunks
// загрузка количества сделок со статусом "не готово"
export const loadingCountNoDoneDeals = () => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    try {
        if (id) {
            const data = await dealsAPI.getCountManagersDealsNoDone(id, token)
            dispatch(profileAC.setCountNoDoneDeals(data.count))
        }
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}

// загрузка количества сделок со статусом "не доставлено"
export const loadingCountNoDeliveredDeals = () => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    try {
        if (id) {
            const data = await dealsAPI.getCountManagersDealsNoDelivered(id, token)
            dispatch(profileAC.setCountNoDeliveredDeals(data.count))
        }
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}

export default profileReducer;