import { instance } from "./api"
import {AxiosResponse} from "axios";

export const dfOrdersAPI = {
    getAllDfOrders(page: number, dfId: string, dateFrom: string, dateTo: string, managerId: string): Promise<any>  {
        let requestString: string = `dforders?page=${page}`
        if (dfId) requestString += `&dfId=${dfId}`
        if (dateFrom) requestString += `&dateFrom=${dateFrom}`
        if (dateTo) requestString += `&dateTo=${dateTo}`
        if (managerId) requestString += `&managerId=${managerId}`
        return instance.get(requestString).then((response: AxiosResponse<any>) => response.data)
    },
    getDfOrderById(id: string): Promise<any> {
        return instance.get(`dforders/${id}`).then((response: AxiosResponse<any>) => response.data)
    },
    addNewDfOrder(dealId: string, driverId: string, sum: number, df: 'driver' | 'forwarder' | 'cost', loading: Array<string>, unloading: Array<string>, comment: string, dealDate: string, managerId: string): Promise<any> {
        return instance.post(`dforders/add`, {dealId, driverId, sum, df, loading, unloading, comment, dealDate, managerId}).then((response: AxiosResponse<any>) => response.data)
    },
    updateDfOrder(id: string, driverId: string, sum: number, df: 'driver' | 'forwarder' | 'cost', loading: Array<string>, unloading: Array<string>, comment: string): Promise<any> {
        return instance.patch(`dforders/${id}`, {driverId, sum, df, loading, unloading, comment}).then((response: AxiosResponse<any>) => response.data)
    },
    paidDfOrder(id: string): Promise<any> {
        return instance.patch(`dforders/paid/${id}`).then((response: AxiosResponse<any>) => response.data)
    },
    deleteDfOrder(dfOrderId: string, dealId: string): Promise<any> {
        return instance.delete(`dforders/${dfOrderId}?deal=${dealId}`).then((response: AxiosResponse<any>) => response.data)
    },
}