import { instance } from "./api"
import {AxiosResponse} from "axios";

export const dfAPI = {
    getAllDf(): Promise<any>  {
        return instance.get(`df`).then((response: AxiosResponse<any>) => response.data)
    },
    getAllDfNames(): Promise<any> {
        return instance.get(`df/names`).then((response: AxiosResponse<any>) => response.data)
    },
    addNewDf(name: string, phone: string, car: string, driver: boolean, forwarder: boolean, cost: boolean): Promise<any> {
        return instance.post(`df/add`, {name, phone, car, driver, forwarder, cost}).then((response: AxiosResponse<any>) => response.data)
    },
    updateDf(id: string, name: string, phone: string, car: string, driver: boolean, forwarder: boolean, cost: boolean): Promise<any> {
        return instance.patch(`df/${id}`, {name, phone, car, driver, forwarder, cost}).then((response: AxiosResponse<any>) => response.data)
    },
    deleteDf(id: string): Promise<any> {
        return instance.delete(`df/${id}`).then((response: AxiosResponse<any>) => response.data)
    }
}