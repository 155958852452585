import {IStore} from "../redux-store";
import { createSelector } from 'reselect';

export const driversListSelector = (store: IStore) => store.forms.drivers.map(df => ({id: df._id, name: df.name}))
export const forwardersListSelector = (store: IStore) => store.forms.forwarders.map(df => ({id: df._id, name: df.name}))
export const costsListSelector = (store: IStore) => store.forms.costs.map(df => ({id: df._id, name: df.name}))
export const managersListSelector = (store: IStore) => store.forms.managers.map(manager => ({id: manager._id, name: manager.name}))
export const clientsListSelector = (store: IStore) => store.forms.clients.map(client => ({id: client._id, name: client.name}))
export const allDfSelector = createSelector(
    (store: IStore) => store.forms.drivers,
    (store: IStore) => store.forms.forwarders,
    (store: IStore) => store.forms.costs,
    (drivers, forwarders, costs) => {
        const allDf = drivers.concat(forwarders, costs).map(df => ({id: df._id, name: df.name}))
        allDf.unshift({id: '', name: 'Не выбрано'})
        return allDf
    }
)