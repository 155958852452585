/** Hooks & React **/
import React from 'react';
import { ReactNode } from 'react';
/** Styles & Graphics **/
import './SimpleTextField.scss'
/** Components **/
import ErrorValidation from "../ErrorValidation/ErrorValidation";
import {TextField} from "@mui/material";
/** Types **/
/** Utilities **/
import MaskedInput from 'react-text-mask'
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  id: string;
  label: string
  onChange: any
  value: string | number
  validation?: boolean
  isSubmitted?: boolean
  errorMessage?: string
  icon?: JSX.Element
  type?: 'phone' | 'date' | 'number'
  size?: 'small' | 'medium'
  endAdornment?: ReactNode
  minDate?: string
  maxDate?: string
}

const SimpleTextField: React.FC<IProps> = ({ id, minDate, maxDate, endAdornment, icon, size = 'small', type, label, validation, isSubmitted, value, errorMessage, onChange }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className='text-field'>
    <div className="text-field__input">
      {icon && icon}
      {type === 'phone' &&
      <TextField
          id={id}
          label={label}
          type={'text'}
          name={id}
          size={size}
          fullWidth={true}
          InputProps={{
            inputComponent: PhoneMaskCustom,
            value: value,
            onChange: onChange,
          }}
      />}
      {type === 'date' &&
      <TextField
          id={id}
          label={label}
          type={type}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{inputProps: { min: minDate, max: maxDate} }}
          fullWidth={true}
          onChange={onChange}
      />}
      {!type &&
      <TextField
          id={id}
          label={label}
          type={'text'}
          name={id}
          size={size}
          fullWidth={true}
          value={value}
          onChange={onChange}
          InputProps={{
            endAdornment: endAdornment
          }}
      />}
      {type === 'number' &&
      <TextField
          id={id}
          label={label}
          type={'number'}
          name={id}
          size={size}
          fullWidth={true}
          value={value}
          onChange={onChange}
          InputProps={{
            endAdornment: endAdornment
          }}
      />}
    </div>
    {validation && isSubmitted && <ErrorValidation message={errorMessage}/>}
  </div>
};

const PhoneMaskCustom = (props: any) => {
  const { inputRef, ...other } = props;

  return (
      <MaskedInput
          {...other}
          ref={inputRef}
          mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
          //showMask={true}
      />
  );
}

export default SimpleTextField;
