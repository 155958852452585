import {IStore} from "../redux-store";

export const dealsDataSelector = (store: IStore) => store.dealsPage.dealsData
export const filteredDealsSelector = (store: IStore) => store.dealsPage.filteredDeals
/** Фильтры **/
export const showDeletedSelector = (store: IStore) => store.dealsPage.filters.showDeleted
/** Разное **/
export const dealsCurrentPageSelector = (store: IStore) => store.dealsPage.dealsCurrentPage
export const dealsPageCountSelector = (store: IStore) => store.dealsPage.dealsPageCount
export const oneDealModeSelector = (store: IStore) => store.dealsPage.oneDealMode
export const filterSelector = (store: IStore) => store.dealsPage.filter
export const driversDataSelector = (store: IStore) => store.dealsPage.driversData
export const forwardersDataSelector = (store: IStore) => store.dealsPage.forwardersData
export const isFetchingSelector = (store: IStore) => store.dealsPage.isFetching
export const loadingSelector = (store: IStore) => store.dealsPage.loading