/** Hooks & React **/
import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import Switch from "@material-ui/core/Switch";
import {IDealsLoading} from "../../../../../redux/types/deals.types";
import {useDispatch, useSelector} from "react-redux";
import {loadingSelector} from "../../../../../redux/selectors/deals.selectors";
import {toggleStatusThunk} from "../../../../../redux/reducers/deals.reducer";
import {TPosition} from "../../../../../redux/types/auth.types";
/** Styles & Graphics **/
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  dealDone: boolean
  id: string
  responsibilityId: string
  position: TPosition
}

const DealDoneSwitcher: React.FC<IProps> = ({ dealDone, id, responsibilityId, position }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  const loading: IDealsLoading = useSelector(loadingSelector)
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const toggleDealDone = () => {
        dispatch(toggleStatusThunk(id, 'dealDone', responsibilityId)) // та же санка что и для переключения статусов
      }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  const dispatch = useDispatch()
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className='deal-left__done-switcher'>
      <div className={classNames('deal-left__title-done-switcher', {
        'deal-left__light-txt': !dealDone,
        'deal-left__loading-deal-done-text': loading.dealDone,
      })}>
      {loading.dealDone
          ? <CircularProgress color="primary" size={20}/>
          : 'Готово'
      }</div>
      {(position === 'manager') && <Switch
          size={'small'}
          checked={dealDone}
          onChange={toggleDealDone}
          name="checkedA"
          color="primary"
      />}
  </div>
};
export default DealDoneSwitcher;
