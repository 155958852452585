import {releasesAPI} from "../../API/api";
import {IRelease, IReleasesState} from "../types/releases.types";
import produce from "immer";

// Actions

enum ActionsType {
    SET_RELEASES_DATA = 'dealCRM/releases/SET_RELEASES_DATA',
    SET_LAST_RELEASE_NUMBER = 'dealCRM/releases/SET_LAST_RELEASE_NUMBER',
    SET_LAST_RELEASE_DATA = 'dealCRM/releases/SET_LAST_RELEASE_DATA'
}

// InitialState

let initialState: IReleasesState = {
    lastRelease: '',
    releasesData: [],
    lastReleaseData: {} as IRelease
}

// ActionCreators

export const releasesAC = {
    setReleasesData: (releasesData: Array<IRelease>) => ({ type: ActionsType.SET_RELEASES_DATA, releasesData } as const),
    setLastReleaseNumber: (number: string) => ({type: ActionsType.SET_LAST_RELEASE_NUMBER, number} as const),
    setLastReleaseData: (lastReleaseData: Array<IRelease>) => ({type: ActionsType.SET_LAST_RELEASE_DATA, lastReleaseData} as const)
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof releasesAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

const releasesReducer = (state = initialState, action: ActionType): IReleasesState =>
    produce(state, draft => {
        switch (action.type) {
        case ActionsType.SET_RELEASES_DATA:
            draft.releasesData = action.releasesData
            break
        case ActionsType.SET_LAST_RELEASE_DATA:
            draft.lastReleaseData = action.lastReleaseData[0]
            break
        case ActionsType.SET_LAST_RELEASE_NUMBER:
            draft.lastRelease = action.number
            break
    }
})

// Thunks
export const loadingReleasesData = () => async (dispatch: any) => {
    try {
        const data = await releasesAPI.getAllReleases()
        dispatch(releasesAC.setReleasesData(data))
    } catch (e) {
        return Promise
    }
};

export const loadingLastReleaseData = () => async (dispatch: any) => {
    try {
        const release: Array<IRelease> = await releasesAPI.getOneRelease()
        dispatch(releasesAC.setLastReleaseData(release))
        dispatch(releasesAC.setLastReleaseNumber(release[0].version))
    } catch (e) {
        return Promise
    }
};
export const addNewRelease = (title: string, text: string, items: Array<string>, version: string, date: string) => async (dispatch: any) => {
    try {
        await releasesAPI.createRelease(title, text, items, version, date)
        dispatch(loadingReleasesData())
    } catch (e) {
        return Promise
    }
};
export const deleteRelease = (id: string) => async (dispatch: any) => {
    try {
        await releasesAPI.deleteRelease(id)
        dispatch(loadingReleasesData())
    } catch (e) {
        return Promise
    }
};


export default releasesReducer;