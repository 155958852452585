import React from 'react';
import PrintItem from "./PrintItem";
import ApplicationAuto from "./ApplicationAuto";

function ComponentToPrint(props) {
    const printClientInvoicesElements = props.clientInvoices.map(c => <PrintItem key={c._id}
                                                                                 fileUrl={c.fileUrl}
    />)
    const printProviderInvoicesElements = props.providerInvoices.map(c => <PrintItem key={c._id}
                                                                                     fileUrl={c.fileUrl}
    />)
    return (
        <>
            <div className="print-container" style={{margin: "0", padding: "0"}}>
                {printClientInvoicesElements}
                {printProviderInvoicesElements}
                <ApplicationAuto location={props.location}
                                 dealNumber={props.dealNumber}
                                 date={props.date}
                                 client={props.client}
                                 clientOld={props.clientOld}
                                 drivers={props.drivers}
                                 forwarders={props.forwarders}
                                 gifts={props.gifts}
                                 address={props.address}
                />
            </div>
        </>
    );
}

export default ComponentToPrint;