import {dealsAPI, driversAPI, forwardersAPI, uploadAPI} from "../../API/api";
import {addNewNotification} from "./notifications.reducer";
import {dateNormalize} from "../../common/DateNormalize/DateNormalize";
import {loadingCompaniesTableData} from "./companies.reducer";
import {
    IDeal,
    IDealsResponse,
    IDealsState,
    TDealsStatus,
    TLoading
} from "../types/deals.types";
import produce from "immer";
import {TDispatch, TGetState} from "../redux-store";
import {TPosition} from "../types/auth.types";
import {addNewSnackThunk, ifAnyErrorThunk} from "./app.reducer";
import moment from "moment";
import {commonAC} from "./common.reducer";
import {dfAPI} from "../../API/df.api";
import {IDriverForwarder} from "../types/tables.types";
import {dfOrdersAPI} from "../../API/dfOrder.api";

// Actions

enum ActionsType {
    SET_DEALS_DATA = 'dealCRM/dealspage/SET_DEALS_DATA',
    SET_FILTERED_DEALS = 'dealCRM/dealspage/SET_FILTERED_DEALS',
    /** Фильтры **/
    TOGGLE_SHOW_DELETED = 'dealCRM/dealspage/TOGGLE_SHOW_DELETED',
    /** Разное **/
    SET_DRIVERS_DATA = 'dealCRM/dealspage/SET_DRIVERS_DATA',
    SET_FORWARDERS_DATA = 'dealCRM/dealspage/SET_FORWARDERS_DATA',
    SET_FILTER = 'dealCRM/dealspage/SET_FILTER',
    SET_DEALS_PAGE_COUNT = 'dealCRM/dealspage/SET_DEALS_PAGE_COUNT',
    SET_DEALS_CURRENT_PAGE = 'dealCRM/dealspage/SET_DEALS_CURRENT_PAGE',
    TOGGLE_IS_FETCHING = 'dealCRM/dealspage/TOGGLE_IS_FETCHING',
    TOGGLE_LOADING = 'dealCRM/dealspage/TOGGLE_LOADING',
    TOGGLE_ONE_DEAL_MODE = 'dealCRM/dealspage/TOGGLE_ONE_DEAL_MODE'
}

// InitialState

let initialState: IDealsState = {
    dealsData: [],
    filteredDeals: [],
    driversData: [],
    forwardersData: [],
    filter: '',
    filters: {
        showDeleted: true
    },
    dealsPageCount: 0,
    dealsCurrentPage: 1,
    oneDealMode: false,
    isFetching: true,
    loading: {
        approved: false,
        providerPaid: false,
        delivered: false,
        clientPaid: false,
        docSigned: false,
        docCollected: false,
        dealDone: false,
        fileCI: false,
        filePI: false,
        fileDOC: false,
        delivery: false,
        commentManager: false,
        commentHead: false,
    }
}

// ActionCreators

export const dealsAC = {
    setDealsData: (data: Array<IDeal>) => ({type: ActionsType.SET_DEALS_DATA, data} as const),
    setFilteredDeals: (data: Array<IDeal>) => ({type: ActionsType.SET_FILTERED_DEALS, data} as const),
    /** Фильтры **/
    toggleShowDeleted: (bool?: boolean) => ({type: ActionsType.TOGGLE_SHOW_DELETED, bool} as const),
    /** Разное **/
    setDriversData: (data: Array<IDriverForwarder>) => ({type: ActionsType.SET_DRIVERS_DATA, data} as const),
    setForwardersData: (data: Array<IDriverForwarder>) => ({type: ActionsType.SET_FORWARDERS_DATA, data} as const),
    setFilter: (filter: string) => ({type: ActionsType.SET_FILTER, filter} as const),
    setDealsPageCount: (count: number) => ({type: ActionsType.SET_DEALS_PAGE_COUNT, count} as const),
    setDealsCurrentPage: (page: number) => ({type: ActionsType.SET_DEALS_CURRENT_PAGE, page} as const),
    toggleIsFetching: (isFetching: boolean) => ({type: ActionsType.TOGGLE_IS_FETCHING, isFetching} as const),
    toggleLoading: (value: TLoading, bool: boolean) => ({type: ActionsType.TOGGLE_LOADING, value, bool} as const),
    toggleOneDealMode: (toggle: boolean) => ({type: ActionsType.TOGGLE_ONE_DEAL_MODE, toggle} as const),
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof dealsAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>
const dealsReducer = (state = initialState, action: ActionType): IDealsState =>
    produce(state, draft => {
        switch (action.type) {
            case ActionsType.SET_DEALS_DATA:
                draft.dealsData = action.data
                break
            case ActionsType.SET_FILTERED_DEALS:
                draft.filteredDeals = action.data
                break
            /** Фильтры **/
            case ActionsType.TOGGLE_SHOW_DELETED:
                if (action.bool !== undefined) {
                    draft.filters.showDeleted = action.bool
                } else {
                    draft.filters.showDeleted = !draft.filters.showDeleted
                }
                break
            /** Разное **/
            case ActionsType.SET_DRIVERS_DATA:
                draft.driversData = action.data
                break
            case ActionsType.SET_FORWARDERS_DATA:
                draft.forwardersData = action.data
                break
            case ActionsType.SET_FILTER:
                draft.filter = action.filter
                break
            case ActionsType.SET_DEALS_PAGE_COUNT:
                draft.dealsPageCount = action.count
                break
            case ActionsType.SET_DEALS_CURRENT_PAGE:
                draft.dealsCurrentPage = action.page
                break
            case ActionsType.TOGGLE_IS_FETCHING:
                draft.isFetching = action.isFetching
                break
            case ActionsType.TOGGLE_LOADING:
                switch (action.value) {
                    case "approved":
                        draft.loading.approved = action.bool
                        break
                    case "clientPaid":
                        draft.loading.clientPaid = action.bool
                        break
                    case "commentHead":
                        draft.loading.commentHead = action.bool
                        break
                    case "commentManager":
                        draft.loading.commentManager = action.bool
                        break
                    case "dealDone":
                        draft.loading.dealDone = action.bool
                        break
                    case "delivered":
                        draft.loading.delivered = action.bool
                        break
                    case "delivery":
                        draft.loading.delivery = action.bool
                        break
                    case "docCollected":
                        draft.loading.docCollected = action.bool
                        break
                    case "docSigned":
                        draft.loading.docSigned = action.bool
                        break
                    case "fileCI":
                        draft.loading.fileCI = action.bool
                        break
                    case "fileDOC":
                        draft.loading.fileDOC = action.bool
                        break
                    case "filePI":
                        draft.loading.filePI = action.bool
                        break
                    case "providerPaid":
                        draft.loading.providerPaid = action.bool
                        break
                }
                break
            case ActionsType.TOGGLE_ONE_DEAL_MODE:
                draft.oneDealMode = action.toggle
                break
    }
})



// Thunks
/** Заблокировать сделку **/
export const lockDealThunk = (id: string) => async (dispatch: TDispatch, getState: TGetState) => {
   try {
       dispatch(commonAC.toggleLoading('lockingDeal', true))
       const deal: IDeal = await dealsAPI.lockDeal(id)
       await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
       dispatch(addNewSnackThunk('info',
           `Сделка № ${deal.dealNumber} ${deal.client ? deal.client.name : deal.clientOld} ${deal.locked ? 'заблокирована' : 'разблокирована'}`, true))
       dispatch(commonAC.toggleLoading('lockingDeal', false))
   } catch (e: any) {
       dispatch(ifAnyErrorThunk(e))
       dispatch(commonAC.toggleLoading('lockingDeal', false))
   }
}

/** Пометить сделку на удаление **/
export const markDealDeletedThunk = (id: string) => async (dispatch: TDispatch, getState: TGetState) => {
   try {
       dispatch(commonAC.toggleLoading('markingDeleted', true))
       const deal: IDeal = await dealsAPI.markDealDeleted(id)
       await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
       dispatch(addNewSnackThunk('info',
           `Сделка № ${deal.dealNumber} ${deal.client ? deal.client.name : deal.clientOld} ${deal.deleted ? 'помечена на удаление' : 'снята с удаления'}`, true))
       dispatch(commonAC.toggleLoading('markingDeleted', false))
   } catch (e: any) {
       dispatch(ifAnyErrorThunk(e))
       dispatch(commonAC.toggleLoading('markingDeleted', false))
   }
}

/** Изменение даты сделки **/
export const changeDealDateThunk = (id: string, date: string) => async (dispatch: TDispatch, getState: TGetState) => {
   try {
       const deal: IDeal = await dealsAPI.changeDealDate(id, date)
       await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
       dispatch(addNewSnackThunk('info', `Дата сделки № ${deal.dealNumber} ${deal.client ? deal.client.name : deal.clientOld} изменена на ${moment(date).format('LL')}`, true))
   } catch (e: any) {
       dispatch(ifAnyErrorThunk(e))
   }
}

// загрузка страницы сделок
export const loadingDealsPage = (id: string | null, filter: string | null = null, page: number = 1) => async (dispatch: TDispatch, getState: TGetState) => {
    if (id) {
        await dispatch(loadingOneDeal(id))
    } else {
        await dispatch(loadingDeals(filter, page))
    }
}

// загрузка одной сделки
export const loadingOneDeal = (id: string) => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        dispatch(dealsAC.toggleOneDealMode(true))
        await dispatch(loadingDFData())
        await dispatch(loadingCompaniesTableData())
        if (getState().app.initialized) {
            let token = getState().authBlock.token
            dispatch(dealsAC.toggleIsFetching(true))
            let oneDeal = await dealsAPI.getOneDeal(id, token)
            dispatch(dealsAC.toggleIsFetching(false))
            dispatch(dealsAC.setDealsData(oneDeal))
            await dispatch(offLoading())
        }
    } catch (e) {
        alert(e)
    }
}
// загрузка списка сделок
export const loadingDeals = (filter: string | null, page: number) => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        dispatch(dealsAC.toggleOneDealMode(false))
        let token: string = getState().authBlock.token
        let id: string | undefined = getState().authBlock.me?._id
        let position: TPosition | undefined = getState().authBlock.me?.position

        if (getState().app.initialized && id && position) {
            switch (position) {
                case 'manager':
                    if (filter) {
                        await dispatch(loadingDealsTableData('manager', id, token, page, filter))
                    } else {
                        await dispatch(loadingDealsTableData('manager', id, token, page))
                    }
                    break
                case 'secretary':
                    await dispatch(loadingDealsTableData('secretary', id, token, page))
                    break
                case 'chief':
                    await dispatch(loadingDealsTableData('chief', id, token, page))
                    break
                case 'director':
                    if (filter) {
                        await dispatch(loadingDealsTableData('director', id, token, page, filter))
                    } else {
                        await dispatch(loadingDealsTableData('director', id, token, page))
                    }
                    break
                default:
                    break
            }
            await dispatch(loadingDFData())
            await dispatch(loadingCompaniesTableData())
            await dispatch(filterDealsThunk())
        }
    } catch (e: any) {

    }
}

/** Фильтрация сделок **/
export const filterDealsThunk = () => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        let deals: Array<IDeal> = getState().dealsPage.dealsData
        const showDeleted: boolean = getState().dealsPage.filters.showDeleted
        if (!showDeleted) {
            deals = deals.filter(deal => !deal.deleted)
        }
        dispatch(dealsAC.setFilteredDeals(deals))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// выключение всех загрузок
export const offLoading = () => async (dispatch: TDispatch) => {
    dispatch(dealsAC.toggleLoading('approved', false))
    dispatch(dealsAC.toggleLoading('providerPaid', false))
    dispatch(dealsAC.toggleLoading('delivered', false))
    dispatch(dealsAC.toggleLoading('clientPaid', false))
    dispatch(dealsAC.toggleLoading('docSigned', false))
    dispatch(dealsAC.toggleLoading('docCollected', false))
    dispatch(dealsAC.toggleLoading('dealDone', false))
    dispatch(dealsAC.toggleLoading('fileCI', false))
    dispatch(dealsAC.toggleLoading('filePI', false))
    dispatch(dealsAC.toggleLoading('fileDOC', false))
    dispatch(dealsAC.toggleLoading('delivery', false))
    dispatch(dealsAC.toggleLoading('commentManager', false))
    dispatch(dealsAC.toggleLoading('commentHead', false))
    dispatch(dealsAC.toggleIsFetching(false))

}
// загрузка массива сделок
export const loadingDealsTableData = (position: TPosition, id: string, token: string, page: number, filter: string = '') => async (dispatch: TDispatch) => {
    try {
        switch (position) {
            case 'manager':
                if (filter) {
                    dispatch(dealsAC.toggleIsFetching(true))
                    let filteredManagersDeals = await dealsAPI.filterDealsByStatusManagers(id, filter, token, page)
                    await dispatch(offLoading())
                    dispatch(dealsAC.setDealsData(filteredManagersDeals.result))
                    dispatch(dealsAC.setDealsPageCount(filteredManagersDeals.pages))
                    dispatch(dealsAC.setDealsCurrentPage(filteredManagersDeals.page))
                } else {
                    dispatch(dealsAC.toggleIsFetching(true))
                    let managerDeals: IDealsResponse = await dealsAPI.getAllManagerDeals(id, token, page)
                    await dispatch(offLoading())
                    dispatch(dealsAC.setDealsData(managerDeals.result))
                    dispatch(dealsAC.setDealsPageCount(managerDeals.pages))
                    dispatch(dealsAC.setDealsCurrentPage(managerDeals.page))
                }
                break
            case 'secretary':
                dispatch(dealsAC.toggleIsFetching(true))
                let dealsDone = await dealsAPI.getAllDealsDone(token, page)
                await dispatch(offLoading())
                dispatch(dealsAC.setDealsData(dealsDone.result))
                dispatch(dealsAC.setDealsPageCount(dealsDone.pages))
                dispatch(dealsAC.setDealsCurrentPage(dealsDone.page))
                break
            case 'chief':
                dispatch(dealsAC.toggleIsFetching(true))
                let allDealsForChief = await dealsAPI.getAllDeals(token, page)
                await dispatch(offLoading())
                dispatch(dealsAC.setDealsData(allDealsForChief.result))
                dispatch(dealsAC.setDealsPageCount(allDealsForChief.pages))
                dispatch(dealsAC.setDealsCurrentPage(allDealsForChief.page))
                break
            case 'director':
                if (filter) {
                    dispatch(dealsAC.toggleIsFetching(true))
                    let filteredDealsForDirector = await dealsAPI.filterDealsByStatusAllManagers(filter, token, page)
                    await dispatch(offLoading())
                    dispatch(dealsAC.setDealsData(filteredDealsForDirector.result))
                    dispatch(dealsAC.setDealsPageCount(filteredDealsForDirector.pages))
                    dispatch(dealsAC.setDealsCurrentPage(filteredDealsForDirector.page))
                } else {
                    dispatch(dealsAC.toggleIsFetching(true))
                    let allDealsForDirector = await dealsAPI.getAllDeals(token, page)
                    await dispatch(offLoading())
                    dispatch(dealsAC.setDealsData(allDealsForDirector.result))
                    dispatch(dealsAC.setDealsPageCount(allDealsForDirector.pages))
                    dispatch(dealsAC.setDealsCurrentPage(allDealsForDirector.page))
                }
                break
            default:
                break
        }
    } catch (e: any) {
        alert(e.response.data.message)
    }
}

// загрузка списка водителей и экспедиторов для выбора при добавлении в сделку
export const loadingDFData = () => async (dispatch: TDispatch) => {
    try {
        const data: Array<IDriverForwarder> = await dfAPI.getAllDf()
        const drivers: Array<IDriverForwarder> = data.filter(df => df.driver)
        const forwarders: Array<IDriverForwarder> = data.filter(df => df.forwarder)
        dispatch(dealsAC.setDriversData(drivers))
        dispatch(dealsAC.setForwardersData(forwarders))
    } catch (e: any) {
        alert(e.response.data.message)
    }
}

// загрузка списка водителей для выбора при добавлении в сделку
export const loadingDriversTableData = () => async (dispatch: TDispatch) => {
    try {
        const tableData = await driversAPI.getAllDrivers()
        dispatch(dealsAC.setDriversData(tableData))
    } catch (e: any) {
        alert(e.response.data.message)
    }
}

// загрузка списка экспедиторов для выбора при добавлении в сделку
export const loadingForwardersTableData = () => async (dispatch: TDispatch) => {
    try {
        const tableData = await forwardersAPI.getAllForwarders()
        dispatch(dealsAC.setForwardersData(tableData))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// добавление сделки
export const addDealThunk = (date: string, client: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const id: string | undefined = getState().authBlock.me?._id
    const headId: string | undefined = getState().authBlock.me?.head._id
    try {
        if (id && headId) {
            const data: IDeal = await dealsAPI.addNewDeal(date, client, id)
            await dispatch(addNewNotification(headId, data._id, `добавил новую сделку Клиент ${client} на ${dateNormalize(date)}`))
            await dispatch(addNewSnackThunk('info', `Добавлена новая сделка #${data.dealNumber} на ${dateNormalize(date)}`, true))
            await dispatch(loadingDealsPage(null))
        }
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// добавление файлов в центральный блок сделки
export const saveFile = (file: any, id: string, company: string, sum: number, type: string, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    let typeText = ''
    switch (type) {
        case 'CI':
            dispatch(dealsAC.toggleLoading('fileCI', true))
            typeText = 'Счет клиента'
            break
        case 'PI':
            dispatch(dealsAC.toggleLoading('filePI', true))
            typeText = 'Счет поставщика'
            break
        case 'DOC':
            dispatch(dealsAC.toggleLoading('fileDOC', true))
            typeText = 'Документ'
            break
        default:
            break
    }
    try {
        await uploadAPI.saveFile(file, id, company, sum, type)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        dispatch(addNewNotification(managerId, id, `добавил новый файл ${typeText} компания ${company} на сумму ${sum}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// удаление файлов из центрального блока сделки
export const deleteFile = (id: string, file: any, type: string, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    let typeText = ''
    switch (type) {
        case 'CI':
            dispatch(dealsAC.toggleLoading('fileCI', true))
            typeText = 'Счет клиента'
            break
        case 'PI':
            dispatch(dealsAC.toggleLoading('filePI', true))
            typeText = 'Счет поставщика'
            break
        case 'DOC':
            dispatch(dealsAC.toggleLoading('fileDOC', true))
            typeText = 'Документ'
            break
        default:
            break
    }
    try {
        await uploadAPI.deleteFile(id, file, type)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        dispatch(addNewNotification(managerId, id, `удалил файл ${typeText}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// удаление водителя или экспедитора из сделки - новый формат
export const deleteDFFromDeal = (dealId: string, dfOrderId: string, name: string, sum: number, df: 'driver' | 'forwarder' | 'cost') => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    const managerId: string | undefined = getState().authBlock.me?._id
    try {
        dispatch(dealsAC.toggleLoading('delivery', true))
        await dfOrdersAPI.deleteDfOrder(dfOrderId, dealId)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(dealId))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        await dispatch(addNewNotification(managerId, dealId, `удалил водителя ${name} сумма ${sum}`))
        headId && await dispatch(addNewNotification(headId, dealId, `удалил водителя ${name} сумма ${sum}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// добавление расхода в сделку
export const addGift = (id: string, giftName: string, comment: string, sum: number, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    try {
        dispatch(dealsAC.toggleLoading('delivery', true))
        await dealsAPI.addGiftToDeal(id, giftName, comment, sum)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        await dispatch(addNewNotification(managerId, id, `добавил расход ${giftName} на сумму ${sum}`))
        headId && await dispatch(addNewNotification(headId, id, `добавил расход ${giftName} на сумму ${sum}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// удаление водителя из сделки - старый формат
export const deleteDriverFromDealOldFormat = (id: string, name: string, sum: number, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    try {
        dispatch(dealsAC.toggleLoading('delivery', true))
        await dealsAPI.deleteDriverFromDealOldFormat(id, name, sum)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        await dispatch(addNewNotification(managerId, id, `удалил водителя ${name} сумма ${sum}`))
        headId && await dispatch(addNewNotification(headId, id, `удалил водителя ${name} сумма ${sum}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// удаление экспедитора из сделки - старый формат
export const deleteForwarderFromDealOldFormat = (id: string, name: string, sum: number, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    try {
        dispatch(dealsAC.toggleLoading('delivery', true))
        await dealsAPI.deleteForwarderFromDealOldFormat(id, name, sum)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        await dispatch(addNewNotification(managerId, id, `удалил экспедитора ${name} сумма ${sum}`))
        headId && await dispatch(addNewNotification(headId, id, `удалил экспедитора ${name} сумма ${sum}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// удаление расхода из сделки
export const deleteGiftFromDeal = (id: string, name: string, sum: number, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    try {
        dispatch(dealsAC.toggleLoading('delivery', true))
        await dealsAPI.deleteGiftFromDeal(id, name, sum)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        await dispatch(addNewNotification(managerId, id, `удалил расход ${name} на сумму ${sum}`))
        headId && await dispatch(addNewNotification(headId, id, `удалил расход ${name} на сумму ${sum}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// редактировать комментарий (менеджер или руководитель)
export const editComment = (id: string, type: string, text: string, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    try {
        if (type === 'CM') {
            dispatch(dealsAC.toggleLoading('commentManager', true))
        } else if (type === 'CH') {
            dispatch(dealsAC.toggleLoading('commentHead', true))
        }
        await dealsAPI.editComment(id, type, text)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        await dispatch(addNewNotification(managerId, id, `изменил комментарий на: ${text}`))
        headId && await dispatch(addNewNotification(headId, id, `изменил комментарий на: ${text}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}
// редактировать адрес доставки
export const editAddress = (id: string, text: string, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    try {
        dispatch(dealsAC.toggleLoading('commentManager', true))
        await dealsAPI.editAddress(id, text)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
        await dispatch(addNewNotification(managerId, id, `изменил адрес доставки на: ${text}`))
        headId && await dispatch(addNewNotification(headId, id, `изменил адрес доставки на: ${text}`))
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}
// обновить дельту без доков
export const updateDeltaWD = (id: string, deltaWD: number) => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        await dealsAPI.updateDeltaWD(id, deltaWD)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}
// обновить дельту
export const updateDelta = (id: string, delta: number) => async (dispatch: TDispatch, getState: TGetState) => {
    try {
        await dealsAPI.updateDelta(id, delta)
        if (getState().dealsPage.oneDealMode) {
            await dispatch(loadingDealsPage(id))
        } else {
            await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
        }
    } catch (e: any) {
        dispatch(ifAnyErrorThunk(e))
    }
}

// переключатель статусов
export const toggleStatusThunk = (id: string, status: TDealsStatus, managerId: string) => async (dispatch: TDispatch, getState: TGetState) => {
    const headId: string | undefined = getState().authBlock.me?.head._id
    const employeeId: string | undefined = getState().authBlock.me?._id
    const oneDealMode = getState().dealsPage.oneDealMode
    switch (status) {
        case 'approved':
            try {
                dispatch(dealsAC.toggleLoading('approved', true))
                const dealStatusValue = await dealsAPI.toggleStatus(id, status)
                if (oneDealMode) {
                    await dispatch(loadingDealsPage(id))
                } else {
                    await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
                }
                const message = `изменил статус на новый: "сделка ${!dealStatusValue.dealStatus.approved ? `одобрена"` : `не одобрена"`}`
                await dispatch(addNewNotification(managerId, id, message))
                employeeId && await dispatch(addNewNotification(employeeId, id, message))
                await dispatch(addNewNotification('5f900bfb1424d612bf673382', id, message))
            } catch (e: any) {
                dispatch(ifAnyErrorThunk(e))
            }
            break
        case 'providerPaid':
            try {
                dispatch(dealsAC.toggleLoading('providerPaid', true))
                const dealStatusValue = await dealsAPI.toggleStatus(id, status)
                if (oneDealMode) {
                    await dispatch(loadingDealsPage(id))
                } else {
                    await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
                }
                const message = `изменил статус на новый: "счета поставщиков ${!dealStatusValue.dealStatus.providerPaid ? `оплатили"` : `не оплатили"`}`
                headId && await dispatch(addNewNotification(headId, id, message))
                employeeId && await dispatch(addNewNotification(employeeId, id, message))
                await dispatch(addNewNotification(managerId, id, message))
            } catch (e: any) {
                dispatch(ifAnyErrorThunk(e))
            }
            break
        case 'delivered':
            try {
                dispatch(dealsAC.toggleLoading('delivered', true))
                const dealStatusValue = await dealsAPI.toggleStatus(id, status)
                if (oneDealMode) {
                    await dispatch(loadingDealsPage(id))
                } else {
                    await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
                }
                const message = `изменил статус на новый: "сделку ${!dealStatusValue.dealStatus.delivered ? `вывезли"` : `не вывезли"`}`
                if (employeeId === managerId) {
                    employeeId && await dispatch(addNewNotification(employeeId, id, message))
                    await dispatch(addNewNotification('5f900bfb1424d612bf673382', id, message))
                    headId && await dispatch(addNewNotification(headId, id, message))
                } else if (employeeId === '5f900bfb1424d612bf673382') {
                    employeeId && await dispatch(addNewNotification(employeeId, id, message))
                    await dispatch(addNewNotification(managerId, id, message))
                    headId && await dispatch(addNewNotification(headId, id, message))
                } else {
                    await dispatch(addNewNotification(managerId, id, message))
                    await dispatch(addNewNotification('5f900bfb1424d612bf673382', id, message))
                }
            } catch (e: any) {
                dispatch(ifAnyErrorThunk(e))
            }
            break
        case 'clientPaid':
            try {
                dispatch(dealsAC.toggleLoading('clientPaid', true))
                const dealStatusValue = await dealsAPI.toggleStatus(id, status)
                if (oneDealMode) {
                    await dispatch(loadingDealsPage(id))
                } else {
                    await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
                }
                const message = `изменил статус на новый: "клиент ${!dealStatusValue.dealStatus.clientPaid ? `оплатил"` : `не оплатил"`}`
                headId && await dispatch(addNewNotification(headId, id, message))
                employeeId && await dispatch(addNewNotification(employeeId, id, message))
                await dispatch(addNewNotification('5f900bfb1424d612bf673382', id, message))
            } catch (e: any) {
                dispatch(ifAnyErrorThunk(e))
            }
            break
        case 'docSigned':
            try {
                dispatch(dealsAC.toggleLoading('docSigned', true))
                const dealStatusValue = await dealsAPI.toggleStatus(id, status)
                if (oneDealMode) {
                    await dispatch(loadingDealsPage(id))
                } else {
                    await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
                }
                const message = `изменил статус на новый: "документы ${!dealStatusValue.dealStatus.docSigned ? `подписаны"` : `не подписаны"`}`
                headId && await dispatch(addNewNotification(headId, id, message))
                employeeId && await dispatch(addNewNotification(employeeId, id, message))
                await dispatch(addNewNotification('5f900bfb1424d612bf673382', id, message))
            } catch (e: any) {
                alert(e.response.data.message)
            }
            break
        case 'docCollected':
            try {
                dispatch(dealsAC.toggleLoading('docCollected', true))
                const dealStatusValue = await dealsAPI.toggleStatus(id, status)
                if (oneDealMode) {
                    await dispatch(loadingDealsPage(id))
                } else {
                    await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
                }
                const message = `изменил статус на новый: "документы ${!dealStatusValue.dealStatus.docCollected ? `собраны"` : `не собраны"`}`
                headId && await dispatch(addNewNotification(headId, id, message))
                employeeId && await dispatch(addNewNotification(employeeId, id, message))
                await dispatch(addNewNotification('5f900bfb1424d612bf673382', id, message))
            } catch (e: any) {
                dispatch(ifAnyErrorThunk(e))
            }
            break
        case 'dealDone':
            try {
                dispatch(dealsAC.toggleLoading('dealDone', true))
                const dealStatusValue = await dealsAPI.toggleStatus(id, status)
                if (oneDealMode) {
                    await dispatch(loadingDealsPage(id))
                } else {
                    await dispatch(loadingDealsPage(null, null, getState().dealsPage.dealsCurrentPage))
                }
                const message = `изменил статус готовности сделки на ${!dealStatusValue.dealStatus.dealDone ? 'Готово' : 'Не готово'}`
                headId && await dispatch(addNewNotification(headId, id, message))
                await dispatch(addNewNotification('5f900bfb1424d612bf673382', id, message))
            } catch (e: any) {
                alert(e.response.data.message)
            }
            break
        default:
            break
    }


}

export default dealsReducer;