/** Hooks & React **/
import React, {useRef, useState} from 'react';
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import DeleteIcon from '@mui/icons-material/Delete';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DealDoneSwitcher from "./DealDoneSwitcher";
import DialogTitle from "@material-ui/core/DialogTitle";
import classes from "../deal.module.scss";
import {dateNormalize} from "../../../../../common/DateNormalize/DateNormalize";
import ReactToPrint from "react-to-print";
import DialogContent from "@material-ui/core/DialogContent";
import ComponentToPrint from "../Print/ComponentToPrint";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {TPosition} from "../../../../../redux/types/auth.types";
import {IClient} from "../../../../../redux/types/tables.types";
import {
  IDealClientInvoice,
  IDealDriver,
  IDealForwarder,
  IDealGift,
  IDealProviderInvoice
} from "../../../../../redux/types/deals.types";
import {lockDealThunk, markDealDeletedThunk} from "../../../../../redux/reducers/deals.reducer";
import {useDispatch, useSelector} from "react-redux";
import {lockingDealSelector, markingDeletedSelector} from "../../../../../redux/selectors/common.selectors";
import {CircularProgress} from "@mui/material";
/** Styles & Graphics **/
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  id: string
  responsibilityId: string
  position: TPosition
  dealDone: boolean
  dealNumber: number
  client: IClient
  drivers: Array<IDealDriver>
  forwarders: Array<IDealForwarder>
  gifts: Array<IDealGift>
  clientInvoices: Array<IDealClientInvoice>
  providerInvoices: Array<IDealProviderInvoice>
  address: string
  date: string
  location: string
  deleted: boolean
  locked: boolean
}

const DealControl: React.FC<IProps> = ({ position, dealDone, responsibilityId,
                                         id, drivers, forwarders, gifts,
                                         clientInvoices , providerInvoices, client,
                                         dealNumber, address, date, location, deleted, locked}) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  const markingDeleted: boolean = useSelector(markingDeletedSelector)
  const lockingDeal: boolean = useSelector(lockingDealSelector)
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  const [openPrint, setOpenPrint] = useState(false)
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onOpenPrintDialog = () => {
    setOpenPrint(true)
  }
  const onClosePrintDialog = () => {
    setOpenPrint(false)
  }

  const onToggleDelete = () => {
    dispatch(markDealDeletedThunk(id))
  }

  const onToggleLock = () => {
    dispatch(lockDealThunk(id))
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  const componentRef = useRef(null);
  const dispatch = useDispatch()
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <>
    <div className='deal-left__control'>
      <div className="deal-left__control--print">
        <Button onClick={onOpenPrintDialog}><PrintIcon /></Button>
      </div>
      {(position === 'manager' || position === 'chief' || position === 'director') &&
      <DealDoneSwitcher
          dealDone={dealDone}
          id={id}
          responsibilityId={responsibilityId}
          position={position} />}
      {position === 'director' && <div className="deal-left__control--lock">
        <Button onClick={onToggleLock} variant={deleted ? 'outlined' : 'text'} type={'submit'}>
          {lockingDeal
              ? <CircularProgress size={24}/>
              : locked
                  ? <LockIcon style={{color: '#d32f2f'}}/>
                  : <LockOpenIcon/>
          }
        </Button>
      </div>}
      {!locked && <div className="deal-left__control--delete">
        <Button onClick={onToggleDelete} variant={deleted ? 'outlined' : 'text'} type={'submit'}>
          {markingDeleted
              ? <CircularProgress size={24}/>
              : deleted
                  ? <AutoDeleteIcon style={{color: '#d32f2f'}}/>
                  : <DeleteIcon/>
          }
        </Button>
      </div>}
    </div>
    <Dialog
        onClose={onClosePrintDialog}
        open={openPrint}
        maxWidth={"xl"}
    >
      <DialogTitle id="customized-dialog-title">
        <div className={classes.headerDialogPrint}>
          <div className={classes.titleDialogPrint}>Сделка {location === 'korolev' ? 'ДК-' : 'ДМ-'}{dealNumber} от {dateNormalize(date)}</div>
          <div className={classes.buttonPrint}><ReactToPrint
              trigger={() => <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PrintIcon />}
              >
                Печатать
              </Button>}
              // @ts-ignore
              content={() => componentRef.current}
          /></div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div ref={componentRef}>
          <ComponentToPrint
              location={location}
              dealNumber={dealNumber}
              date={date}
              client={client}
              drivers={drivers}
              forwarders={forwarders}
              gifts={gifts}
              clientInvoices={clientInvoices}
              providerInvoices={providerInvoices}
              address={address}

          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosePrintDialog} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  </>
};
export default DealControl;
