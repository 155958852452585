/** Hooks & React **/
import React from 'react';
/** Styles & Graphics **/
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/
import AuthBlock from "./AuthBlock/AuthBlock";
import Alert from "@material-ui/lab/Alert";
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import classes from './LoginPage.module.css'
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    isAuthSelector,
    isWaitingLoginSelector,
    textResponseMessageSelector
} from "../../redux/selectors/auth.selectors";

const LoginPage: React.FC = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const isAuth: boolean = useSelector(isAuthSelector)
    const isWaitingLogin: boolean = useSelector(isWaitingLoginSelector)
    const textResponseMessage: string | null = useSelector(textResponseMessageSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    if (isAuth) {
        return <Redirect to={'/dealspage'}/>
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/

    return <div className={classes.authWrapper}>
        <div className={classes.authBlock}>
            <AuthBlock />
        </div>
        {textResponseMessage !== null &&
        <div className={classes.errorBlock}><Alert severity="error">{textResponseMessage}</Alert></div>}
        {isWaitingLogin && <div className={classes.isWaitingLogin}><CircularProgress color="primary" size={30}/></div>}
    </div>
}

export default compose(withRouter)(LoginPage)