/** Hooks & React **/
import React from 'react';
/** Styles & Graphics **/
import './AddTextToArray.scss'
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
/** Components **/
import ErrorValidation from "../ErrorValidation/ErrorValidation";
import {Button, TextField} from "@mui/material";
/** Types **/
/** Utilities **/
import {useFormik} from "formik";
import * as yup from "yup";
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  id: string;
  label: string
  iconInput?: JSX.Element
  inputPlaceholder: string
  buttonLabel: string
  onChange: any
  value: Array<string>
  validation?: boolean
  isSubmitted?: boolean
  errorMessage?: string
  variants?: Array<string>
  size?: 'small' | 'medium'
}

const AddTextToArray: React.FC<IProps> = ({ id, label, variants, iconInput = <LabelImportantIcon />, inputPlaceholder, size = 'small', buttonLabel, validation, isSubmitted, value, errorMessage, onChange }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  const onSubmit = () => {
    if (Object.keys(formikErrors).length === 0) {
      //setArray(prev => [...prev, formikValues.text])
      onChange(id, [ ...value, formikValues.text])
      formikSet('text', '')
    }
  }
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  //const [array, setArray] = useState<Array<string>>([])
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  let validationSchema = yup.object().shape({
    text: yup.string().required(`Нужно ввести какой-нибудь текст, чтобы добавить`),
  });
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      text: ''
    },
    onSubmit: onSubmit,
    validateOnMount: true,
    validationSchema: validationSchema
  });

  const formikValues = formik.values
  const formikErrors = formik.errors
  const formikChange = formik.handleChange
  const formikSet = formik.setFieldValue
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onDeleteItem = (index: number) => {
    //setArray(prev => [...prev.filter((item, i) => i !== index)])
    onChange(id, [ ...value.filter((item, i) => i !== index)])
  }

  const onSelectVariant = (variant: string) => {
    //setArray(prev => [...prev, variant])
    onChange(id, [ ...value, variant])
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  const arrayElements = value.map((item, index) =>
      <li key={index + item} className='add-text-to-array__list-item'>
        <div className="add-text-to-array__list-item--number">{index + 1}.</div>
        <div className="add-text-to-array__list-item--text">{item}</div>
        <div className="add-text-to-array__list-item--delete">
          <Button size={'small'} onClick={() => onDeleteItem(index)}><DeleteOutlineIcon fontSize={'small'}/></Button>
        </div>
      </li>)

  const variantsElements = variants ? variants.map(item => <Button size={'small'} variant={'outlined'} onClick={() => onSelectVariant(item)}>{item}</Button> ) : []
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className='add-text-to-array'>
    <div className="add-text-to-array__title">
      {label}
    </div>
    <div className="add-text-to-array__input">
      <TextField
          id='text'
          //label={label}
          placeholder={inputPlaceholder}
          type={'text'}
          name='text'
          size={size}
          fullWidth={true}
          value={formikValues.text}
          onChange={formikChange}
          InputProps={{
            startAdornment: iconInput
          }}
      />
      <Button className='add-text-to-array__input-btn' startIcon={<AddIcon />} onClick={onSubmit}>{buttonLabel}</Button>
    </div>
    {variants && <div className="add-text-to-array__variants">
      {variantsElements}
    </div>}
    <ol className="add-text-to-array__list">
      {arrayElements}
    </ol>
    {validation && isSubmitted && <ErrorValidation message={errorMessage}/>}
  </div>
};

export default AddTextToArray;
