/** Hooks & React **/
import React, {useState} from 'react';
import {IDeal} from "../../../../redux/types/deals.types";
import config from "../../../../config/config.json";
/** Styles & Graphics **/
import './DealMini.scss'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LockIcon from '@mui/icons-material/Lock';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import NumbersIcon from '@mui/icons-material/Numbers';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PaidIcon from '@mui/icons-material/Paid';
import CommentIcon from '@mui/icons-material/Comment';
import classNames from 'classnames';
import Deal from '../Deal/Deal';
import {Tooltip} from "@mui/material";
import {TPosition} from "../../../../redux/types/auth.types";
import moment from 'moment';
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  dealData: IDeal
  position: TPosition
  location: string
}

const DealMini: React.FC<IProps> = ({dealData, position, location}) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
      // вычисляем константы для Сделки:
      // Сумма счетов клиента
  const sumClientInvoices = dealData.clientInvoices.reduce((s, i) => s = s + Number(i.sum), 0)
  // Сумма счетов поставщиков
  const sumProviderInvoices = dealData.providerInvoices.reduce((s, i) => s = s + Number(i.sum), 0)
  // Сумма документов
  const sumAllDocs = dealData.allDocs.reduce((s, i) => s = s + Number(i.sum), 0)
  // -----------------------------------------------------------------------------------------------------------------
  // Сумма Доставки
  const sumAllTaxes = dealData.taxes.reduce((s, i) => {
    if (i.bill === 'nn') {
      s = s + +i.sumTax
    } else {
      s = s - +i.sumTax
    }
    return s
  }, 0)
  const sumAllGifts = dealData.gifts.reduce((s, i) => s = s + Number(i.sum), 0)
  const sumDeliver = dealData.df.reduce((s, i) => s + Number(i.sum), 0)
  const sumOther = sumAllTaxes + sumAllGifts
  // Сумма дельты без доков
  const sumDeltaOutDocs = sumClientInvoices - sumProviderInvoices - sumDeliver + sumAllTaxes - sumAllGifts
  // Сумма дельты с доками
  let sumDeltaWithDocs = 0
  if (dealData.allDocs.length > 0) {
    sumDeltaWithDocs = sumClientInvoices - sumAllDocs - sumDeliver + sumAllTaxes - sumAllGifts
  }
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  const [openDeal, setOpenDeal] = useState<boolean>(false)
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onOpenDeal = () => {
    setOpenDeal(!openDeal)
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  const CLIENT_NAME: string = dealData.client ? dealData.client.name : dealData.clientOld ? dealData.clientOld : ''
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <>
    <div className={classNames("deal-mini", {
      "deal-mini__opened": openDeal
    })} onClick={onOpenDeal}>
      {dealData.deleted && <div className="deal-mini__deleted"/>}
      {position !== 'manager' && <div className="deal-mini__manager">
        <img alt='' className="deal-mini__manager-avatar"
             src={dealData.responsibility.avatar === '' ? `${config.baseUrl}/images/avatars/default.png` : `${config.baseUrl}/${dealData.responsibility.avatar}`}/>
        <div className="deal-mini__manager-name">{dealData.responsibility.name}</div>
      </div>}
      <div className="deal-mini__date">
        {moment(dealData.date).format('DD.MM.YYYY')}
      </div>
      <div className="deal-mini__number">
        <div className="deal-mini__number-icon"><NumbersIcon fontSize={'small'}/></div>
        <div className="deal-mini__number-text">{dealData.responsibility.location === 'korolev' ? 'ДК-' : 'ДМ-'}{dealData.dealNumber}</div>
      </div>
      <div className="deal-mini__client">
        <div className="deal-mini__client-icon"><CorporateFareIcon fontSize={'small'}/></div>
        {dealData.client
            ? <Tooltip title={
              <React.Fragment>
                <p><b>{'ИНН:'}</b> {dealData.client.inn}</p>
                <p><b>{'Тел:'}</b> {dealData.client.phone ? dealData.client.phone : '—'}</p>
                <p><b>{'E-mail:'}</b> {dealData.client.email ? dealData.client.email : '—'}</p>
              </React.Fragment>
            }>
                <div className="deal-mini__client-text">{CLIENT_NAME}</div>
              </Tooltip>
            : <div className="deal-mini__client-text">{CLIENT_NAME}</div>}
      </div>
      <div className="deal-mini__statuses">
        <div className={classNames('deal-mini__statuses-icon', {'deal-mini__statuses--active': dealData.dealStatus.approved})}>
          <Tooltip title={dealData.dealStatus.approved ? 'Одобрено' : 'Не одобрено'}><ThumbUpIcon /></Tooltip>
        </div>
        <div className={classNames('deal-mini__statuses-icon', {'deal-mini__statuses--active': dealData.dealStatus.providerPaid})}>
          <Tooltip title={dealData.dealStatus.providerPaid ? 'Оплачена' : 'Не оплачена'}><CurrencyRubleIcon /></Tooltip>
        </div>
        <div className={classNames('deal-mini__statuses-icon', {'deal-mini__statuses--active': dealData.dealStatus.delivered})}>
          <Tooltip title={dealData.dealStatus.delivered ? 'Вывезена' : 'Не вывезена'}><LocalShippingIcon /></Tooltip>
        </div>
        <div className={classNames('deal-mini__statuses-icon', {'deal-mini__statuses--active': dealData.dealStatus.clientPaid})}>
          <Tooltip title={dealData.dealStatus.clientPaid ? 'Клиент оплатил' : 'Клиент не оплатил'}><AccountBalanceWalletIcon /></Tooltip>
        </div>
        <div className={classNames('deal-mini__statuses-icon', {'deal-mini__statuses--active': dealData.dealStatus.docSigned})}>
          <Tooltip title={dealData.dealStatus.docSigned ? 'Документы подписаны' : 'Документы не подписаны'}><AssignmentTurnedInIcon /></Tooltip>
        </div>
        <div className={classNames('deal-mini__statuses-icon', {'deal-mini__statuses--active': dealData.dealStatus.docCollected})}>
          <Tooltip title={dealData.dealStatus.docCollected ? 'Документы собраны' : 'Документы не собраны'}><FactCheckIcon /></Tooltip>
        </div>
      </div>
      <Tooltip title={'Сумма выставленных счетов'}><div className="deal-mini__receipt">
        <span className="deal-mini__receipt-text">{sumClientInvoices.toLocaleString()} ₽</span>
        <span className="deal-mini__receipt-icon"><ReceiptIcon fontSize={'small'}/></span>
      </div></Tooltip>
      <Tooltip title={'Дельта без учета документов'}><div className="deal-mini__delta-out">
        <span className="deal-mini__delta-out-text">{sumDeltaOutDocs.toLocaleString()} ₽</span>
        <span className="deal-mini__delta-out-icon"><PaidIcon fontSize={'small'}/></span>
      </div></Tooltip>
      <div className="deal-mini__marks">
        {(dealData.commentManager || dealData.commentHead) && <Tooltip title={'Есть комментарии к сделке'}><CommentIcon /></Tooltip>}
        {dealData.locked && <Tooltip title={'Сделка заблокирована'}><LockIcon /></Tooltip>}
        {!dealData.dealStatus.dealDone && <Tooltip title={'Сделка не готова'}><ReportGmailerrorredIcon /></Tooltip>}
      </div>
    </div>
    {openDeal && <Deal key={dealData.dealNumber}
                       dealData={dealData}
                       sumAllDocs={sumAllDocs}
                       sumClientInvoices={sumClientInvoices}
                       sumProviderInvoices={sumProviderInvoices}
                       sumDeliver={sumDeliver}
                       sumOther={sumOther}
                       sumDeltaWithDocs={sumDeltaWithDocs}
                       sumDeltaOutDocs={sumDeltaOutDocs}
                       position={position}
                       location={location}
    />}
  </>
};
export default DealMini;
