/** Hooks & React **/
import React from 'react';
import {useSelector} from "react-redux";
/** Styles & Graphics **/
/** Components **/
import StatusBlock from "./StatusBlock";
import ChangeDealDate from "./_organisms/ChangeDealDate";
/** Types **/
import {TPosition} from "../../../../redux/types/auth.types";
import {IDeal, IDealsLoading} from "../../../../redux/types/deals.types";
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
import {loadingSelector} from "../../../../redux/selectors/deals.selectors";
import DealControl from "./_organisms/DealControl";
/** Other **/

interface IProps {
  dealData: IDeal
  position: TPosition
  location: string
}

const DealLeft: React.FC<IProps> = ({ dealData: { _id, responsibility, dealStatus,
  dealNumber, date, client, drivers, forwarders, gifts,
  clientInvoices, providerInvoices, address, deleted, locked}, position, location }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  const loading: IDealsLoading = useSelector(loadingSelector)
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */

  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */

  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className='deal-left'>
    <DealControl
        id={_id}
        responsibilityId={responsibility._id}
        position={position}
        dealDone={dealStatus.dealDone}
        dealNumber={dealNumber}
        client={client}
        drivers={drivers}
        forwarders={forwarders}
        gifts={gifts}
        clientInvoices={clientInvoices}
        providerInvoices={providerInvoices}
        address={address}
        date={date}
        location={location}
        deleted={deleted}
        locked={locked}
    />
    {!(locked || deleted) && <ChangeDealDate id={_id} date={date}/>}
    <StatusBlock
        dealStatus={dealStatus}
        dealId={_id}
        managerId={responsibility._id}
        loading={loading}
        deleted={deleted}
        locked={locked}
    />
  </div>
};
export default DealLeft;
