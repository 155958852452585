/** Hooks & React **/
import React, {useState} from "react";
/** Styles & Graphics **/
import './Deal.scss'
/** Components **/
import CircularProgress from "@material-ui/core/CircularProgress";
import AddFile from "./Add/AddFile";
import DocsItem from "./DocsItem";
import {
    IDealClientInvoice,
    IDealDoc,
    IDealProviderInvoice,
    IDealsLoading
} from "../../../../redux/types/deals.types";
import {TPosition} from "../../../../redux/types/auth.types";
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    id: string
    managerId: string
    sumClientInvoices: number
    sumProviderInvoices: number
    sumAllDocs: number
    clientInvoices: Array<IDealClientInvoice>
    providerInvoices: Array<IDealProviderInvoice>
    allDocs: Array<IDealDoc>
    position: TPosition
    dealDone: boolean
    loading: IDealsLoading
    locked: boolean
    deleted: boolean
}

const DealCenter: React.FC<IProps> = ({
                         clientInvoices,
                         providerInvoices,
                         allDocs,
                         id,
                         managerId,
                         loading,
                         position,
                         dealDone,
                         sumClientInvoices,
                         sumProviderInvoices,
                         sumAllDocs,
                                          deleted,
                                          locked
}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [openAddFile, setOpenAddFile] = React.useState(false);
    let [typeFile, setTypeFile] = useState('')
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    // окно ADD FILE
    // открыть для ClientInvoices
    const onAddFileOpenCI = () => {
            setOpenAddFile(true)
            setTypeFile('CI')
        }
    // открыть для ProviderInvoices
    const onAddFileOpenPI = () => {
        setOpenAddFile(true)
        setTypeFile('PI')
    }
    // открыть для AllDocs
    const onAddFileOpenDOC = () => {
        setOpenAddFile(true)
        setTypeFile('DOC')
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    // отрисовка счетов для клиента
    let clientInvoicesElements = clientInvoices.map(clientInvoice => <DocsItem
            key={clientInvoice.fileUrl + clientInvoice.sum}
            company={clientInvoice.company}
            fileUrl={clientInvoice.fileUrl}
            sum={clientInvoice.sum}
            typeFile={clientInvoice.typeFile}
            dealId={id}
            position={position}
            dealDone={dealDone}
            managerId={managerId}
            locked={locked}
            deleted={deleted}
        />)
    // -----------------------------------------------------------------------------------------------------------------
    // отрисовка счетов поставщиков
    let providerInvoicesElements = providerInvoices.map(providerInvoice => <DocsItem
        key={providerInvoice.fileUrl + providerInvoice.sum}
        company={providerInvoice.company}
        fileUrl={providerInvoice.fileUrl}
        sum={providerInvoice.sum}
        typeFile={providerInvoice.typeFile}
        dealId={id}
        position={position}
        dealDone={dealDone}
        managerId={managerId}
        locked={locked}
        deleted={deleted}
    />)
    // -----------------------------------------------------------------------------------------------------------------
    // отрисовка документов
    let docsElements = allDocs.map(doc => <DocsItem key={doc.fileUrl + doc.sum}
                                                    company={doc.company}
                                                    fileUrl={doc.fileUrl}
                                                    sum={doc.sum}
                                                    typeFile={doc.typeFile}
                                                    dealId={id}
                                                    position={position}
                                                    dealDone={dealDone}
                                                    managerId={managerId}
                                                    locked={locked}
                                                    deleted={deleted}
    />)
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <>
            {/*----------------------начало-------------------ОКНО ADD FILE------------------------------------------*/}
            <AddFile openAddFile={openAddFile}
                     setOpenAddFile={setOpenAddFile}
                     typeFile={typeFile}
                     setTypeFile={setTypeFile}
                     id={id}
                     managerId={managerId}
            />
            {/*----------------------конец--------------------ОКНО ADD FILE------------------------------------------*/}
            <div className='deal-center'>
                <div className='deal-center__ci'>
                    <div className='deal-center__header'>
                        <div className='deal-center__title'>счета клиенту</div>
                        <div className='deal-center__loading'>{loading.fileCI &&<CircularProgress color="secondary" size={20}/>}</div>
                        <div className='deal-center__sum'>{sumClientInvoices.toLocaleString()} ₽</div>
                    </div>
                    <div className='dc-files'>
                        {clientInvoicesElements}
                        {(position === 'manager' || position === 'chief') && !dealDone && !(locked || deleted) &&
                        <div className='dc-files__add-file' onClick={onAddFileOpenCI}>
                            <div className='dc-files__add-file--icon'>+</div>
                            <div className='dc-files__add-file--text'>Добавить<br/>файл</div>
                        </div>}
                    </div>
                </div>
                <div className='deal-center__pi'>
                    <div className='deal-center__header'>
                        <div className='deal-center__title'>счета поставщиков</div>
                        <div className='deal-center__loading'>{loading.filePI &&<CircularProgress color="secondary" size={20}/>}</div>
                        <div className='deal-center__sum'>{sumProviderInvoices.toLocaleString()} ₽</div>
                    </div>
                    <div className='dc-files'>
                        {providerInvoicesElements}
                        {(position === 'manager' || position === 'chief') && !dealDone && !(locked || deleted) &&
                        <div className='dc-files__add-file' onClick={onAddFileOpenPI}>
                            <div className='dc-files__add-file--icon'>+</div>
                            <div className='dc-files__add-file--text'>Добавить<br/>файл</div>
                        </div>}
                    </div>
                </div>
                <div className='deal-center__doc'>
                    <div className='deal-center__header'>
                        <div className='deal-center__title'>документы</div>
                        <div className='deal-center__loading'>{loading.fileDOC &&<CircularProgress color="secondary" size={20}/>}</div>
                        <div className='deal-center__sum'>{sumAllDocs.toLocaleString()} ₽</div>
                    </div>
                    <div className='dc-files'>
                        {docsElements}
                        {(position === 'manager' || position === 'chief' || position === 'secretary') && !(locked || deleted) && <div
                            className='dc-files__add-file'
                            onClick={onAddFileOpenDOC}
                        >
                            <div className='dc-files__add-file--icon'>+</div>
                            <div className='dc-files__add-file--text'>Добавить<br/>файл</div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DealCenter;