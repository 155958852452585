import {notificationsAPI} from "../../API/api";
import {INotification, INotificationsState} from "../types/notifications.types";
import produce from "immer";
import {TDispatch, TGetState} from "../redux-store";
import {ifAnyErrorThunk} from "./app.reducer";
// Actions

enum ActionsType {
    SET_NOTIFICATIONS_DATA = 'dealCRM/notifications/SET_NOTIFICATIONS_DATA',
    SET_NOTIFICATIONS_POPUP_DATA = 'dealCRM/notifications/SET_NOTIFICATIONS_POPUP_DATA',
    SET_NOTIFICATIONS_PAGES_COUNT = 'dealCRM/notifications/SET_NOTIFICATIONS_PAGES_COUNT',
    SET_NOTIFICATIONS_CURRENT_PAGE = 'dealCRM/notifications/SET_NOTIFICATIONS_CURRENT_PAGE',
    TOGGLE_IS_FETCHING = 'dealCRM/notifications/TOGGLE_IS_FETCHING',
    SET_NOTIFICATIONS_NO_READ_COUNT = 'dealCRM/notifications/SET_NOTIFICATIONS_NO_READ_COUNT',
    SET_TOGGLE_REFRESH = 'dealCRM/notifications/SET_TOGGLE_REFRESH',
    SET_TOGGLE_NEW_NOTIFICATION = 'dealCRM/notifications/SET_TOGGLE_NEW_NOTIFICATION'
}

// InitialState

let initialState: INotificationsState = {
    notifications: [],
    notificationsPopup: [],
    notificationsPagesCount: 0,
    notificationsCurrentPage: 1,
    isFetching: true,
    countNotificationsNoRead: 0,
    toggleRefresh: false,
    newNotification: false,
}

// ActionCreators

export const notificationsAC = {
    setNotificationsData: (data: Array<INotification>) => ({type: ActionsType.SET_NOTIFICATIONS_DATA, data} as const),
    setNotificationsPopupData: (data: Array<INotification>) => ({type: ActionsType.SET_NOTIFICATIONS_POPUP_DATA, data} as const),
    setNotificationsPagesCount: (count: number) => ({type: ActionsType.SET_NOTIFICATIONS_PAGES_COUNT, count} as const),
    setNotificationsCurrentPage: (page: number) => ({type: ActionsType.SET_NOTIFICATIONS_CURRENT_PAGE, page} as const),
    toggleIsFetching: (isFetching: boolean) => ({type: ActionsType.TOGGLE_IS_FETCHING, isFetching} as const),
    setNotificationsNoReadCount: (count: number) => ({type: ActionsType.SET_NOTIFICATIONS_NO_READ_COUNT, count} as const),
    setToggleRefresh: (toggleRefresh: boolean) => ({type: ActionsType.SET_TOGGLE_REFRESH, toggleRefresh} as const),
    setToggleNewNotification: (newNotification: boolean) => ({type: ActionsType.SET_TOGGLE_NEW_NOTIFICATION, newNotification} as const),
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof notificationsAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

// cases
const notificationsReducer = (state = initialState, action: ActionType): INotificationsState =>
    produce(state, draft => {
        switch (action.type) {
        case ActionsType.SET_NOTIFICATIONS_DATA:
            draft.notifications = action.data
            break
        case ActionsType.SET_NOTIFICATIONS_POPUP_DATA:
            draft.notificationsPopup = action.data
            break
        case ActionsType.SET_NOTIFICATIONS_PAGES_COUNT:
            draft.notificationsPagesCount = action.count
            break
        case ActionsType.SET_NOTIFICATIONS_CURRENT_PAGE:
            draft.notificationsCurrentPage = action.page
            break
        case ActionsType.TOGGLE_IS_FETCHING:
            draft.isFetching = action.isFetching
            break
        case ActionsType.SET_NOTIFICATIONS_NO_READ_COUNT:
            draft.countNotificationsNoRead = action.count
            break
        case ActionsType.SET_TOGGLE_REFRESH:
            draft.toggleRefresh = action.toggleRefresh
            break
        case ActionsType.SET_TOGGLE_NEW_NOTIFICATION:
            draft.newNotification = action.newNotification
            break
    }
})

// ActionCreators


// Thunks
// загрузка всех уведомлений для пользователя
export const loadingAllNotificationsData = (page: number) => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    try {
        dispatch(notificationsAC.toggleIsFetching(true))
        const data = await notificationsAPI.getAllNotifications(id, page, token)
        dispatch(notificationsAC.toggleIsFetching(false))
        dispatch(notificationsAC.setNotificationsData(data.result))
        dispatch(notificationsAC.setNotificationsPagesCount(data.pages))
        dispatch(notificationsAC.setNotificationsCurrentPage(data.page))
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}
// сделать прочитанными все уведомления
export const setReadAllNotifications = (page: number) => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    try {
        if (id) {
            await notificationsAPI.readAllNotifications(id, token)
            await dispatch(loadingAllNotificationsData(page))
        }
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}

// сделать прочитанными все уведомления через popup окно
export const setReadAllNotificationsMini = () => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    try {
        if (id) {
            await notificationsAPI.readAllNotifications(id, token)
            await dispatch(loadingNotificationsNoReadCount())
            await dispatch(loadingNotificationsForPopupData())
        }
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}
// загрузка уведомлений для пользователя для всплывающего окна
export const loadingNotificationsForPopupData = () => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    try {
        if (id) {
            const data = await notificationsAPI.getNotificationsForPopup(id, token)
            dispatch(notificationsAC.setNotificationsPopupData(data))
        }
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}
// счетчик новых уведомлений для пользователя
export const loadingNotificationsNoReadCount = () => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    try {
        if (id) {
            const data = await notificationsAPI.getCountNoReadNotifications(id, token)
            dispatch(notificationsAC.setNotificationsNoReadCount(data.count))
        }
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}
// загрузка новых уведомлений для пользователя
export const loadingNewNotificationsData = () => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    try {
        if (id) {
            const data = await notificationsAPI.getNewNotifications(id, token)
            dispatch(notificationsAC.setNotificationsData(data))
        }
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}
// добавление нового уведомления
export const addNewNotification = (recipients: string, deal: string, message: string) => async (dispatch: TDispatch, getState: TGetState) => {
    let id: string | undefined = getState().authBlock.me?._id
    let token = getState().authBlock.token
    let read = false
    try {
        if (id) {
            if (recipients === id) {
                read = true
            }
            await notificationsAPI.create(id, recipients, deal, message, read, token)
        }
    } catch (e: any) {
        ifAnyErrorThunk(e)
    }
}

// проверка на новые уведомления
export const checkNotifications = () => async (dispatch: TDispatch) => {
    dispatch(notificationsAC.setToggleRefresh(false))
}

export default notificationsReducer;