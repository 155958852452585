import {getAuthUserData} from "./auth.reducer";
import produce from 'immer';
import {IAppState, ISnack, ISnackLink} from "../types/app.types";
import { showErrorMessage } from "../../utils/showErrorMessage";
import { randomString } from "../../utils/randomString";
import { TDispatch } from "../redux-store";

// Actions

enum ActionsType {
    INITIALIZED_SUCCESS = 'dealCRM/app/INITIALIZED_SUCCESS',
    TOGGLE_CHAT_OPEN = 'dealCRM/app/TOGGLE_CHAT_OPEN',
    /** Снэкбары */
    ADD_NEW_SNACK = '[set] Добавлен новый снэкбар в список',
    DELETE_SNACK = '[set] Удален снэкбар из списка',
}

// InitialState

const initialState: IAppState = {
    initialized: false,
    isChatOpen: false,
    snackBars: []
}

// ActionCreators

export const appAC = {
    initializedSuccess: () => ({ type: ActionsType.INITIALIZED_SUCCESS } as const),
    toggleChatOpen: (toggle: boolean) => ({type: ActionsType.TOGGLE_CHAT_OPEN, toggle} as const),
    /** Снэкбары */
    addNewSnack: (payload: ISnack) => ({ type: ActionsType.ADD_NEW_SNACK, payload } as const),
    deleteSnack: (payload: string) => ({ type: ActionsType.DELETE_SNACK, payload } as const),
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof appAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

let appReducer = (state = initialState, action: ActionType): IAppState =>
    produce(state, draft => {
        switch (action.type) {
            case ActionsType.INITIALIZED_SUCCESS:
                draft.initialized = true
                break
            case ActionsType.TOGGLE_CHAT_OPEN:
                draft.isChatOpen = action.toggle
                break
            /** Снэкбары */
            case ActionsType.ADD_NEW_SNACK:
                draft.snackBars.push((action.payload))
                break;
            case ActionsType.DELETE_SNACK:
                draft.snackBars = draft.snackBars.filter(item => item.id !== action.payload)
                break;
        }
    })



export const initializeAppThunk = () => (dispatch: TDispatch) => {
    let promise = dispatch(getAuthUserData())
    Promise.all([promise])
        .then(() => {
            dispatch(appAC.initializedSuccess())
        })
}

/** Снэкбары */
export const addNewSnackThunk = (type: 'info' | 'warning' | 'error', text: string, isVanishing: boolean = false, errorText?: string, link?: ISnackLink ) => (dispatch: TDispatch) => {
    try {
        const id = randomString(5)
        dispatch(appAC.addNewSnack({
            id: id,
            type: type,
            data: text,
            errorText: errorText,
            isVanishing: isVanishing
        }))
    } catch (e) {
        showErrorMessage(e);
    }
}

/** Вывод ошибок и действия после возникновения ошибки */
export const ifAnyErrorThunk = (e: any) => (dispatch: TDispatch) => {
    try {
        const errorInfo = e.response.data.detail || e.response.data.title || '';
        let errorText: string;
        switch (e?.response?.status) {
            case 400:
                errorText = `Ошибка на клиенте! ${e.response.status}`;
                break;
            case 403:
                errorText = `Ошибка авторизации! ${e.response.status}`;
                break;
            case 500:
                errorText = `Ошибка на сервере! ${e.response.status}`;
                break;
            default:
                errorText = `Неизвестная ошибка! ${e.response.status}`;
                break;
        }
        /** Выводим снэкбар с ошибкой */
        dispatch(addNewSnackThunk('error', errorText, false, errorInfo));
    } catch (e) {
        showErrorMessage(e);
    }
};

export default appReducer;