import React from 'react';

export interface ISearchHighlighter {
  jsx: string | JSX.Element
  found: boolean
}

interface IRange {
  start: number
  end: number
  exist: boolean
}

export function markSearchText(str: string, searchValue: string): ISearchHighlighter {
  if (searchValue.length === 0) return {
    jsx: str,
    found: false
  }
  const strVal = str.toLowerCase()
  const searchArray = searchValue.toLowerCase().split(' ').filter(item => item !== '')
  let foundArray: Array<Array<number>> = []
  searchArray.forEach(word => {
    let pos = -1
    while ((pos = strVal.indexOf(word, pos + 1)) !== -1) {
      foundArray.push([pos, pos + word.length])
    }
  })

  if (foundArray.length > 0) {
    const allNumbers: Array<number> = []
    foundArray.forEach(range => {
      for (let i = range[0]; i < range[1]; i++) {
        allNumbers.push(i)
      }
    })

    const foundArraySorted: Array<number> = Array.from(new Set(allNumbers)).sort((n1,n2) => n1 - n2)

    const makeRange = (array: Array<number>, str: string) => {
      const ranges: Array<IRange> = []
      let start: number = 0
      for (let i = 0; i < str.length; i++) {
        if (array.includes(i)) {
          if (!array.includes(i - 1)) start = i
          if (!array.includes(i + 1)) {
            ranges.push({
              start: start,
              end: i,
              exist: true
            })
          }
        } else {
          if (array.includes(i - 1)) start = i
          if (array.includes(i + 1)) {
            ranges.push({
              start: start,
              end: i,
              exist: false
            })
          }
        }
        if (i === str.length - 1 && ranges[ranges.length - 1].end !== str.length - 1) {
          ranges.push({
            start: start,
            end: str.length - 1,
            exist: array.includes(i)
          })
        }
      }
      return ranges
    }

    const ranges: Array<IRange> = makeRange(foundArraySorted, str)
    const jsxSpan = ranges.map(item => <span key={item.start + item.end} className={item.exist ? 'search-highlight' : ''}>{str.slice(item.start, item.end + 1)}</span>)

    return {
      jsx: <>{jsxSpan}</>,
      found: true
    }
  } else {
    return {
      jsx: str,
      found: false
    }
  }
}