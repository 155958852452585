import produce from 'immer';
import {ICommonState, TCommonLoadingKeys} from "../types/common.types";

// Actions

enum ActionsType {
    TOGGLE_IS_SUBMITTED = '[toggle] Флаг вызова функции',
    TOGGLE_LOADING = '[toggle] Переключаем флаг загрузки',
}

// InitialState

const initialState: ICommonState = {
    forms: {
        isSubmitted: false
    },
    loading: {
        markingDeleted: false,
        lockingDeal: false
    }

}

// ActionCreators

export const commonAC = {
    toggleIsSubmitted: (toggle: boolean) => ({type: ActionsType.TOGGLE_IS_SUBMITTED, toggle} as const),
    toggleLoading: (key: TCommonLoadingKeys, bool: boolean) => ({type: ActionsType.TOGGLE_LOADING, key, bool} as const),
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof commonAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

let appReducer = (state = initialState, action: ActionType): ICommonState =>
    produce(state, draft => {
        switch (action.type) {
            case ActionsType.TOGGLE_IS_SUBMITTED:
                draft.forms.isSubmitted = action.toggle
                break
            case ActionsType.TOGGLE_LOADING:
                switch (action.key) {
                    case "markingDeleted":
                        draft.loading.markingDeleted = action.bool
                        break
                    case "lockingDeal":
                        draft.loading.lockingDeal = action.bool
                        break
                    default:
                        break
                }
                break
        }
    })



// export const initializeAppThunk = () => (dispatch: TDispatch) => {
//     let promise = dispatch(getAuthUserData())
//     Promise.all([promise])
//         .then(() => {
//             dispatch(appAC.initializedSuccess())
//         })
// }

export default appReducer;