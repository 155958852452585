import {authAPI} from "../../API/api";
import {setManagerMode} from '../settings-reducer'
import {IAuthState, IMe} from "../types/auth.types";
import produce from "immer";
import {showErrorMessage} from "../../utils/showErrorMessage";
import {addNewSnackThunk} from "./app.reducer";

// Actions

enum ActionsType {
    SET_USER_DATA = 'SET_USER_DATA',
    UPDATE_NEW_EMAIL_TEXT = 'UPDATE_NEW_EMAIL_TEXT',
    UPDATE_NEW_PASSWORD_TEXT = 'UPDATE_NEW_PASSWORD_TEXT',
    DELETE_USER_DATA = 'DELETE_USER_DATA',
    CLEAR_EMAIL_AND_PASSWORD = 'CLEAR_EMAIL_AND_PASSWORD',
    SET_RESPONSE_MESSAGE = 'SET_RESPONSE_MESSAGE',
    SET_TOKEN_DATA = 'SET_TOKEN_DATA',
    TOGGLE_IS_WAITING_LOGIN = 'TOGGLE_IS_WAITING_LOGIN',
}

// InitialState

let initialState: IAuthState = {
    me: {} as IMe,
    isAuth: false,
    newEmailText: '',
    newPasswordText: '',
    textResponseMessage: null,
    typeResponseMessage: null,
    token: '',
    isWaitingLogin: false
}

// ActionCreators

export const authAC = {
    setAuthUserData: (userData: IMe, isAuth: boolean) => ({ type: ActionsType.SET_USER_DATA, userData, isAuth } as const),
    newEmailTextAC: (text: string) => ({ type: ActionsType.UPDATE_NEW_EMAIL_TEXT, text } as const),
    newPasswordTextAC: (text: string) => ({ type: ActionsType.UPDATE_NEW_PASSWORD_TEXT, text } as const),
    exitAuth: () => ({ type: ActionsType.DELETE_USER_DATA } as const),
    clearEmailAndPassword: () => ({ type: ActionsType.CLEAR_EMAIL_AND_PASSWORD } as const),
    setResponseMessage: (message: string, typeMessage: 'error' | 'inform' | null) => ({ type: ActionsType.SET_RESPONSE_MESSAGE, message, typeMessage } as const),
    setTokenData: (token: string) => ({ type: ActionsType.SET_TOKEN_DATA, token} as const),
    toggleIsWaitingLogin: (toggle: boolean) => ({ type: ActionsType.TOGGLE_IS_WAITING_LOGIN, toggle} as const),
}

// Union Type for actions

type ActionType = GetActionsTypes<typeof authAC>
type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
type GetActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesType<T>>

const authReducer = (state = initialState, action: ActionType): IAuthState =>
    produce(state, draft => {
        switch (action.type) {
            case ActionsType.SET_USER_DATA:
                draft.me = action.userData
                draft.isAuth = action.isAuth
                draft.newEmailText = ''
                draft.newPasswordText = ''
                break
            case ActionsType.UPDATE_NEW_EMAIL_TEXT:
                draft.newEmailText = action.text
                break
            case ActionsType.UPDATE_NEW_PASSWORD_TEXT:
                draft.newPasswordText = action.text
                break
            case ActionsType.DELETE_USER_DATA:
                draft.isAuth = false
                break
            case ActionsType.CLEAR_EMAIL_AND_PASSWORD:
                draft.newEmailText = ''
                draft.newPasswordText = ''
                break
            case ActionsType.SET_RESPONSE_MESSAGE:
                draft.textResponseMessage = action.message
                draft.typeResponseMessage = action.typeMessage
                break
            case ActionsType.SET_TOKEN_DATA:
                draft.token = action.token
                break
            case ActionsType.TOGGLE_IS_WAITING_LOGIN:
                draft.isWaitingLogin = action.toggle
                break
        }
})

// Thunks

export const getAuthUserData = () => async (dispatch: any) => {
    if (localStorage.getItem('token')) {
        const userDataLocalStorage: any = JSON.parse(localStorage.getItem('token') as string)
        dispatch(authAC.setTokenData(userDataLocalStorage.token))
        try {
            const me = await authAPI.me(userDataLocalStorage.id, userDataLocalStorage.token)
            if (me.statusText === 'OK') {
                dispatch(authAC.setAuthUserData(me.data, true))
                if (me.data.position === 'manager') {
                    dispatch(setManagerMode(true))
                } else {
                    dispatch(setManagerMode(false))
                }
            }
        } catch (e) {
            return Promise
        }
    }
};

export const login = (email: string, password: string) => async (dispatch: any) => {
    try {
        dispatch(authAC.toggleIsWaitingLogin(true))
        const response = await authAPI.loginToCRM(email, password)
        dispatch(authAC.setTokenData(response.data.token))
        localStorage.setItem('token', JSON.stringify({
            token: response.data.token,
            id: response.data.id
        }))
        const me = await authAPI.me(response.data.id, response.data.token)
        dispatch(authAC.setAuthUserData(me.data, true))
        dispatch(authAC.toggleIsWaitingLogin(false))
    } catch (e: any) {
        showErrorMessage(e)
        /** Выводим снэкбар с ошибкой */
        e.response && e.response.data && e.response.data.message &&
        dispatch(addNewSnackThunk('error', e.response.data.message, true));
        dispatch(authAC.toggleIsWaitingLogin(false))
    }
}

export const logout = () => async (dispatch: any) => {
    dispatch(authAC.setAuthUserData({} as IMe, false))
    localStorage.removeItem('token')
}

export const register = (email: string, password: string) => async (dispatch: any) => {
    const data = await authAPI.registerToCRM(email, password).catch((err: any) => err.response.data)
    if (data.message) {
        dispatch(authAC.setResponseMessage(data.message, 'error'))
    } else {
        dispatch(authAC.clearEmailAndPassword())
    }
}

export const changePasswordToNew = (newPassword: string) => async (dispatch: any, getState: any) => {
    const id = getState().authBlock.me._id
    const data = await authAPI.changePassword(id, newPassword).catch((err: any) => err.response.data)
    if (data.status === 201) {
        dispatch(authAC.setResponseMessage(data.data.message, 'inform'))
    } else {
        dispatch(authAC.setResponseMessage(data.data.message, 'error'))
    }
}

export default authReducer;