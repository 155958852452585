import {IStore} from "../redux-store";

export const tableDataSelector = (store: IStore) => store.tables.tableData
export const currentPageSelector = (store: IStore) => store.tables.currentPage
export const countPagesSelector = (store: IStore) => store.tables.pages
export const filterDfIdSelector = (store: IStore) => store.tables.filters.dfId
export const filterDateFromSelector = (store: IStore) => store.tables.filters.dateFrom
export const filterDateToSelector = (store: IStore) => store.tables.filters.dateTo
export const filterManagerIdSelector = (store: IStore) => store.tables.filters.managerId
export const tableItemDataSelector = (store: IStore) => store.tables.itemData
export const clientsForSelectSelector = (store: IStore) => store.tables.tableData.map(client => ({
    id: client._id,
    name: client.name,
}))
export const tableDataLoadedSelector = (store: IStore) => store.tables.tableDataLoaded
export const filteredSelector = (store: IStore) => store.tables.filtered
export const serverErrorSelector = (store: IStore) => store.tables.serverError
export const loadingSelector = (store: IStore) => store.tables.loading
export const savingSelector = (store: IStore) => store.tables.saving
export const savingRowSelector = (store: IStore) => store.tables.savingRow
export const successSelector = (store: IStore) => store.tables.success
export const searchSelector = (store: IStore) => store.tables.search