/** Hooks & React **/
import React from 'react';
import {NavLink} from "react-router-dom";
/** Styles & Graphics **/
import classes from './NotificationsMini.module.css'
/** Components **/
import Tooltip from "@material-ui/core/Tooltip";
/** Types **/
/** Utilities **/
import {dateAndTimeNormalize, dateNormalize} from "../../../common/DateNormalize/DateNormalize";
import {IMe} from "../../../redux/types/auth.types";
import {IDeal} from "../../../redux/types/deals.types";
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    creator: IMe
    deal: IDeal
    dt: string
    message: string
    read: boolean
}

const Notification: React.FC<IProps> = ({creator: {name}, deal: {client, clientOld, date, _id}, message, dt, read}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const CLIENT_NAME: string = client ? client.name : clientOld ? clientOld : ''
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return <div className={`${classes.notification} ${read ? classes.read : classes.noRead}`}>
        <div className={classes.row}>
            <div className={classes.firstBlock}>
                <div className={classes.dt}>{dateAndTimeNormalize(dt)}</div>
                <div className={classes.creator}>{name}</div>
            </div>
            <div className={classes.message}>{message}</div>
            <div className={`${classes.dealLink} ${read ? classes.dealLinkRead : classes.dealLinkNoRead}`}>
                <Tooltip title="Перейти к сделке" placement="bottom-start">
                    <NavLink to={`/dealspage/${_id}`}>
                        <div>Клиент {CLIENT_NAME}</div>
                        <div>от {dateNormalize(date)}</div>
                    </NavLink>
                </Tooltip>
            </div>
        </div>
    </div>
}

export default Notification;