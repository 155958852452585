/** Hooks & React **/
import React, {Dispatch, SetStateAction} from 'react';
import config from "../../../config/config.json";
import {IClient} from "../../../redux/types/tables.types";
import { ISearchHighlighter, markSearchText } from '../../../utils/markSearchText';
import { useMemo } from 'react';
import {useSelector} from "react-redux";
import {searchSelector} from "../../../redux/selectors/tables.selector";
/** Styles & Graphics **/
import AbcIcon from '@mui/icons-material/Abc';
import BusinessIcon from "@material-ui/icons/Business";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import EditIcon from '@mui/icons-material/Edit';
import {Button} from "@mui/material";
import {userIdSelector} from "../../../redux/selectors/auth.selectors";
import {dateTime} from "../../../utils/dateTime";
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
    data: IClient
    setEditMode: Dispatch<SetStateAction<boolean>>
    setId: Dispatch<SetStateAction<string>>
}

const ClientsRow: React.FC<IProps> = ({data: {_id, name, inn, type, manager, phone, email, created }, setId, setEditMode }) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const search: string = useSelector(searchSelector)
    const userId: string | null = useSelector(userIdSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const onEditClient = () => {
        setId(_id)
        setEditMode(true)
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const MANAGER_NAME: string = manager && manager.name ? manager.name : ''
    const NAME_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(name, search), [name, search])
    const INN_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(inn, search), [inn, search])
    const PHONE_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(phone, search), [phone, search])
    const EMAIL_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(email, search), [email, search])
    const MANAGER_NAME_SEARCH: ISearchHighlighter = useMemo(() => markSearchText(MANAGER_NAME, search), [MANAGER_NAME, search])
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className='table__row'>
            <div className="table__row-field table__row-edit">
                {userId && manager && manager._id === userId && <Button variant={'text'} color={'primary'} onClick={onEditClient}><EditIcon/></Button>}
            </div>
            <div className="table__row-field table__row-name">
                <div className='table__row-field--icon'><AbcIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{NAME_SEARCH.jsx}</div>
            </div>
            <div className="table__row-field table__row-inn">
                <div className='table__row-field--icon'><Grid3x3Icon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{type === 'ЧЛ' ? '-' : 'ИНН ' + INN_SEARCH.jsx}</div>
            </div>
            <div className="table__row-field table__row-type">
                <div className='table__row-field--icon'><BusinessIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{type}</div>
            </div>
            <div className="table__row-field table__row-phone">
                <div className='table__row-field--icon'><PhoneIphoneIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{PHONE_SEARCH.jsx}</div>
            </div>
            <div className="table__row-field table__row-email">
                <div className='table__row-field--icon'><EmailIcon fontSize={'small'}/></div>
                <div className='table__row-field--text'>{EMAIL_SEARCH.jsx}</div>
            </div>
            <div className="table__row-field table__row-created">{dateTime.print(created).date}</div>
            <div className="table__row-field table__row-manager">
                <div className="table__row-manager--name">{MANAGER_NAME_SEARCH.jsx}</div>
                <div className="table__row-manager--avatar">
                    <img alt='' className="deal-mini__manager-avatar"
                         src={manager && manager.avatar !== ''
                             ? `${config.baseUrl}/${manager.avatar}`
                             : `${config.baseUrl}/images/avatars/default.png`}/>
                </div>
            </div>
        </div>
    )
}

export default ClientsRow;