/** Hooks & React **/
import React, {useEffect} from 'react';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
/** Styles & Graphics **/
import '../Deal.scss'
import moment from 'moment';
import { useState } from 'react';
import {dateTime} from "../../../../../utils/dateTime";
/** Components **/
/** Types **/
/** Utilities **/
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {changeDealDateThunk} from "../../../../../redux/reducers/deals.reducer";
import ErrorValidation from "../../../../_organisms/ErrorValidation/ErrorValidation";
import {isSubmittedSelector} from "../../../../../redux/selectors/common.selectors";
import {commonAC} from "../../../../../redux/reducers/common.reducer";
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  id: string
  date: string;
}

const ChangeDealDate: React.FC<IProps> = ({ id, date }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  const isSubmitted: boolean = useSelector(isSubmittedSelector)
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  const [changeDate, setChangeDate] = useState(false)
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  let validationSchema = yup.object().shape({
    date: yup.date().required('Выберите дату')
  });
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      date: '' as string,
    },
    onSubmit: (): void => {},
    validateOnMount: true,
    validationSchema: validationSchema
  });

  const formikValues = formik.values
  const formikSet = formik.setFieldValue
  const formikErrors = formik.errors
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onOpenChangeDate = () => {
    setChangeDate(true)
  }
  const onCloseChangeDate = () => {
    setChangeDate(false)
    formikSet('date', dateTime.fromApi(date).date)
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  const dispatch = useDispatch()
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  useEffect(() => {
    formikSet('date', dateTime.fromApi(date).date)
    // eslint-disable-next-line
  }, [date])

  useEffect(() => {
    return () => {
      dispatch(commonAC.toggleIsSubmitted(false))
      formikSet('date', dateTime.fromApi(date).date)
    }
    // eslint-disable-next-line
  }, [])
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  const onChangeDate = (e: any) => {
    formikSet('date', moment(e).format('YYYY-MM-DD'))
  }

  const onSaveDate = () => {
    dispatch(commonAC.toggleIsSubmitted(true))
    if (Object.keys(formikErrors).length === 0) {
      onCloseChangeDate()
      if (moment(date).format('YYYY-MM-DD') !== formikValues.date) {
        dispatch(changeDealDateThunk(id, formikValues.date))
      }
    }
  }
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className="deal-left__change-date">
    {changeDate
        ? <>
            <div className='deal-left__change-date-container'>
              <LocalizationProvider dateAdapter={DateAdapter} locale={'ru'}>
                <DesktopDatePicker
                    mask={'__.__.____'}
                    label="Дата сделки"
                    inputFormat="DD.MM.yyyy"
                    value={formikValues.date}
                    onChange={onChangeDate}
                    renderInput={(params) => <TextField id={'date'} fullWidth {...params} />}
                />
              </LocalizationProvider>
              <div className="deal-left__change-date-actions">
                <Button variant={'text'} fullWidth onClick={onSaveDate}><SaveIcon /></Button>
                <Button variant={'text'} fullWidth onClick={onCloseChangeDate}><CloseIcon /></Button>
              </div>
            </div>
            {isSubmitted && <ErrorValidation message={'Невалидная дата'} triggers={[Boolean(formikErrors.date)]}/>}
        </>
        : <Button variant={'text'} onClick={onOpenChangeDate}>Изменить дату</Button> }
  </div>
};
export default ChangeDealDate;
