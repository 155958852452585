/** Hooks & React **/
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
/** Styles & Graphics **/
/** Components **/
import BusinessIcon from "@material-ui/icons/Business";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DoneIcon from '@material-ui/icons/Done';
import DescriptionIcon from '@material-ui/icons/Description';
import classes from "./addFile.module.css";
import SimpleTextField from "../../../../_organisms/SimpleTextField/SimpleTextField";
import SelectField from "../../../../_organisms/SelectField/SelectField";
/** Types **/
import {ICompany} from "../../../../../redux/types/companies.types";
/** Utilities **/
import {useFormik} from "formik";
import {useDropzone} from 'react-dropzone'
import * as yup from "yup";
/** Thunks and ActionCreators **/
import { saveFile } from "../../../../../redux/reducers/deals.reducer";
import {commonAC} from "../../../../../redux/reducers/common.reducer";
/** Selectors **/
import {companiesSelector} from "../../../../../redux/selectors/companies.selectors";
import {isSubmittedSelector} from "../../../../../redux/selectors/common.selectors";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment} from "@mui/material";
/** Other **/

interface IProps {
    openAddFile: boolean
    setOpenAddFile: Dispatch<SetStateAction<boolean>>
    typeFile: string
    setTypeFile: Dispatch<SetStateAction<string>>
    id: string
    managerId: string
}


const AddFile: React.FC<IProps> = ({openAddFile, setOpenAddFile, typeFile, setTypeFile, managerId, id}) => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const allCompanies: Array<ICompany> = useSelector(companiesSelector)
    const isSubmitted: boolean = useSelector(isSubmittedSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [error, setError] = useState('')
    const [dragEnter, setDragEnter] = useState(false)
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    let validationSchema = yup.object().shape({
        company: yup.string().required('Введите Название компании'),
        sum: yup.number().moreThan(0, 'Сумма должна быть больше чем 0').required('Введите сумму')
    });
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            company: '' as string,
            sum: 0 as number
        },
        onSubmit: (): void => {},
        validateOnMount: true,
        validationSchema: validationSchema
    });

    const formikValues = formik.values
    const formikChange = formik.handleChange
    const formikSet = formik.setFieldValue
    const formikErrors = formik.errors
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
        // окно ADD FILE
        // закрыть
    const onAddFileClose = () => {
            setOpenAddFile(false)
            setError('')
            formikSet('company', '')
            formikSet('sum', 0)
            setTypeFile('')
            acceptedFiles.shift()
        }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch()
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        return () => {
            dispatch(commonAC.toggleIsSubmitted(false))
        }
        // eslint-disable-next-line
    }, [])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    // Ф-я загрузки файлов (срабатывает при добавлении файлов)
    const onUploadFile = () => {
        dispatch(commonAC.toggleIsSubmitted(true))
        if (acceptedFiles.length > 0 && acceptedFiles[0].size > 5*1024*1024) {
            setError('Файл должен быть меньше 5 Мб')
        } else if (acceptedFiles.length < 1) {
            setError(`Добавьте файл!`)
        } else if (fileRejections.length > 0) {
            setError(fileRejections[0].errors[0].message)
        } else {
            setError('')
            if (Object.keys(formikErrors).length === 0) {
                dispatch(saveFile(acceptedFiles[0], id, formik.values.company, formikValues.sum, typeFile, managerId))
                onAddFileClose()
            }
        }
    }
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    // Добавляем список компаний в список для выбора при добавлении
    let optionsCompaniesElements = allCompanies.map(company => ({
        id: company._id,
        name: company.name
        }))
    // -----------------------------------------------------------------------------------------------------------------
    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxFiles: 1
    });

    const files = acceptedFiles.map((file: File) => (
        // @ts-ignore
        <li key={file.path}>
            <div className={classes.fileIcon}><DescriptionIcon fontSize={"small"}  /></div>
            {/*@ts-ignore*/}
            <div className={classes.fileName}>{file.path} - {(file.size/1024/1024).toFixed(3)} Мб</div>
        </li>
    ));
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <>
            <Dialog onClose={onAddFileClose} aria-labelledby="customized-dialog-title" open={openAddFile}>
                <DialogTitle id="customized-dialog-title">
                    Загрузить файл
                </DialogTitle>
                <DialogContent dividers>
                    <div className={classes.addFile}>
                        {typeFile === 'CI'
                            ? <SelectField id={'company'}
                                           icon={<BusinessIcon/>}
                                           label={'Выберите компанию'}
                                           onChange={formikSet}
                                           value={formikValues.company}
                                           validation
                                           isSubmitted={isSubmitted}
                                           errorMessage={formikErrors.company}
                                           list={optionsCompaniesElements}
                            />
                            : <SimpleTextField id={'company'}
                                               icon={<BusinessIcon/>}
                                               label={'Компания'}
                                               onChange={formikChange}
                                               value={formikValues.company}
                                               validation
                                               isSubmitted={isSubmitted}
                                               errorMessage={formikErrors.company}
                            />}
                        <SimpleTextField
                            id={'sum'}
                            type={'number'}
                            icon={<MonetizationOnIcon/>}
                            label={'Сумма счета'}
                            onChange={formikChange}
                            value={formikValues.sum}
                            validation
                            isSubmitted={isSubmitted}
                            errorMessage={formikErrors.sum}
                            endAdornment={<InputAdornment position="start">₽</InputAdornment>}
                        />
                        <section className={classes.uploadZone}>
                            <div {...getRootProps({className: `${classes.dropZone} ${dragEnter && classes.dropZoneEnter}`})}
                                 onDragOver={() => {setDragEnter(true)}}
                                 onDragLeave={() => {setDragEnter(false)}}
                            >
                                <input {...getInputProps()} />
                                <div className={classes.textInDropZone}>{files.length > 0 ? <DoneIcon fontSize={"large"}/> : `Перенесите один файл в эту область`}</div>
                            </div>
                            <aside>
                                <ul>{files}</ul>
                            </aside>
                        </section>
                        {error.length > 0 && <div className={classes.error}>{error}</div>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onUploadFile} variant="contained" color="primary" component="span">
                        Загрузить
                    </Button>
                    <Button onClick={onAddFileClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddFile;