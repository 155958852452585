import React, {useEffect, useRef} from "react";
import { useState } from "react";
import {useDispatch, useSelector } from "react-redux";
import { appAC } from "../../../redux/reducers/app.reducer";
import {snackBarsListSelector} from "../../../redux/selectors/app.selector";
import { ISnack } from "../../../redux/types/app.types";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as Warning } from '../../../assets/icons/warning.svg';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import './SnackBars.scss'
import {Button, Dialog, DialogContent, DialogTitle} from "@mui/material";

const SnackBars: React.FC = () => {
    /** ---------------------------------- MAIN FUNC ----------------------------------------------------------------------- */
    /** ---------------------------------- / MAIN FUNC --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
    const [popupContent, setPopupContent] = useState<string>('')
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const snackBarsList: Array<ISnack> = useSelector(snackBarsListSelector);
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    const deleteSnack = (id: string) => {
        dispatch(appAC.deleteSnack(id));
    };

    const onOpenPopup = (text: string) => {
        setIsPopupOpen(true)
        setPopupContent(text)
    }

    const onClosePopup = () => {
        setIsPopupOpen(false)
        setPopupContent('')
    }
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    const dispatch = useDispatch();
    const nodeRef = useRef(null)
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    useEffect(() => {
        !isPopupOpen && setPopupContent('')
    }, [isPopupOpen])
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const snacksJSX = snackBarsList.map(item => {
        if (item.isVanishing) {
            setTimeout(() => {
                dispatch(appAC.deleteSnack(item.id));
            }, 5000)
        }
        return <CSSTransition
            key={item.id}
            nodeRef={nodeRef}
            timeout={{
                enter: 380,
                exit: 380
            }}
            classNames="snackbars__list"
            mountOnEnter
            unmountOnExit
        >
            <div key={item.id} ref={nodeRef} className='snackbars__item'>
                <div className={classNames('snackbars__item-elem snackbars__item-icon', {
                    'snackbars__item-icon--error': item.type === 'error'
                })}>
                    {item.type === 'info' && <Info />}
                    {item.type === 'warning' && <Warning />}
                    {item.type === 'error' && <Warning />}
                </div>
                <div className={classNames('snackbars__item-elem snackbars__item-text', {
                    'snackbars__item-text--error': item.type === 'error'
                })}>{item.data}</div>
                {item.link && <div className='snackbars__item-elem snackbars__item-btn'>
                    <Button>{item.link?.label}</Button>
                </div>}
                {item.errorText && <div className='snackbars__item-elem snackbars__item-btn'>
                    <Button onClick={() => {onOpenPopup(item.errorText || '')}}>Смотреть текст ошибки</Button>
                </div>}
                <div className='snackbars__item-elem snackbars__item-close' onClick={() => {
                    deleteSnack(item.id);
                }}>
                    <Button><Close /></Button>
                </div>
            </div>
        </CSSTransition>}
    );

    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <>
            <div className='snackbars__container'>
                <TransitionGroup className='snackbars__list'>{snacksJSX}</TransitionGroup>
            </div>
            <Dialog
                onClose={onClosePopup}
                aria-labelledby="customized-dialog-title"
                open={Boolean(popupContent)}
                maxWidth={"xl"}
            >
                <DialogTitle id="customized-dialog-title">
                    Текст ошибки
                </DialogTitle>
                <DialogContent dividers>
                    {popupContent}
                </DialogContent>
            </Dialog>
            {/*<Popup title={'Текст ошибки'} openPopup={isPopupOpen} setOpenPopup={setIsPopupOpen} closeBtn>*/}
            {/*    {popupContent}*/}
            {/*</Popup>*/}
        </>
    );
};
export default SnackBars;