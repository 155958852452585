/** Hooks & React **/
import React from 'react';
/** Styles & Graphics **/
import './ErrorValidation.scss';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import classNames from 'classnames';
/** Components **/
/** Types **/
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  message: any;
  triggers?: Array<boolean>;
  className?: string
}

const ErrorValidation: React.FC<IProps> = ({ message, triggers = [true], className }) => {
  /** ###################################### JSX ######################################################################## **/
  return (
    <>
      {triggers && triggers.some(trigger => trigger) && message && (
        <div className={classNames('error-validation', className)}>
          <div className='error-validation__icon'>
            <ErrorOutlineIcon />
          </div>
          <div className='error-validation__text'>{message}</div>
        </div>
      )}
    </>
  );
};

export default ErrorValidation;
