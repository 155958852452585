import moment from 'moment/moment';
import 'moment/locale/ru';
moment.locale('ru');

/** Функция возвращающая текущее время и дату в строковом формате подходящем для отправки на БЭК */

export interface IDateTimeFromApi {
  date: string
  time: string
}

export const dateTime = {
  now(): string {
    const date: string = moment().format().split('').slice(0, 10).join('');
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const time: string =  (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);

    return `${date}T${time}:00${moment().format('Z')}`;
  },
  nowTime(): string {
    return moment().format('HH:mm')
  },
  nowDate(): string {
    return moment().format('YYYY-MM-DD')
  },
  addTime(date: string, minutes: number): string {
    let newDate = moment(date).add(minutes, 'minutes')
    return `${moment(newDate).format().split('').slice(0, 10).join('')}T${moment(newDate).format('HH:mm')}:00${moment().format('Z')}`;
  },
  subtractTime(date: string, minutes: number): string {
    let newDate = moment(date).subtract(minutes, 'minutes')
    return `${moment(newDate).format().split('').slice(0, 10).join('')}T${moment(newDate).format('HH:mm')}:00${moment().format('Z')}`;
  },
  toApi(date: string, time: string): string {
    if (date && time) {
      return `${date}T${time}:00${moment().format('Z')}`
    }
    return ''
  },
  fromApi(date: string): IDateTimeFromApi {
      return {
        date: moment(date).format().split('').slice(0, 10).join(''),
        time: moment(date).format('HH:mm')
      }
  },
  print(date: string): IDateTimeFromApi {
      return {
        date: moment(date).format('D MMMM YYYY'),
        time: moment(date).format('HH:mm')
      }
  }
}

export const additionTime = (startDate: string, etaTime: string) => {
  if (startDate && etaTime) {
    let [hoursString, minutesString] = (etaTime.split(':'))
    let hours = +hoursString
    let minutes = +minutesString
    if (+hours > 0) minutes += +hours * 60
    let date = moment(startDate).add(minutes, 'minutes');
    return `${moment(date).format('YYYY-MM-DD')}T${moment(date).format('HH:mm')}:00${moment().format('Z')}`;
  }
  return null;
};
