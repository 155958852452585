/** Hooks & React **/
import React from 'react';
import {useSelector} from "react-redux";
/** Styles & Graphics **/
import classes from './DealsItems.module.css'
/** Components **/
import DealMini from './DealMini/DealMini';
/** Types **/
import { IDeal } from '../../../redux/types/deals.types';
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
import {filteredDealsSelector} from "../../../redux/selectors/deals.selectors";
import {IMe} from "../../../redux/types/auth.types";
import {meDataSelector} from "../../../redux/selectors/auth.selectors";
/** Other **/

const DealsItems = () => {
    /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
    /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
    /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
    const filteredDeals: Array<IDeal> = useSelector(filteredDealsSelector)
    const meData: IMe = useSelector(meDataSelector)
    /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
    /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
    /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
    /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
    /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
    /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
    /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
    /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
    /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
    /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
    /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
    /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
    /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
    /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
    const position = meData.position
    const location = meData.location
    let dealsElements = filteredDeals.map(deal => <DealMini key={deal._id} dealData={deal} position={position} location={location}/>)
    /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

    /** ###################################### JSX ######################################################################## **/
    return (
        <div className={`${classes.dealsPageItems}`}>
            {dealsElements}
        </div>
    )
};

export default DealsItems;