/** Hooks & React **/
import React, {ChangeEvent, ReactNode} from 'react';
import ErrorValidation from "../ErrorValidation/ErrorValidation";
/** Styles & Graphics **/
import './CheckboxField.scss'
/** Components **/
/** Types **/
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
/** Utilities **/
/** Thunks and ActionCreators **/
/** Selectors **/
/** Other **/

interface IProps {
  id: string;
  label?: string
  onChange: any
  value: boolean
  validation?: boolean
  isSubmitted?: boolean
  errorMessage?: string
  disabled?: boolean
}

const CheckboxField: React.FC<IProps> = ({ id, label, validation,
                                           isSubmitted, value, errorMessage,
                                           onChange, disabled= false }) => {
  /** ----------------------------------  CONSTANTS AND MAPS ------------------------------------------------------------- */
  /** ---------------------------------- / CONSTANTS AND MAPS ------------------------------------------------------------ */
  /** ---------------------------------- SELECTORS ----------------------------------------------------------------------- */
  /** ---------------------------------- / SELECTORS --------------------------------------------------------------------- */
  /** ---------------------------------- LOCAL STATE --------------------------------------------------------------------- */
  /** ---------------------------------- / LOCAL STATE ------------------------------------------------------------------- */
  /** ---------------------------------- VALIDATION ---------------------------------------------------------------------- */
  /** ---------------------------------- / VALIDATION -------------------------------------------------------------------- */
  /** ---------------------------------- FORMIK -------------------------------------------------------------------------- */
  /** ---------------------------------- / FORMIK ------------------------------------------------------------------------ */
  /** ---------------------------------- MICRO FUNCTIONS ----------------------------------------------------------------- */
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>, child: ReactNode) => {
    console.log('e.target.value', e.target.checked)
    onChange(id, e.target.checked)
  }
  /** ---------------------------------- / MICRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- HOOKS --------------------------------------------------------------------------- */
  /** ---------------------------------- / HOOKS ------------------------------------------------------------------------- */
  /** ---------------------------------- EFFECTS ------------------------------------------------------------------------- */
  /** ---------------------------------- / EFFECTS ----------------------------------------------------------------------- */
  /** ---------------------------------- MACRO FUNCTIONS ----------------------------------------------------------------- */
  /** ---------------------------------- / MACRO FUNCTIONS --------------------------------------------------------------- */
  /** ---------------------------------- TEMPLATES ----------------------------------------------------------------------- */
  /** ---------------------------------- / TEMPLATES --------------------------------------------------------------------- */

  /** ###################################### JSX ######################################################################## **/
  return <div className='checkbox-field'>
    <div className="checkbox-field__checkbox">
      <FormGroup aria-disabled={disabled}>
        {label
            ? <FormControlLabel className={'checkbox-field__mui'}
                           control={<Checkbox checked={value} onChange={onChangeValue}/>}
                           label={label}
                           disabled={disabled}
        />
            : <Checkbox checked={value} onChange={onChangeValue} disabled={disabled}/>}
      </FormGroup>
    </div>
    {validation && isSubmitted && <ErrorValidation message={errorMessage}/>}
  </div>
};

export default CheckboxField;
